import React, { Fragment } from 'react';
import Seo from '../../components/Layout/Seo';
import Navbar from '../../components/Layout/Navbar';
import Simulador from '../../components/Simulador/Simulador';

const SimuladorPage = () => {
  return (
    <Fragment>
      <Seo title="Simulador" />
      <Navbar />
      <Simulador />
    </Fragment>
  );
};

export default SimuladorPage;
