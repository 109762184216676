import {
    Stack,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
  } from '@chakra-ui/react';

import { useHistory } from "react-router-dom"
import ConstantesSistema from '../../Constants/ConstantesSistema';
import { Auto } from './Auto';
import { Especificaciones } from './Especificaciones';
import { Cantidades } from './Cantidades'


export const Resultados = ({ vh,permiso }) => {
    const history = useHistory();  
    const onSelect = (value) => {
      value.tk = localStorage.getItem(ConstantesSistema.nombre_Tkn);
      value.emp = parseInt(localStorage.getItem(ConstantesSistema.empresaSel));
      localStorage.setItem(ConstantesSistema.vehiculoDet, JSON.stringify(value));
      if (window.location.href.toString().toUpperCase().indexOf("PLACA") > -1) {
        localStorage.setItem(ConstantesSistema.tipoBusqueda, ConstantesSistema.tipoBusPlaca);
      } else {
        localStorage.setItem(ConstantesSistema.tipoBusqueda, ConstantesSistema.tipoBusCodigo);
      }
      localStorage.removeItem(ConstantesSistema.regresar);  
      history.push(ConstantesSistema.SiteRutas.Detalle);
    }
   
    return (
      <Stack >
        <Stack display={{ base: 'none', md: 'block' }} onClick={() => onSelect(vh)}>
          <Stack
            bg="light"
            rounded="md"
            cursor="pointer"
            transition="0.3s"
            boxShadow="sm"
            _hover={{ transform: 'translateY(-1px)', boxShadow: 'xl' }}
            p={4}
          >
            <Stack
              direction={{ base: 'column', lg: 'row' }}
              spacing={{ base: '8', lg: '0' }}
              justifyContent="space-between"
              px={4}
            >
              <Stack spacing={4}>
                <Auto placa={vh.placa} codigo={vh.estilo_Descripcion} anio={vh.anio} />
                <Stack hidden={permiso.opGeneral}>
                  <Especificaciones transmision={vh.transmision} cilindraje={vh.cilindraje} combustible={vh.combustible} traccion={vh.traccion} />
                </Stack>
              </Stack>
              <Cantidades preciosugventa={vh.precio_Sugerido_Venta} preciorecibo={vh.precio_Recibo} cantinventario={vh.cantidad_Inventario} permiso={permiso} />
            </Stack>
          </Stack>
        </Stack>
        <AccordionItem display={{ base: 'block', md: 'none' }}>
          <Stack
            bg="light"
            rounded="md"
            cursor="pointer"
            transition="0.3s"
            boxShadow="sm"
            _hover={{ transform: 'translateY(-1px)', boxShadow: 'xl' }}
          >
            <Stack>
              <AccordionButton borderBottom={{ base: '1px solid #E6E4E4', md: '0' }} _hover={{ background: '#f5f5f5' }}>
                <Auto placa={vh.placa} codigo={vh.estilo_Descripcion} />
                <AccordionIcon display={{ base: 'block', md: 'none' }} />
              </AccordionButton>
            </Stack>
            <AccordionPanel onClick={() => onSelect(vh)}>
              <Cantidades preciosugventa={vh.precio_Sugerido_Venta} preciorecibo={vh.precio_Recibo} cantinventario={vh.cantidad_Inventario} permiso={permiso}/>
              <Stack hidden={permiso.opGeneral}>
                  <Especificaciones transmision={vh.transmision} cilindraje={vh.cilindraje} combustible={vh.combustible} traccion={vh.traccion} />
                </Stack>
            </AccordionPanel>
          </Stack>
        </AccordionItem>
      </Stack>
    );
  };