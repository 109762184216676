import { GetResponseBody, baseUrlApi,baseUrlApiPC, GetHeaderRequest } from './PathConfig';

export const ObtenerBaseRequest = async (pToken) => {
    const result = await fetch(`${baseUrlApi}api/Comparacion/Obtener_ListaBase?Pantalla=Comparacion_ListaBase`, {
        method: 'GET',
        headers: GetHeaderRequest(pToken)
    });
    return await result.json();
};
export const ObtenerDatosRequest = async (pToken,pEmpresaId,estilo,anno,meses) => {
    const data = { empresaId: pEmpresaId, estilo: estilo, anno: anno };
    const result = await fetch(`${baseUrlApi}api/Comparacion/Obtener_ListaDatos?mesesPromedio=${meses}&Pantalla=Comparacion`, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: GetHeaderRequest(pToken)
    });
    return await result.json();
};