import React, { Fragment } from 'react';
import Seo from '../../components/Layout/Seo';
import Navbar from '../../components/Layout/Navbar';
import BuscarCodigo from '../../components/Buscar/BuscarCodigo';

const BuscarPlacaPage = () => {
  return (
    <Fragment>
      <Seo title="Buscar Placa" />
      <Navbar />
      <BuscarCodigo />
    </Fragment>
  );
};

export default BuscarPlacaPage;
