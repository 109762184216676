import { GetResponseBody, GetHeaderRequest, baseUrlApi } from './PathConfig';

export const ObtenerUsuarioRequest = async (pUsuario) => {
    const result = await fetch(`${baseUrlApi}api/Usuario/Obtener_Usuario?usuario=${pUsuario}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        }
    });
    return await GetResponseBody(result);
};