import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import ConstantesSistema from '../../../Constants/ConstantesSistema';

toastr.options = {
    positionClass: 'toast-top-center',
    toastClass: 'toastr',
    showDuration: 500,
    hideDuration: 1000,
    timeOut: 0, //Tiempo en cero, lo establece el parámetro de empresa al iniciar sesión PMS 2022/FEB/15
    tapToDismiss:false,
    extendedTimeOut:6000,
    closeButton:true
};


export const mostrarError = (mensaje) => {

    toastr.options = {
        ...toastr.options,
        timeOut: localStorage.getItem(ConstantesSistema.paramTiempoMensajes), 
    };

    toastr.error(mensaje);

}

export const mostrarWarning = (mensaje) => {

    toastr.options = {
        ...toastr.options,
        timeOut: localStorage.getItem(ConstantesSistema.paramTiempoMensajes), 
    };

    toastr.warning(mensaje);

}

export const mostrarExito = (mensaje) => {

    toastr.options = {
        ...toastr.options,
        timeOut: localStorage.getItem(ConstantesSistema.paramTiempoMensajes), 
    };

    toastr.warning(mensaje);

}
