import React, { useState, useEffect } from 'react';
import {
  Container,
  Stack,
  Box,
  Text,
  Button,
  Image,
  List,
  ListItem,
  Alert,
  AlertIcon,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
} from '@chakra-ui/react';
import ConstantesSistema from '../../Constants/ConstantesSistema';
import {ObtenerParamIdRequest} from '../../request/ParamRequest';
import { BiChevronLeft } from 'react-icons/bi';
import { InfoIcon } from '@chakra-ui/icons';
import NumberFormat from "react-number-format";

export const DetallesSimulador = ({vh}) => {

    const [msjRes,setMsjRes]=useState('');
    const [leyendaPrecioRecibo, setLeyendaPrecioRecibo] = useState('');
    let [listaPermiso, setListaPermiso] = useState({ opRestringido: false,
      opGeneral: false, 
      opRestringido: false,
      opPrecioRecibo:false });

    useEffect(() => {
      const ObtenerParam = async()=>{  
        if(localStorage.getItem(ConstantesSistema.paramMsjRes)===null){
          const vMsj = await ObtenerParamIdRequest(localStorage.getItem(ConstantesSistema.nombre_Tkn), ConstantesSistema.paramMsjRes);
          if (vMsj.exitoso) {
            setMsjRes(vMsj.objeto.valor);    
            localStorage.setItem(ConstantesSistema.paramMsjRes, vMsj.objeto.valor);      
          }
        }else{
          setMsjRes(localStorage.getItem(ConstantesSistema.paramMsjRes));  
        }

        let vParam = await ObtenerParamIdRequest(localStorage.getItem(ConstantesSistema.nombre_Tkn),ConstantesSistema.paramLeyendaBusquedaVehiculoPR);
        if (vParam.exitoso) {
          setLeyendaPrecioRecibo(vParam.objeto.valor);
        }

      }
      const ObtenerSubMenu = async () => { 
        var vSubMenu = JSON.parse(localStorage.getItem(ConstantesSistema.opcionesSubMenu));  
        var vTipo = parseInt(localStorage.getItem(ConstantesSistema.menuSel));
        if (vSubMenu != null) {
          vSubMenu = vSubMenu.filter(x => x.menu_Id === vTipo);     
          setListaPermiso({ 
            opRestringido:vSubMenu.filter(x => parseInt(x.opcion_Id) === 1).length===0,
            opGeneral:vSubMenu.filter(x => parseInt(x.opcion_Id) === 2).length===0,          
            opPrecioRecibo:vSubMenu.filter(x => parseInt(x.opcion_Id) === 3).length===0
            });
        }    
      }
      ObtenerParam();
      ObtenerSubMenu();
    }, []);


    return (
      <Box w="100%" minH={{ base: '60vh', lg: '100vh' }}>
        <Stack bg="secondary" boxShadow="sm" direction="row" justifyContent="center" pr={4}
          hidden={!vh.tiene_Restriccion}>
          <Alert status="warning" bg="none" color="light">
            <AlertIcon color="light" alignSelf="flex-start" />
            {msjRes}
          </Alert>
          <Stack p={6} hidden={true}>
            <Popover>
              <PopoverTrigger>
                <Button variant="link">
                  <InfoIcon
                    w={6}
                    h={6}
                    color="light"
                    transition="0.3s"
                    _hover={{ transform: 'translateY(-2px)' }}
                    _active={{ transform: 'translateY(0)' }}
                  />
                </Button>
              </PopoverTrigger>
              <PopoverContent w="max-content">
                <PopoverArrow />
                <PopoverCloseButton />
                <PopoverHeader>Detalle</PopoverHeader>
                <PopoverBody>Restricción 1</PopoverBody>
                <PopoverBody>Restricción 2</PopoverBody>
                <PopoverBody>Restricción 3</PopoverBody>
              </PopoverContent>
            </Popover>
          </Stack>
        </Stack>
        <Container maxW={{ base: '95%', md: '75%' }} py={8}>
          <Image src={vh.imagen_Marca} alt="Logo" w="11rem" mb={8}  />
          <Stack  direction="row" alignItems="center" fontWeight="bold" py={2}>
            <BiChevronLeft fontSize="2rem" />
            <Text>{vh.estilo_Descripcion} {vh.anio}</Text>
          </Stack>
          <List fontSize="lg" spacing={4} mb={6} hidden={listaPermiso.opGeneral}>
            <ListItem>Transmisión: {vh.transmision}</ListItem>
            <ListItem>Combustible: {vh.combustible}</ListItem>
            <ListItem>Cilindraje: {vh.cilindraje}</ListItem>
            <ListItem>Tracción: {vh.traccion}</ListItem>
          </List>
          <Stack justifyContent="flex-start" direction="column" py={2} hidden={listaPermiso.opRecibo}>
            <Text style={{textAlign:'right'}} fontSize="sm" color="error" fontWeight="bold">Última Actualización: {vh.fechaCalculo}</Text>
            <Text style={{textAlign:'right'}} fontSize="sm" color="error" fontWeight="bold">{leyendaPrecioRecibo}</Text>
          </Stack>      
          <Stack spacing={8}>
            <Stack spacing={4} hidden={listaPermiso.opPrecioRecibo}>
              <Stack
                bg="light"
                justifyContent="space-between"
                fontWeight="bold"
                p={4}
                rounded="md"
                boxShadow="sm"
                direction={{ base: 'column', sm: 'row' }}
              >
                <Text textTransform="uppercase">Precio de recibo</Text>
                <Text fontSize="lg" color="error">
                  <NumberFormat
                    value={vh.precio_Recibo}
                    displayType={'text'} thousandSeparator={true} prefix={'$'}
                  />
                </Text>
              </Stack>
              <Stack
                bg="light"
                justifyContent="space-between"
                fontWeight="bold"
                p={4}
                rounded="md"
                boxShadow="sm"
                direction={{ base: 'column', sm: 'row' }}
              >
                <Text textTransform="uppercase">año anterior</Text>
                <Text fontSize="lg" color="error">
                  <NumberFormat
                    value={vh.precio_Recibo_Anterior}
                    displayType={'text'} thousandSeparator={true} prefix={'$'}
                  />
                </Text>
              </Stack>
            </Stack>
          </Stack>
        </Container>
      </Box>
    );
  };