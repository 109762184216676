import React, { useState, useEffect } from 'react';
import ConstantesSistema from '../../Constants/ConstantesSistema';
import { ObtenerAnnosPorEstiloRequest} from '../../request/VehiculosRequest';
import {
    Stack,
    Box,
    Text,
    Button,
    Input,
  } from '@chakra-ui/react';
  import { mostrarError, mostrarExito, mostrarWarning } from '../Util/Notification/Notification'


export const Annios = ({pEstilo, onSetLoading }) => {

    const [Item, setItem] = useState([]);
    const [searchtext, setSearchtext] = useState('');
    const [suggest, setSuggest] = useState([]);
    const [resfound, setResfound] = useState(true);

  useEffect(() => {
    if(localStorage.getItem(ConstantesSistema.annioSel)){
      setSearchtext(parseInt(localStorage.getItem(ConstantesSistema.annioSel)));
    }
  }, []);

    const obtener_annios_estilo = async () => {

      const ObtenerAnnio = async () => {

        let vEmpresa = localStorage.getItem(ConstantesSistema.empresaSel);
        let vCod = pEstilo;
    
        const response = await ObtenerAnnosPorEstiloRequest(localStorage.getItem(ConstantesSistema.nombre_Tkn), vEmpresa,vCod);
        if (response.exitoso) {
            let vIni = parseInt(response.objeto.result.annoInicial);
            let vFin = parseInt(response.objeto.result.annoFinal) + 1;  
  
            if(vIni>0 && vFin >0){
              let vL = vFin - vIni;
              setItem(Array.from({ length: vL }, ((_, i) => vIni + i)));
            }
            else{
              mostrarError("El vehículo no tiene parametrización de años de vigencia. Por favor comuníquese con Distribuidor Usados.");
              setItem([]);
            }
        }
        else {
            mostrarError(response.mensajeRespuesta);
        }
      }
      await ObtenerAnnio();
    }
  
    useEffect(async () => {

      setSearchtext(''); //limpiar campo de año 

        if (pEstilo !== null)
        {
          if (pEstilo.length>0)
          {        

            onSetLoading(true);

            await obtener_annios_estilo();
            
            onSetLoading(false);
        }
      }
  
    }, [pEstilo]);

   
  
    useEffect(() => {
  
      const obtenerDatosInternos = async () => {
        if (localStorage.getItem(ConstantesSistema.regresar) != null) {
          const vh = localStorage.getItem(ConstantesSistema.annioSel);
          if (vh != null) {
            setSearchtext(parseInt(vh));
          }
        }
      }
      obtenerDatosInternos();
    }, []);
  
    const handleChange = (e) => {
      let searchval = e.target.value;
      let suggestion = [];
      if (searchval.length > 0) {
        suggestion = Item.sort().filter((e) => e.toString().toLowerCase().includes(searchval.toLowerCase()));
        setResfound(suggestion.length !== 0 ? true : false);
      }
      setSuggest(suggestion);
      setSearchtext(searchval);
    };
    const onKeyDownAnio = (e)=>{
      if(e.keyCode===13 || e.keyCode===9){
        if(suggest.length>0){
          suggestedText(suggest[0])
        }      
      } 
    }
    const suggestedText = (value) => {
      setSearchtext(value);
      setSuggest([]);
      localStorage.setItem(ConstantesSistema.annioSel, value);
      localStorage.setItem(ConstantesSistema.iniciaBuscar, ConstantesSistema.iniciaBuscar);
    };
  
    const getSuggestions = () => {
      if (suggest.length === 0 && searchtext !== '' && !resfound) {
        return (
          <Stack
            overflowY="auto"
            maxH="10rem"
            p={2}
            w="max-content"
            pos="absolute"
            bg="light"
            zIndex="3"
            boxShadow={{ base: 'md', lg: 'md' }}
            rounded="md"
          >
            <Box display="grid" justifyItems="start">
              <Text>No se encontraron resultados</Text>
            </Box>
          </Stack>
        );
      }
    };
  
    return (
      <Box
        bg="white"
        w={{ base: '5.5rem', lg: '9rem' }}
        px={{ base: '1', xl: '0' }}
        borderLeftRadius={{ base: 'none', xl: 'none' }}
        boxShadow={{ base: 'sm', xl: 'none' }}
        py={{ base: '2', xl: '0' }}
        display={{ base: 'block', xl: 'block' }}
      >
        <Stack direction="row" pr={2} fontWeight="bold">
          <Text p={2}  display={{ base: 'none', lg: 'block' }}>Año</Text>
          <Input
            borderColor="#EEEEEF"
            focusBorderColor="none"
            variant="flushed"
            placeholder="Año"
            value={searchtext}
            onChange={handleChange}
            onKeyDown={onKeyDownAnio}
          />
        </Stack>
        {getSuggestions()}
        <Stack
          overflowY="auto"
          maxH="10rem"
          p={!searchtext ? '1' : '0'}
          w={!searchtext ? '18rem' : 'max-content'}
          pos="absolute"
          bg="light"
          zIndex="3"
          boxShadow={{ base: 'md', lg: 'md' }}
          rounded="md"
          visibility={searchtext ? 'visible' : 'hidden'}
        >
          <Box display="grid" gridTemplateColumns={{base: "repeat(2, 1fr)", lg:"repeat(4, 1fr)"}} justifyItems="center">
            {suggest.map((item, index) => {
              return (
                <Box key={index}>
                  <Button variant="ghost" rounded="full" onClick={() => suggestedText(item)}>
                    {item}
                  </Button>
                  {index !== suggest.length - 1}
                </Box>
              );
            })}
          </Box>
        </Stack>
      </Box>
    );
  };
  