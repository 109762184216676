import { extendTheme } from '@chakra-ui/react';

export const theme = extendTheme({
  styles: {
    global: {
      body: {
        bg: '#F2F1F1',
        color: '#1A1919',
      },
    },
  },
  fonts: {
    heading: '"Sofia Pro", sans-serif',
    body: '"Sofia Pro", sans-serif',
  },
  colors: {
    primary: '#242B38',
    primaryHover: '#646C83',
    secondary: '#C9A221',
    secondaryLight: '#F1C64C',
    secondaryHover: '#DAB446',
    tertiary: '#A0AEC0',
    body: '#1A1919',
    bodyAlt: '#303849',
    bodyTitle: '#4D4D4D',
    light: '#FFFFFF',
    background: '#F2F1F1',
    backgroundLight: '##F7F7F7',
    error: '#D8222B',
    borders: '#D9D7D7',
  },
  components: {
    Checkbox: {
      baseStyle: {
        control: {
          bg: '#7C8CAB',
          border: '#7C8CAB',
        },
      },
    },
  },
});
