import { Helmet } from 'react-helmet';
import React from 'react';

const Seo = ({ title, description, pathSlug }) => {
  const url = `https://sitio.com/${pathSlug}`;
  return (
    <Helmet
      htmlAttributes={{ lang: 'es' }}
      title={'Purdy Book'}
      meta={[
        {
          name: 'description',
          content: description,
        },
      ]}
      links={[
        {
          rel: 'canonical',
          href: url,
        },
      ]}
    />
  );
};
export default Seo;
