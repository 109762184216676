import React, { Fragment } from 'react';
import Seo from '../../components/Layout/Seo';
import Navbar from '../../components/Layout/Navbar';
import Comparar from '../../components/Comparar/Comparar';

const CompararPage = () => {
  return (
    <Fragment>
      <Seo title="Comparar" />
      <Navbar />
      <Comparar />
    </Fragment>
  );
};

export default CompararPage;
