import React, { useState, Fragment, useEffect } from 'react';
import { mostrarError, mostrarExito, mostrarWarning } from '../Util/Notification/Notification'
import {
  Alert,
  AlertIcon,
  Container,
  Stack,
  Box,
  Text,
  Heading,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Checkbox,
  CheckboxGroup,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from '@chakra-ui/react';
import { BiChevronDown, BiX } from 'react-icons/bi';
import { FaFilter, FaSearch } from 'react-icons/fa';
import { ObtenerEmpresasRequest } from '../../request/EmpresasRequest';
import ConstantesSistema from '../../Constants/ConstantesSistema';
import { ObtenerEstiloRequest, ObtenerVehiculosxEstiloRequest, ObtenerVehiculosxPlacaRequest} from '../../request/VehiculosRequest';
import Loading from '../../components/Util/Loading/Loading';

import {ObtenerExtrasRequest} from '../../request/ExtrasRequest';
import { Codigos } from './Codigos';
import { Annios } from './Annios';
import { Resultados } from './Resultados'
import ConstantesConsultaEmpresa from '../../Constants/ConstantesConsultaEmpresa';

export const Busqueda = () => {

  const [listaEmpresas, setListaEmpresas] = useState([]);
  const [empresaSel, setEmpresaSel] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingAnnios, setLoadingAnnios] = useState(false);
  const [codigos, setCodigos] = useState([]);
  const [estilo, setEstilo] = useState(null);
  const [listaVehiculo, setListaVehiculo] = useState([]);
  const [loadingV, setLoadingV] = useState(false);
  const [tipoBusqueda, setTipoBusqueda] = useState(0);
  const [verR,setVerR] = useState(false);
  const [loadingEmp,setLoadingEmp] = useState(false);
  const [listaPermiso, setListaPermiso] = useState({ opRestringido: false, opInventario: false, opRecibo: false, opSugerido: false,opGeneral: false });
  const [sinResultados,setSinResultados]=useState('No se obtuvieron resultados');
  const [extras,setExtras]=useState([]);
  const [extrasBd,setExtrasBd]=useState([]);
  const [maxExtras,setMaxExtras]=useState(localStorage.getItem(ConstantesSistema.paramCantExtras));
  const [contador,setContador] = useState(0);

  useEffect(() => {
    let vTipo = ConstantesSistema.tipoBusCodigo;
    if (window.location.href.toString().toUpperCase().indexOf("PLACA") > -1) {
      vTipo = ConstantesSistema.tipoBusPlaca;
    }
    if(localStorage.getItem(ConstantesSistema.paramNoVh)!=null){
      setSinResultados(localStorage.getItem(ConstantesSistema.paramNoVh))
    }
    setTipoBusqueda(vTipo);
    const ObtenerEmpresas = async () => {
      setLoadingEmp(true);
      var lista =JSON.parse( localStorage.getItem(ConstantesSistema.empresaLista));
      if (lista === null) {
        const vDatos = await ObtenerEmpresasRequest(localStorage.getItem(ConstantesSistema.nombre_Tkn), ConstantesConsultaEmpresa.Consultas);
        if (vDatos.exitoso) {
          setListaEmpresas(vDatos.objeto);
          setLoadingEmp(false);
          obtenerDatosInternos(vDatos.objeto);
          ObtenerSubMenu(vTipo);
        }
        else {
          mostrarError(vDatos.mensajeRespuesta);
        }
      } else {
        setListaEmpresas(lista);
        setLoadingEmp(false);
        obtenerDatosInternos(lista);
        ObtenerSubMenu(vTipo);
      }
      if (vTipo===ConstantesSistema.tipoBusCodigo) {
        ObtenerExtras();
      }
      
    }
    ObtenerEmpresas();    
    // const interval = setInterval(() => {
    //   var x = localStorage.getItem(ConstantesSistema.iniciaBuscar);
    //   if(x!=null){
    //     localStorage.removeItem(ConstantesSistema.iniciaBuscar);
    //     buscarVehiculo();
    //   }
    // }, 1000);
    // return () => clearInterval(interval);
  }, []);
  const DetaExtra = ({pExtra}) =>{    
    return (
      <Box 
      w={'100%'}
      px={{ base: '4', xl: '0' }}
      bg="white"
      >
        
          <AccordionItem defaultIsOpen>
            <Stack
              bg="light"
              rounded="md"
              cursor="pointer"
              transition="0.3s"
              boxShadow="sm"
              _hover={{ transform: 'translateY(-1px)', boxShadow: 'xl' }}
            >
              <Stack>
                <AccordionButton borderBottom={{ base: '1px solid #E6E4E4', md: '0' }} _hover={{ background: '#f5f5f5' }}>
                  <Text>{pExtra.label}</Text>
                  <AccordionIcon />
                </AccordionButton>
              </Stack>
              <AccordionPanel>
                <Stack
                  display="grid"
                  gridTemplateColumns={{
                    base: '1fr',
                    md: 'repeat(auto-fit, minmax(20rem, 1fr))',
                  }}
                  gridGap="0.75rem"
                  alignItems="start"
                >
                  {pExtra.deta.map((item, index) => {
                    return (
                      <Checkbox size="sm" value="" key={index} onChange={() => onCheckboxChange(item)} isChecked={item.sel}>
                        {item.label}
                      </Checkbox>
                    );
                  })}
                </Stack>
              </AccordionPanel>
            </Stack>
          </AccordionItem>
      
      </Box>
    )
  }


  const ObtenerExtras=async()=>{
    const response = await ObtenerExtrasRequest(localStorage.getItem(ConstantesSistema.nombre_Tkn));
    if (response.exitoso) {
      const result = response.objeto.map(
        item => ({
          value: item.datId,
          label: item.datDescripcion,
          refId: item.ref_DatId,
          refDes:item.ref_Descripcion,
          sel: false
        })
      );  
      const clas = response.objeto.map(
        item => ({
          value: item.ref_DatId,
          label: item.ref_Descripcion
        })
      );    
      var unique = [...new Set(clas.map(item => item.label))];
      unique = unique.sort();
      var lista = unique.map(
        item => ({
          label: item,
          ver: false,
          cant:0,
          deta: result.filter(x=>x.refDes===item)
        })
      );      
      setExtras(lista);
      setExtrasBd(result);
    }else{
      mostrarError(response.mensajeRespuesta);
    }
  }
  const ObtenerSubMenu = async (pTipo) => { 
    var vSubMenu = JSON.parse(localStorage.getItem(ConstantesSistema.opcionesSubMenu));  
    if (vSubMenu != null) {
      vSubMenu = vSubMenu.filter(x => x.menu_Id === pTipo);      
      if (pTipo === ConstantesSistema.tipoBusCodigo) {
        setListaPermiso({
          opRestringido: vSubMenu.filter(x => parseInt(x.opcion_Id) === 1).length === 0,
          opGeneral:vSubMenu.filter(x => parseInt(x.opcion_Id) === 2).length===0,          
          opInventario:vSubMenu.filter(x => parseInt(x.opcion_Id) === 3).length===0,
          opRecibo:vSubMenu.filter(x => parseInt(x.opcion_Id) === 4).length===0,
          opSugerido:vSubMenu.filter(x => parseInt(x.opcion_Id) === 5).length===0
        });
      } else {
        setListaPermiso({
          opRestringido: vSubMenu.filter(x => parseInt(x.opcion_Id) === 2).length === 0,
          opGeneral:vSubMenu.filter(x => parseInt(x.opcion_Id) === 3).length===0,            
          opRecibo:vSubMenu.filter(x => parseInt(x.opcion_Id) === 4).length===0,       
          opSugerido:vSubMenu.filter(x => parseInt(x.opcion_Id) === 5).length===0,
        });
      }
    }
  }
  const obtenerDatosInternos = async(listaEmpresas)=>{
    if (localStorage.getItem(ConstantesSistema.regresar) != null) {
      setListaVehiculo(JSON.parse(localStorage.getItem(ConstantesSistema.listaVh)));
      setVerR(true);
      var vEmpresa = listaEmpresas.filter(x=>parseInt(x.id)===parseInt(localStorage.getItem(ConstantesSistema.empresaSel)));
      setEmpresaSel(vEmpresa[0]);
      onItemMenuClick(vEmpresa[0]);
      if(ConstantesSistema.tipoBusCodigo===parseInt(localStorage.getItem(ConstantesSistema.tipoBusqueda))){
        setLoading(true);
        const vDatos = await ObtenerEstiloRequest(localStorage.getItem(ConstantesSistema.nombre_Tkn), parseInt(localStorage.getItem(ConstantesSistema.empresaSel)));
        if (vDatos.exitoso) {
          setCodigos(vDatos.objeto);
          setLoading(false);
        }
        else {
          mostrarError(vDatos.mensajeRespuesta);
        }
      }
    }else{
      localStorage.removeItem(ConstantesSistema.listaVh);   
    }
  }
  const [isShowExtras, setIsShowExtras] = useState(true);

  const handleClickExtras = () => {
    setIsShowExtras(!isShowExtras);
  };
  const onCheckboxChange = (e) => {
    var x = contador+1;   
    try {
      if (e === null || e === undefined) {
        return;
      }
      var cantidad = extrasBd.filter(x => x.sel);
      if (cantidad.length >= maxExtras && !e.sel) {
        mostrarError(`La cantidad máxima de extras a consultar es de: ${maxExtras} opciones`);
        return;
      }
      e.sel = !e.sel
      const result = extrasBd.map(
        item => item.value === e.value ? {
          value: e.value,
          label: e.label,
          refId: e.refId,
          refDes: e.refDes,
          sel: e.sel
        } : item
      );
      //setExtras(result);
      setContador(x);
      /*
      setExtras(lista);
      setExtrasBd(result);
      */
    } catch (ex) {
      console.log('ex', e, ex)
    }
  }
  const limpiarExtras = () =>{
    const result = extrasBd.map(
      item => ({
        value: item.value,
        label: item.label,
        refId: item.refId,
        refDes:item.refDes,
        sel: false
      })
    );    
    var lista = extras;
    for(var x=0;x<=lista.length-1;x++){
      var ex = lista[x].deta.map(
        item => ({
          value: item.value,
          label: item.label,
          refId: item.refId,
          refDes:item.refDes,
          sel: false
        })
      )
      lista[x].deta = ex;
    }
    setExtrasBd(result)
    setExtras(lista);
  }
  const aplicarExtras=()=>{
    const result = extras.filter(x=>x.sel);
    var lista = '';
    for (var x = 0; x <= result.length - 1; x++) {
      lista =lista + result[x].value + ',';
    }
    lista =lista.substring(0,lista.length-1);
  }
  const onItemMenuClick = (pItem) => {
    localStorage.setItem(ConstantesSistema.empresaSel, pItem.id);
    //localStorage.removeItem(ConstantesSistema.vehiculoSel);
    //localStorage.removeItem(ConstantesSistema.annioSel);
    setEmpresaSel(pItem);
    if (tipoBusqueda === ConstantesSistema.tipoBusCodigo) {
      setLoading(true);
      setCodigos([]);
      const Obtener = async () => {
        try{
          const vDatos = await ObtenerEstiloRequest(localStorage.getItem(ConstantesSistema.nombre_Tkn), pItem.id);
          if (vDatos.exitoso) {
            await setCodigos(vDatos.objeto);
          }
          else {
            mostrarError(vDatos.mensajeRespuesta);
          }
          setLoading(false);
        }catch(ex){
          mostrarError(ex);
          setLoading(false);
        }        
      }
      Obtener();
    }
  }
  const buscarVehiculo = async () => {
    let vEmpresa = localStorage.getItem(ConstantesSistema.empresaSel);
    let vTipo = ConstantesSistema.tipoBusCodigo;       
    localStorage.removeItem(ConstantesSistema.listaVh);
    if (window.location.href.toString().toUpperCase().indexOf("PLACA") > -1) {
      vTipo = ConstantesSistema.tipoBusPlaca;
    }
    if (vEmpresa === null) {
      mostrarError('Por favor seleccione una compañía');
      return;
    }
    
    try {
      if (vTipo === ConstantesSistema.tipoBusCodigo) {        
        let vCod = JSON.parse(localStorage.getItem(ConstantesSistema.vehiculoSel));
        let vAnnio = localStorage.getItem(ConstantesSistema.annioSel);
        if (vCod === null) {
          mostrarError('Por favor ingrese el código o marca a consultar');
          return;
        }
        if (vAnnio === null) {
          mostrarError('Por favor ingrese el año a consultar');
          return;
        }
        setIsShowExtras(true);
        const result = extrasBd.filter(x=>x.sel);
        var strExtra = '';
        for (var x = 0; x <= result.length - 1; x++) {
          strExtra =strExtra + result[x].value + ',';
        }
        strExtra =strExtra.substring(0,strExtra.length-1);
        setLoadingV(true);
        var response = await ObtenerVehiculosxEstiloRequest(localStorage.getItem(ConstantesSistema.nombre_Tkn), parseInt(vEmpresa), vCod.busqueda, vCod.codigo, parseInt(vAnnio),strExtra);
        if (response) {
          if(response.objeto != null){
            if(listaPermiso.opRestringido){
              response.objeto = response.objeto.filter(x=>x.tiene_Restriccion ===false);
            }
            setListaVehiculo(response.objeto);
            localStorage.setItem(ConstantesSistema.listaVh, JSON.stringify(response.objeto));
          }else{
            setListaVehiculo([]);
          }   
          setVerR(true);
        }
      } else {
        let vCod = localStorage.getItem(ConstantesSistema.vehiculoSel);
        if (vCod === null) {
          mostrarError('Por favor ingrese la placa a consultar');
          return;
        }
        setLoadingV(true);
        var response = await ObtenerVehiculosxPlacaRequest(localStorage.getItem(ConstantesSistema.nombre_Tkn), parseInt(vEmpresa), vCod.toString().toUpperCase());
        
        if (response) {
          if(response.objeto != null){
            setListaVehiculo(response.objeto);
            localStorage.setItem(ConstantesSistema.listaVh, JSON.stringify(response.objeto));
          }else{
            setListaVehiculo([]);
          }          
          setVerR(true);         
        }
      }     
    } catch (ex) {
      mostrarError(ex);
    }
    setLoadingV(false);
  }

  const onChangeEstilo = (pEstilo) => {
    limpiarExtras();
    setEstilo(pEstilo);
  }

  const onSetLoadingAnnio = (valor) => {

    setLoadingAnnios(valor);

  }

  return (
    <Fragment>
      <Stack
        bg={{ base: 'none', xl: 'light' }}
        boxShadow={{ base: 'none', xl: 'md' }}
        rounded="1.5rem"
        mx={{ base: 'auto', md: 'auto' }}
        spacing={8}
        maxW="max-content"
        justifyContent="center"
        mt={10}
      >
        <Stack direction={{ base: 'column', xl: 'row' }} py={3} spacing={4}>
          <Heading fontSize={'md'} display={{ base: 'block', md: 'none' }}>Buscar por {(tipoBusqueda === ConstantesSistema.tipoBusCodigo) ? ' Código' : ' Placa'}</Heading>
          <Stack hidden={!loadingEmp}>
            <Loading color="#D8222B" texto="Cargando compañías" tam={30} />
          </Stack>
          <Stack direction="row" pr={1}  fontWeight="bold" hidden={loadingEmp}>
            <Text p={2}>Compañía</Text>
            <Box  zIndex="3">
              <Menu>
                <MenuButton
                  variant="ghost"
                  as={Button}
                  color="tertiary"
                  borderBottom="1px solid #EEEEEF"
                  rounded="none"
                  fontSize="md"
                  fontWeight="regular"
                  zIndex="3"
                  _active={{
                    bg: 'none',
                  }}
                  _hover={{
                    bg: 'none',
                  }}
                  rightIcon={<BiChevronDown color="#000000" />}
                >
                  <Text>{(empresaSel.length===0)?'Seleccione una compañía':empresaSel.nombre}</Text>
                </MenuButton>
                <MenuList overflowY="auto" maxH="10rem" p={1} minWidth="max-content" className="">
                  {listaEmpresas.map((item, index) => {
                    return (
                      <MenuItem rounded="full" fontWeight="bold" w="max-content" key={index} onClick={() => { onItemMenuClick(item) }}>
                        {item.nombre}
                      </MenuItem>
                    );
                  })}
                </MenuList>
              </Menu>
            </Box>
          </Stack>
          <Stack direction="row" spacing={{ base: '0px', xl: '4' }} fontWeight="bold" >
            <Text display={{ base: 'none', xl: 'block' }} pr={2} p={1} hidden={loading}
              w={(tipoBusqueda === ConstantesSistema.tipoBusCodigo) ? '8.5rem':'5rem'}>
              {(tipoBusqueda === ConstantesSistema.tipoBusCodigo) ? 'Marca o código' : 'Placa'}
            </Text>
            <Stack hidden={!loading} >
              <Loading color="#D8222B" texto="" tam={30} />
            </Stack>
            <Codigos Item={codigos} onChangeEstilo={onChangeEstilo}/>
            <Stack hidden={!loadingAnnios} >
              <Loading color="#D8222B" texto="" tam={30} />
            </Stack>
            <Stack hidden={tipoBusqueda===ConstantesSistema.tipoBusPlaca || loadingAnnios} >
              <Annios pEstilo={estilo} onSetLoading={onSetLoadingAnnio} />
            </Stack>            
            <Stack
              direction="row"
              spacing={2}
              bg={{ base: 'light', xl: 'none' }}
              boxShadow={{ base: 'base', xl: 'none' }}
              align="center"
              px={{ base: '2', lg: '2' }}
              py={{ base: '2', xl: '0' }}
              borderRightRadius={{ base: 'full', xl: 'none' }}
            >
              <Button
                hidden={tipoBusqueda===ConstantesSistema.tipoBusPlaca || loadingV}
                background="secondary"
                variant="solid"
                color="light"
                rounded="full"
                p={{ base: '1', md: '4' }}
                _hover={{ bg: 'secondaryHover' }}
                onClick={handleClickExtras}
              >
                <FaFilter fontSize="1rem" display={{ base: 'none', md: 'block' }} />
                <Text display={{ base: 'none', md: 'block' }} ml={1} fontSize="sm">
                  Extras
                </Text>
              </Button>
              <Button
                background="primary"
                variant="solid"
                color="light"
                rounded="full"
                p={{ base: '1', md: '4' }}
                _hover={{ bg: 'primaryHover' }}
                onClick={buscarVehiculo}
                hidden={loadingV}
              >
                <FaSearch fontSize="1rem" display={{ base: 'none', md: 'block' }} />
                <Text display={{ base: 'none', md: 'block' }} ml={1} fontSize="sm">
                  Buscar
                </Text>
              </Button>
              <Stack hidden={!loadingV} >
                <Loading color="#D8222B" texto="Buscando" tam={30} />
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
      <Stack
        bg={{ base: 'light', md: 'light' }}
        boxShadow={{ base: 'md', lg: 'md' }}
        rounded="md"
        spacing={8}
        mt="5"
        mx={{ base: '0', lg: '0' }}
        p={6}
        w={{ base: '95%', lg: '78rem'}}
        position="absolute"
        hidden={isShowExtras}
        opacity={isShowExtras ? '0' : '1'}
        transform={isShowExtras ? 'scale(0.8) translateY(-50px)' : 'scale(1) translateY(0)'}
        transition="0.2s"
        zIndex="1"
      >
        <Stack direction="row" justifyContent="space-between" alignItems="start">
          <Heading fontSize="md">Extras</Heading>
          <Button variant="ghost" color="primary" _hover={{ color: 'primaryHover' }} onClick={handleClickExtras}>
            <BiX fontSize="1.5rem" display={{ base: 'none', md: 'block' }} />
          </Button>
        </Stack>
        <CheckboxGroup colorScheme="red">
          <Stack

          >
            <Accordion defaultIndex={[0]} allowMultiple allowToggle>
              <Stack spacing={2}>
                {extras.map((item, index) => {
                  return (
                    <DetaExtra pExtra={item} />
                  );
                })}
              </Stack>
            </Accordion>
          </Stack>
        </CheckboxGroup>
        <Stack direction={'row'} spacing={6} justifyContent="flex-end">
          <Button color="primary" variant="link" _hover={{ bg: 'none' }} _active={{ color: 'primaryHover' }} onClick={limpiarExtras} >
            <Text>Borrar filtros</Text>
          </Button>
          <Button color="error" variant="link" _hover={{ bg: 'none' }} onClick={buscarVehiculo} >
            <Text _active={{ color: 'primaryHover' }}>Aplicar</Text>
          </Button>
        </Stack>
      </Stack>
      <Stack mt="10" mx={{ base: '0', md: '5%' }} hidden={!verR}>
        <Heading fontSize="md" mb="5">
          {(listaVehiculo.length>0)?'Resultados de búsqueda':
          <Box flex="1" bg="#F2F1F1" minH="100%" hidden={listaVehiculo.length>0}>
            <Stack bg="secondary" boxShadow="sm" direction="row" justifyContent="center" pr={4}>
              <Alert status="warning" bg="none" color="light">
                <AlertIcon color="light" alignSelf="flex-start" />
                {sinResultados}
              </Alert>
            </Stack>
          </Box>}
        </Heading>
        
        <Accordion defaultIndex={[0]}>
          <Stack spacing={4}>
            {listaVehiculo.map((item, index) => {
              return (
                <Resultados vh={item} permiso={listaPermiso} />
              );
            })}
          </Stack>
        </Accordion>
      </Stack>
    </Fragment>
  );
};


const BuscarCodigo = () => {  
  return (
    <Container maxW="container.xl" minH="100%" mb={4}>
      <Busqueda />     
    </Container>
  );
};

export default BuscarCodigo;
