import React from 'react';
import {
  Container,
  Stack,

} from '@chakra-ui/react';

import { Busqueda } from './Busqueda';


const Simulador = () => {
  return (
    <Stack spacing={0}>
      <Container maxW={{ base: '100%', lg: '100%' }} py={3} bg={{ base: 'none', lg: 'light' }}>
        <Stack direction={{ base: 'column', lg: 'row' }} justifyContent="space-between" bg={{ base: 'none', lg: 'light' }} >
          <Busqueda borderColor="#000000" />
        </Stack>
      </Container>
    </Stack>
  )
  /*
  return (
    <Stack spacing={0}>
      <Container maxW={{ base: '100%', lg: '100%' }} py={3} bg={{ base: 'none', lg: 'light' }}>
        <Stack direction={{ base: 'column', lg: 'row' }} justifyContent="space-between"  bg={{ base: 'none', lg: 'light' }} >         
          <Box display={{ base: 'block', lg: 'none' }} bg={{ base: '#000000', lg: '#000000' }}>
            <Busqueda borderColor="#000000" />
          </Box>
        </Stack>
      </Container>
      <Stack direction={{ base: 'column', lg: 'row' }} spacing={0} >
        <Box flex="1" bg="#E4E4E3" minH="100%">
          <Box bg={{ base: 'none', lg: 'light' }} h={{ base: '0', lg: '16' }}></Box>
          <DetallesSimulador />
        </Box>
        <Box flex="2" bg="#F2F1F1" minH="100%">
          <Box display={{ base: 'none', lg: 'flex' }}  bg={{ base: 'none', lg: 'light' }}>
            <Busqueda />
          </Box>
          <PrecioRecibo />
        </Box>
      </Stack>
    </Stack>
  );
  */
};

export default Simulador;
