import React, { useState, useEffect } from 'react';

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useDisclosure,
  Stack,
  FormControl,
  FormLabel,
  Input,
  Divider,
  Select,
  Alert,
  AlertIcon,
  AlertDescription,
} from '@chakra-ui/react';
import { mostrarError, mostrarExito, mostrarWarning } from '../Util/Notification/Notification'
import { ObtenerEmpresasRequest } from '../../request/EmpresasRequest';
import ConstantesSistema from '../../Constants/ConstantesSistema';
import { EnvioSFRequest } from '../../request/SalesForceRequest';
import Loading from '../../components/Util/Loading/Loading';
import ConstantesConsultaEmpresa from '../../Constants/ConstantesConsultaEmpresa';

export const PlacaModale = ({ avaluo, vehiculo }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [listaEmpresas, setListaEmpresas] = useState([]);
  const [loading, setLoading] = useState({ empresa: false, loading: false, envioSf: false });
  const [datos, setDatos] = useState({ empresa: 0, op: '', resMensaje: '', resObjeto: '',resExitoso:false });
  const onAbrir = async () => {
    Inicializa();
    onOpen();
  }
  const onCerrar = async () => {
    onClose();
  }
  const onEmpresaChange = async (valor) => {
    setDatos({ ...datos, empresa: valor });
  }
  const Inicializa = async () => {
    setLoading({ empresa: false, loading: false, envioSf: false });
    setDatos({ empresa: 0, op: '' });
  }
  const handleChange = (e) => {
    let searchval = e.target.value;
    setDatos({ ...datos, op: searchval });
  };
  const ObtenerEmpresas = async () => {
    setLoading({ ...loading, empresa: true });
    const response = await ObtenerEmpresasRequest(localStorage.getItem(ConstantesSistema.nombre_Tkn), ConstantesConsultaEmpresa.EnvioSF);
    if (response.exitoso) {
      setListaEmpresas(response.objeto);
    }
    else {
      mostrarError(response.mensajeRespuesta);
    }
    setLoading({ ...loading, empresa: false });
  }
  const EnvioSF = async () => {
    var vH = vehiculo;
    vH.numOP = datos.op;
    vH.anno = vH.anio.toString();
    vH.tk = null;
    vH.historialTaller = (avaluo.historialTaller === 1) ? 'Si' : 'No';
    vH.usuario = localStorage.getItem(ConstantesSistema.username);
    vH.empresaId = vH.emp;
    setLoading({ ...loading, loading: true });
    console.clear();
    const response = await EnvioSFRequest(localStorage.getItem(ConstantesSistema.nombre_Tkn), vH);
    setLoading({ ...loading, envioSf: true, loading: false });
    setDatos({ ...datos, resMensaje: response.mensajeRespuesta, resObjeto: response.objeto, resExitoso: response.exitoso });
  }
  useEffect(() => {
    ObtenerEmpresas();
  }, []);
  return (
    <>
      <Button
        onClick={onAbrir}
        variant="solid"
        color="light"
        bg="secondary"
        rounded="md"
        w={40}
        _hover={{ bg: 'secondaryHover' }}
      >
        Enviar a Salesforce
      </Button>
      <Modal isOpen={isOpen} onClose={onCerrar} size="lg">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontSize="2rem" color="secondary" textAlign="center">
            {vehiculo.placa}
          </ModalHeader>
          <Divider />
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing={4}>
              <FormControl id="user">
                <FormLabel fontWeight="bold">Compañía</FormLabel>
                <Select
                  placeholder=">> Seleccione una empresa <<"
                  variant="outline"
                  bg="light"
                  focusBorderColor="borders"
                  borderColor="borders"
                  _focus={{ bg: 'light' }}
                  boxShadow="sm"
                  value={datos.empresa}
                  onChange={(event) => onEmpresaChange(event.currentTarget.value)}
                >
                  {listaEmpresas.map((emp, index) =>
                    <option key={index} value={emp.id}>
                      {emp.nombre}
                    </option>)}
                </Select>
              </FormControl>
              <FormControl id="emailpassword">
                <FormLabel fontWeight="bold">Número de OP</FormLabel>
                <Input
                  borderColor="borders"
                  focusBorderColor="none"
                  variant="outline"
                  placeholder="Número de OP"
                  value={datos.op}
                  onChange={handleChange}
                />
              </FormControl>
            </Stack>
          </ModalBody>
          <ModalFooter justifyContent="center">
            <Stack hidden={datos.op === '' || parseInt(datos.empresa) === 0 || loading.loading || loading.envioSf}>
              <Button onClick={EnvioSF} color="light" variant="solid" background="primary" _hover={{ bg: 'primaryHover' }} px={8}>
                Enviar
              </Button>
            </Stack>
            <Stack hidden={!loading.loading || loading.envioSf}>
              <Loading color="#D8222B" texto="Enviando a Salesforce" tam={30} />
            </Stack>
          </ModalFooter>
        </ModalContent>

        {/* Mensaje de éxito - Quitar: display="none" */}
        <Stack hidden={!loading.envioSf}>
          <ModalContent>
            <ModalHeader>
              <ModalCloseButton zIndex="3" />
              <Stack justifyContent="center">
                <Alert status={(datos.resObjeto===null)?"success":"warning"} bg="light">
                  <Stack w="100%" justifyContent="center" direction="column" alignItems="center" spacing={4}>
                    <AlertIcon w={12} h={12} />
                    <Stack justifyContent="center" hidden={datos.resObjeto === null || datos.resObjeto === ''}>
                      <AlertDescription textAlign="center">
                        {datos.resObjeto}
                      </AlertDescription>
                    </Stack>
                    <Stack justifyContent="center" hidden={datos.resObjeto != '' && datos.resObjeto != null}>
                      <AlertDescription textAlign="center">
                        {datos.resMensaje}
                      </AlertDescription>
                    </Stack>
                  </Stack>
                </Alert>
              </Stack>
            </ModalHeader>
            <ModalFooter justifyContent="center">
              <Button onClick={onClose} color="body" variant="outline" background="light" _hover={{ bg: 'none' }} px={8}>
                Cerrar
              </Button>
            </ModalFooter>
          </ModalContent>
        </Stack>
      </Modal>
    </>
  );
};

export default PlacaModale;
