import React, { useState, useEffect } from 'react';
import {
  Stack,
  Box,
  Text,
  Button,
  Input,
} from '@chakra-ui/react';

import ConstantesSistema from '../../Constants/ConstantesSistema';

export const Codigo = ({ Item, texto, onChangeEstilo, enviadoComparacion, pTextoAutomatico, pTipoBusqueda }) => {

  const [searchtext, setSearchtext] = useState(texto);
  const [suggest, setSuggest] = useState([]);
  const [resfound, setResfound] = useState(true);
  const [codVehiculo, setCodVehiculo] = useState('');
  
  useEffect(() =>{
    // if(codVehiculo){
    //   onChangeEstilo(codVehiculo);
    // }
  }, [codVehiculo]);


  useEffect(() => {

    //if(localStorage.getItem(ConstantesSistema.vehiculoSel) && localStorage.getItem(ConstantesSistema.tipoBusqueda) === ConstantesSistema.tipoBusCodigo){
    

  }, []);

  useEffect(() => {
    setSearchtext('');
  }, [pTipoBusqueda])

  useEffect(()=>{

    if(enviadoComparacion){
      if(pTipoBusqueda === ConstantesSistema.tipoBusCodigo){
        setSearchtext(JSON.parse(localStorage.getItem(ConstantesSistema.vehiculoSel)).descripcion);
      }
      else if(pTipoBusqueda === ConstantesSistema.tipoBusPlaca){
        setSearchtext(pTextoAutomatico);
      }
    }

  }, [pTextoAutomatico])
    
    const handleChange = (e) => {
      let searchval = e.target.value;
      let vTipo = parseInt(localStorage.getItem(ConstantesSistema.tipoBusqueda));
      if (Item != null && vTipo === ConstantesSistema.tipoBusCodigo) {
        if (Item.length > 0) {
          if (searchval.length > 1) {
            const Obtener = async () => {
              const suggestion = Item.sort().filter((e) => e.descripcion.toLowerCase().includes(searchval.toLowerCase()) || e.codigo.toLowerCase().includes(searchval.toLowerCase()));
              setSuggest(suggestion);
              setResfound(suggestion.length !== 0 ? true : false);
            }
            Obtener();
          }
        }
      } else if (vTipo === ConstantesSistema.tipoBusPlaca) {
        localStorage.setItem(ConstantesSistema.vehiculoSel, searchval);
      }
      setSearchtext(searchval);
    };
    const onKeyDownCod = (e) => {
      if (e.keyCode === 13 || e.keyCode === 9) {
        if (suggest.length > 0) {
          suggestedText(suggest[0])
        } else {
          if (parseInt(localStorage.getItem(ConstantesSistema.tipoBusqueda)) === ConstantesSistema.tipoBusPlaca) {
            localStorage.setItem(ConstantesSistema.iniciaBuscar, ConstantesSistema.iniciaBuscar);
          }
        }
      }
    }
    const suggestedText = (value) => {
      setSearchtext(value.descripcion);
      setSuggest([]);
      if (localStorage.getItem(ConstantesSistema.tipoBusqueda) === localStorage.getItem(ConstantesSistema.tipoBusPlaca)) {
        localStorage.setItem(ConstantesSistema.vehiculoSel, value);
        setCodVehiculo(value);
      } else {
        localStorage.setItem(ConstantesSistema.vehiculoSel, value.codigo);
        setCodVehiculo(value.codigo);
        onChangeEstilo(value.codigo);
      }
    };
  
    const getSuggestions = () => {
      if (suggest.length === 0 && searchtext !== '' && !resfound) {
        return (
          <Stack
            overflowY="auto"
            maxH="26.3rem"
            p={2}
            w="max-content"
            pos="absolute"
            bg="light"
            zIndex="3"
            boxShadow={{ base: 'md', lg: 'md' }}
            rounded="md"
          >
            <Box display="grid" justifyItems="start">
              <Text>No se encontraron resultados</Text>
            </Box>
          </Stack>
        );
      }
    };
  
    return (
      <Box
        w={{ base: '100%', md: '100%' }}
        px={{ base: '2', xl: '0' }}
        borderLeftRadius={{ base: 'none', md: 'none' }}
        boxShadow={{ base: 'sm', xl: 'none' }}
        py={{ base: '0', xl: '0' }}
        bg={{ base: 'none', xl: 'none' }}
      >
        <Input
          borderColor="#E4E4E3"
          focusBorderColor="none"
          variant="flushed"
          placeholder={texto}
          value={searchtext}
          onChange={handleChange}
          onKeyDown={onKeyDownCod}
          fontSize={{ base: 'sm', lg: 'md' }}
        />
        {getSuggestions()}
        <Stack
          overflowY="auto"
          maxH="10rem"
          p={!searchtext ? '1' : '0'}
          w="max-content"
          pos="absolute"
          bg="light"
          zIndex="3"
          boxShadow={{ base: 'md', lg: 'md' }}
          rounded="md"
          visibility={searchtext ? 'visible' : 'hidden'}
        >
          <Box display="grid" justifyItems="start">
            {suggest.map((item, index) => {
              return (
                <Box key={index}>
                  <Button variant="ghost" rounded="full" onClick={() => suggestedText(item)}>
                    {item.codigo} - {item.descripcion}
                  </Button>
                  {index !== suggest.length - 1}
                </Box>
              );
            })}
          </Box>
        </Stack>
      </Box>
    );
  };