import React, { useState,useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { NavLinkStyled } from '../../styles';
import { mostrarError, mostrarExito, mostrarWarning } from '../Util/Notification/Notification'
import {
  Container,
  Text,
  Box,
  Flex,
  Stack,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  Divider,
  useDisclosure,
  Image,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Avatar,
} from '@chakra-ui/react';
import { Icon } from '@chakra-ui/icons';
import { BiUser, BiExit, BiMenu } from 'react-icons/bi';
import { FaUserCircle } from 'react-icons/fa';
import logo from '../../assets/images/logo.png';
import { ObtenerMenuRequest,ObtenerMenuOpcionesRequest } from '../../request/MenuRequest';
import ConstantesSistema from '../../Constants/ConstantesSistema';

export const UserAvatar = () => {
  const [imagen,setImagen] = useState(localStorage.getItem(ConstantesSistema.imagen));
  const [userName,setUserName] = useState(localStorage.getItem(ConstantesSistema.username));
 
  const errorCarga = ()=>{
    localStorage.setItem(ConstantesSistema.imagen, ConstantesSistema.imagenError);    
    setImagen( ConstantesSistema.imagenError);
  }
  return (
    <Box>
      <Avatar 
        onError={errorCarga} 
        fallback={<FaUserCircle fontSize="3rem" />}
        size="md" 
        name={userName} 
        src={imagen} 
        />
    </Box>
  );
};

const Navbar = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [menuUsuario,setMenuUsuario] = useState([]);
  const [userName,setUserName] = useState(localStorage.getItem(ConstantesSistema.username));
  const [menuSel,setMenuSel] =  useState(localStorage.getItem(ConstantesSistema.menuSel));
  const history = useHistory()
  useEffect(() => {
    const ObteneSubMenu = async(menu)=>{
      const subMenu = JSON.parse(localStorage.getItem(ConstantesSistema.opcionesSubMenu));
      if(subMenu===null){
        var vLista=[];
        for (let x = 0; x <= menu.length - 1; x++) {
          var response= await ObtenerMenuOpcionesRequest(localStorage.getItem(ConstantesSistema.nombre_Tkn),parseInt(menu[x].id));
          if(response){
            //response.objeto = response.objeto.filter(x=>x.menu_Id != 3 && x.opcion_Id !=2)
            vLista.push(...response.objeto);
          }
        }
        localStorage.setItem(ConstantesSistema.opcionesSubMenu, JSON.stringify(vLista));        
      }
    }
    const Obtener = async () => {
      var menu =  JSON.parse(localStorage.getItem(ConstantesSistema.opcionesMenu));
      if(menu===null){
        const vDatos = await ObtenerMenuRequest(localStorage.getItem(ConstantesSistema.nombre_Tkn));
        if (vDatos.exitoso) {        
          setMenuUsuario(vDatos.objeto.filter(x=>x.padreID===0 && x.url.toString().includes('PB')));
          await ObteneSubMenu(vDatos.objeto.filter(x=>x.padreID===0 && x.url.toString().includes('PB')));
        }
        else {
          mostrarError(vDatos.mensajeRespuesta);
        }
      }else{
        var vMenu = menu.filter(x=>x.padreID===0 && x.url.toString().includes('PB'));
        setMenuUsuario(vMenu);
        await ObteneSubMenu(vMenu);
      }
     
    }
    Obtener();    
  }, []);
 const onclick=(pOpcion)=>{
  localStorage.setItem(ConstantesSistema.menuSel, pOpcion);        
  localStorage.removeItem(ConstantesSistema.listaVh);   
  localStorage.removeItem(ConstantesSistema.regresar);   
  localStorage.removeItem(ConstantesSistema.vehiculoDet);   
  localStorage.removeItem(ConstantesSistema.vehiculoSel);   
  localStorage.removeItem(ConstantesSistema.tipoBusqueda);  
  localStorage.removeItem(ConstantesSistema.listaComparar);
  localStorage.removeItem(ConstantesSistema.paramMeses);
  localStorage.removeItem(ConstantesSistema.iniciaComparar);
  localStorage.removeItem(ConstantesSistema.annioSel);
 }
 const goHome=()=>{
  history.push(ConstantesSistema.SiteRutas.Home);
 }
  return (
    <>
      <Box bg="primary" color="light" pos="sticky" top="0" zIndex={3} boxShadow="sm" >
        <Container maxW="90%">
          <Flex
            h={16}
            alignItems={'center'}
            justifyContent={'space-between'}
            display={{ base: 'none', lg: 'flex' }}
            m="0 auto"
          >
            <Stack direction="row" spacing={7} alignItems={'center'}>
              <Image src={logo} alt="Logo" width={36}  onClick={goHome} cursor="pointer" />
              <Stack direction="row" as={'nav'} spacing={8}>
                {menuUsuario.map((item, index) => {
                  return (
                    <Box
                      px={2}
                      py={1}
                      rounded={'md'}
                      transition="100ms"
                      _hover={{
                        textDecoration: 'none',
                        bg: 'primaryHover',
                      }}
                      className={(parseInt(item.id)===parseInt(menuSel))?"btn btn-secondary":""}
                      key={index}
                      fontFamily='Monserrat2'
                    >
                      <NavLinkStyled to={item.url} onClick={()=>onclick(item.id)}>{item.nombre}</NavLinkStyled>
                    </Box>
                  );
                })}
              </Stack>
            </Stack>
            <Flex alignItems={'center'} display={{ base: 'none', lg: 'flex' }}>
              <Menu placement="bottom-start">
                <MenuButton
                  as={Button}
                  rounded={'full'}
                  variant={'ghost'}
                  cursor={'pointer'}
                  minW={0}
                  bl="1px solid light"
                  _hover={{ bg: 'none' }}
                  _active={{ bg: 'none' }}
                >
                  <Stack px={6} py={4} borderLeft="1px solid white" direction="row" alignItems="center" spacing={4} >
                    <Text color="light">{userName}</Text>
                    <Avatar bg="trasparent" color="light" icon={<BiUser fontSize="2rem" />} hidden={true}  />
                    <UserAvatar />
                  </Stack>
                </MenuButton>
                <MenuList color="body">
                  <MenuItem justifyContent="flex-start" variant="ghost" as={Button} leftIcon={<BiUser />}>
                    {userName}
                  </MenuItem>
                  <MenuDivider />
                  <MenuItem justifyContent="flex-start" variant="ghost" as={Button} leftIcon={<BiExit />}>
                  <NavLinkStyled to={ConstantesSistema.SiteRutas.Login}>Cerrar Sesión</NavLinkStyled>                   
                  </MenuItem>
                </MenuList>
              </Menu>
            </Flex>
          </Flex>

          <Flex h={16} alignItems={'center'} justifyContent={'space-between'} display={{ base: 'flex', lg: 'none' }}>
            <Box w={"8rem"} display={{ base: 'none', sm:'flex', lg: 'none' }}>
              <Text fontSize="lg">
                PURDY BOOK
              </Text>
            </Box>
            <Image src={logo} alt="Logo" w={20} alignContent={'center'} onClick={goHome} />
            <Box pl={2} py={2} borderLeft="1px solid white">
              <Icon color="light" as={BiMenu} w={12} h={12} onClick={onOpen} />
            </Box>
            <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
              <DrawerOverlay />
              <DrawerContent display={{ base: 'block', lg: 'none' }}>
                <DrawerCloseButton fontSize="1rem" mr="16rem" />
                <DrawerHeader>
                  <Flex alignItems={'center'} justifyContent={'flex-end'}>
                    <Box mr={1} fontSize="xl" textAlign="right">
                      <Text>¡Hola {userName}!</Text>
                      <Text color="tertiary">Purdy Book</Text>
                    </Box>
                    <Stack pl={6} py={6} borderLeft="1px solid white" direction="row" alignItems="center" spacing={4}>                     
                      <UserAvatar />
                    </Stack>
                  </Flex>
                  <Divider opacity="1" />
                </DrawerHeader>
                <DrawerBody>
                  <Stack as={'nav'} spacing={8} py={2} textAlign="right" fontFamily='Monserrat2' fontWeight="bold" fontSize="xl" >
                    {menuUsuario.map((item, index) => {
                      return (
                        <Box key={index} fontFamily='Monserrat2'>
                          <NavLinkStyled to={item.url} onClick={()=>onclick(item.id)}>{item.nombre}</NavLinkStyled>
                        </Box>
                      );
                    })}
                    <Divider opacity="1" />
                    <Text hidden={true} fontWeight="bolder">Cerrar Sesión</Text>
                    <NavLinkStyled to={ConstantesSistema.SiteRutas.Login} >Cerrar Sesión</NavLinkStyled> 
                  </Stack>
                </DrawerBody>
              </DrawerContent>
            </Drawer>
          </Flex>
        </Container>
      </Box>
    </>
  );
};

export default Navbar;
