import React, { useState,useEffect } from 'react';
import { NavLinkStyled } from '../../styles';
import { useHistory } from "react-router-dom";
import { mostrarError, mostrarExito, mostrarWarning }from '../Util/Notification/Notification';
import {
    Container,
    Text,
    Box,
    Stack
  } from '@chakra-ui/react';

import ConstantesSistema from '../../Constants/ConstantesSistema';
import { ObtenerMenuRequest} from '../../request/MenuRequest';
import { FaHome,FaCarAlt } from 'react-icons/fa';
import {ObtenerParamRequest} from '../../request/ParamRequest';
import { ObtenerEmpresasRequest } from '../../request/EmpresasRequest';
import ConstantesConsultaEmpresa from '../../Constants/ConstantesConsultaEmpresa';

const MenuHome = () => {
    const [lista,setLista]=useState([]);
    const history = useHistory()
    const ObtenerMenu =async()=>{
        const Menu = JSON.parse(localStorage.getItem(ConstantesSistema.opcionesMenu));
        //console.log(Menu);

        if(Menu===null){
            await ConsultarMenu();
        }else{
            var vMenu = Menu.filter(x=>x.padreID===0 && x.url.toString().includes('PB'));
            //console.log(vMenu);
            setLista(vMenu);
        }
    }
    const ConsultarMenu = async()=>{
        const vDatos = await ObtenerMenuRequest(localStorage.getItem(ConstantesSistema.nombre_Tkn));
        if (vDatos.exitoso) {        
            var Menu = vDatos.objeto.filter(x=>x.padreID===0 && x.url.toString().includes('PB'));
          
            setLista(Menu);
            localStorage.setItem(ConstantesSistema.opcionesMenu, JSON.stringify(Menu));        
        }
        else {
          mostrarError(vDatos.mensajeRespuesta);
        }
    }
    const goUrl = async (item) => {
        localStorage.removeItem(ConstantesSistema.listaVh);
        localStorage.removeItem(ConstantesSistema.regresar);
        localStorage.removeItem(ConstantesSistema.vehiculoDet);
        localStorage.removeItem(ConstantesSistema.vehiculoSel);
        localStorage.removeItem(ConstantesSistema.tipoBusqueda);
        localStorage.removeItem(ConstantesSistema.listaComparar);
        localStorage.removeItem(ConstantesSistema.paramMeses);
        localStorage.removeItem(ConstantesSistema.iniciaComparar);
        localStorage.removeItem(ConstantesSistema.annioSel);
        localStorage.setItem(ConstantesSistema.menuSel, item.id);        
        var url = ConstantesSistema.ruta + item.url;
        history.push(url);
    }
    const CargarParametros = async () => {
        const response = await ObtenerParamRequest(localStorage.getItem(ConstantesSistema.nombre_Tkn));
        if (response.exitoso) {
            var valor = response.objeto.filter(x => x.id === ConstantesSistema.paramNoVh);
           // localStorage.setItem(ConstantesSistema.paramNoVh, valor[0].valor);
           await AlmacenarParametro(response.objeto,ConstantesSistema.paramNoVh)
           await AlmacenarParametro(response.objeto,ConstantesSistema.paramCantExtras)
        }
    }
    const AlmacenarParametro=async(pLista,pParam)=>{
        var valor =pLista.filter(x => x.id === pParam);
        if(valor != null){
            if(valor.length>0){
                localStorage.setItem(pParam, valor[0].valor);
            }
        }        
    }
    const ObtenerEmpresas = async () => {
        var lista = localStorage.getItem(ConstantesSistema.empresaLista)
        if(lista ===null){
            const vDatos = await ObtenerEmpresasRequest(localStorage.getItem(ConstantesSistema.nombre_Tkn), ConstantesConsultaEmpresa.Consultas);
            if (vDatos.exitoso) {
                localStorage.setItem(ConstantesSistema.empresaLista, JSON.stringify(vDatos.objeto));
            }
            else {
                mostrarError(vDatos.mensajeRespuesta);
            }
        }        
    }
    useEffect(() => {
        ObtenerMenu();  
        CargarParametros();  
        ObtenerEmpresas();
      }, []);
    return (
        <>
            <Container maxW={{ base: '100%', md: '100%' }} >
                <Stack ml={{ base: "2%", md: "4%" }} mt={{ base: "5%", md: "5%" }} mb={{ base: "50%", md: "30%" }} direction={{ base: 'column', lg: 'row' }}>
                    {lista.map((item, index) => {
                        return (
                            <Stack
                                w={{ base: '100%', lg: '30%' }}
                                border="1px solid #F2F1F1"
                                bg="light"
                                transition="0.3s"
                                p={4}
                                rounded="md"
                                cursor="pointer"
                                _hover={{ bg: 'light', boxShadow: 'lg', transform: 'translateY(-2px)' }}
                                onClick={() => goUrl(item)}
                            >
                                <Box key={index} display="flex">
                                    <Stack mr="1rem" fontSize="3rem" color="error" ><FaCarAlt /></Stack>
                                    <Text mt="0.5rem" fontWeight="bold" >{item.nombre}</Text>
                                </Box>
                            </Stack>
                        );
                    })}
                </Stack>
            </Container>
        </>
    );
};

export default MenuHome;