import * as actionTypes from './actionTypes';
import Constantes from '../../Constants/ConstantesSistema';

const initialState = {
    token: localStorage.getItem(Constantes.nombre_Tkn),
    nombre: '',
    estaLogueado: false,
    imagen: 'https://dummyimage.com/40'
};

export default function (state = initialState, action) {
    switch (action.type) {
        case actionTypes.SET_USER_INFO:
            localStorage.setItem(Constantes.nombre_Tkn, action.payload.token);
            return {
                ...state,
                ...action.payload
            };
        case actionTypes.SET_USER_LOGGED_IN:
            let token = state.token;
            if(!action.payload) {
                localStorage.setItem(Constantes.nombre_Tkn, "");
                token = '';
            }
            return {
                ...state,
                token,
                estaLogueado: action.payload
            };
        default:
            return state;
    }
}