import { GetResponseBody, baseUrlApi,baseUrlApiPC, GetHeaderRequest } from './PathConfig';

export const AvaluoRequest = async (pToken,pEmpresaId,placa) => {
    const data = { empresaId: pEmpresaId, placa: placa };

    const result = await fetch(`${baseUrlApi}api/PrecioAvaluo/ObtenerAvaluoPorPlaca`, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: GetHeaderRequest(pToken)
    });
    return await result.json();
};