import { GetResponseBody, baseUrlApi,baseUrlApiPC, GetHeaderRequest } from './PathConfig';

export const LoginRequest = async (pUserName, pPassword) => {
    const data = { username: pUserName, password: pPassword };
    //console.log(data, baseUrlApi);
    const result = await fetch(`${baseUrlApi}api/Login/Login`, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json'
        }
    });
    return await GetResponseBody(result);
};

export const ObtenerRutaImagenes = async (pToken) => {
    const result = await fetch(`${baseUrlApi}api/Configuration/Obtener_Ruta_Imagenes`, {
        method: 'GET',
        headers: GetHeaderRequest(pToken)
    });
    return await GetResponseBody(result);
};

export const LoginOpcionRequest = async (pToken, pMenuId, pOpcionId, pUserName, pPassword) => {
    const data = { Menu_Id: pMenuId, Opcion_Id: pOpcionId, Username: pUserName, Password: pPassword };
    const result = await fetch(`${baseUrlApi}Seguridad/Seg_Usuario/Seg_Usuario_Valida_Opcion`, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: GetHeaderRequest(pToken)
    });
    return await GetResponseBody(result);
};

export const CambiarContraseniaRequest = async (pToken,pDatos) => {
    const result = await fetch(`${baseUrlApi}api/Usuario/Cambiar_Contrasenia`, {
        method: 'POST',
        body: JSON.stringify(pDatos),
        headers: GetHeaderRequest(pToken)
    });
    return await GetResponseBody(result);
};
export const OlvideContraseniaRequest = async (pUsuario) => {
    const result = await fetch(`${baseUrlApi}api/Usuario/Olvide_Contrasenia?usuario=${pUsuario}`, {
        method: 'POST',
    });
    return await GetResponseBody(result);
};
export const LoginPCRequest= async (pUserName, pPassword) => {
    const data = { username: pUserName, password: pPassword };
    const result = await fetch(`${baseUrlApiPC}Seguridad/Login/Login`, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json'
        }
    });
    return await GetResponseBody(result);
};