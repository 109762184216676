import styled from 'styled-components';
   
export const Box = styled.div`
  padding: 5px 80px;
  background: black;
  position: fixed;
  bottom: 0;
  width: 100%;
    
  @media (max-width: 1000px) {
    padding: 5px 30px;
  }
`;
   
export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 1000px;
    margin: 0 auto;
`
   
export const Column = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-left: 1px;
`;
export const Column2 = styled.div`
display: flex;
flex-direction: column;
text-align: left;
margin-left: 1px;
`;
export const Row = styled.div`
  display: grid;
  text-align: center;
  grid-template-columns: repeat(auto-fill, 
                         minmax(100%, 1fr));
  grid-gap: 20px;
   
  @media (max-width: 1000px) {
    grid-template-columns: repeat(auto-fill, 
                           minmax(200px, 1fr));
  }
`;
   
export const FooterLink = styled.div`
  color: #fff;
  margin-bottom: 2px;
  font-size: 14px;
  text-decoration: none;
   float:left;
   margin-left:15px;
  &:hover {
      color: #CC9D03;
      transition: 200ms ease-in;
      cursor:pointer;
  }
`;
   
export const Heading = styled.div`
    color: #fff;
    margin-bottom: 2px;
    font-size: 14px;
    float:left;
    text-decoration: none;
    margin-left:15px;
    @media (max-width: 1000px) {
        display:none
      }
`;

