import React, { useState, Fragment, useEffect } from 'react';
import { useHistory } from "react-router-dom"
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useDisclosure,
  Stack,
  FormControl,
  FormLabel,
  Select,
  Divider,
  Input,
  Box,
  Text,
  InputGroup,
  InputRightElement,
  Image,
  Icon,
} from '@chakra-ui/react';
import { FaCarAlt, FaCalendarAlt, FaTrashAlt } from 'react-icons/fa';
import { mostrarError, mostrarExito, mostrarWarning } from '../Util/Notification/Notification'
import Loading from '../../components/Util/Loading/Loading';
import { ObtenerEmpresasRequest } from '../../request/EmpresasRequest';
import ConstantesSistema from '../../Constants/ConstantesSistema';
import { ObtenerEstiloRequest, ObtenerAnnosPorEstiloRequest,ObtenerEquipamientoRequest } from '../../request/VehiculosRequest'
import {ObtenerDatosRequest} from '../../request/CompararRequest';
import {ObtenerParamIdRequest} from '../../request/ParamRequest';
import ConstantesConsultaEmpresa from '../../Constants/ConstantesConsultaEmpresa';
import ConstantesMenu from '../../Constants/ConstantesMenu';

export const Annios = ({ Item }) => {
  const [searchtext, setSearchtext] = useState('');
  const [suggest, setSuggest] = useState([]);
  const [resfound, setResfound] = useState(true);
  const handleChange = (e) => {
    let searchval = e.target.value;
    let suggestion = [];
    if (searchval.length > 0) {
      suggestion = Item.sort().filter((e) => e.toString().toLowerCase().includes(searchval.toLowerCase()));
      setResfound(suggestion.length !== 0 ? true : false);
    }
    setSuggest(suggestion);
    setSearchtext(searchval);
  }; 
  const onKeyDownAnio = (e)=>{
    if(e.keyCode===13 || e.keyCode===9){
      if(suggest.length>0){
        suggestedText(suggest[0])
      }      
    } 
  }
  const suggestedText = (value) => {
    if (value != null && value != '') {
      localStorage.setItem(ConstantesSistema.annioSel, value);
    } else {
      localStorage.removeItem(ConstantesSistema.annioSel)
    }
    setSearchtext(value);
    setSuggest([]);
  };
  function limpiar() {
    if(Item.length===0 && searchtext !=''){
      setSearchtext('');
    }
    
  }
  limpiar()
  //suggestedText('');
  const getSuggestions = () => {
    if (suggest.length === 0 && searchtext !== '' && !resfound) {
      return (
        <Stack
          overflowY="auto"
          maxH="10rem"
          p={2}
          w="max-content"
          pos="absolute"
          bg="light"
          zIndex="3"
          boxShadow={{ base: 'md', lg: 'md' }}
          rounded="md"
        >
          <Box display="grid" justifyItems="start">
            <Text>No se encontraron resutados</Text>
          </Box>
        </Stack>
      );
    }
  };

  return (
    <Box w="100%">
      <Input
        placeholder="Año"
        variant="outline"
        bg="light"
        focusBorderColor="borders"
        borderColor="borders"
        _focus={{ bg: 'light' }}
        boxShadow="sm"
        value={searchtext}
        onChange={handleChange}
        onKeyDown={onKeyDownAnio}
      />
      {getSuggestions()}
      <Stack
        overflowY="auto"
        maxH="10rem"
        p={!searchtext ? '1' : '0'}
        w={!searchtext ? '18rem' : 'max-content'}
        pos="absolute"
        bg="light"
        zIndex="3"
        boxShadow={{ base: 'md', lg: 'md' }}
        rounded="md"
        visibility={searchtext ? 'visible' : 'hidden'}
      >
        <Box display="grid" gridTemplateColumns="repeat(4, 1fr)" justifyItems="center">
          {suggest.map((item, index) => {
            return (
              <Box key={index}>
                <Button variant="ghost" rounded="full" onClick={() => suggestedText(item)}>
                  {item}
                </Button>
                {index !== suggest.length - 1}
              </Box>
            );
          })}
        </Box>
      </Stack>
    </Box>
  );
};

// const Vehiculo = ({ estilo,descripcion,anio,visible }) => {
//   return (
//     <Stack
//       bg="light"
//       w="100%"
//       p={2}
//       border="1px solid #F2F1F1"
//       transition="0.2s"
//       _hover={{ bg: 'light', boxShadow: 'lg', transform: 'translateY(-2px)' }}
//       cursor="pointer"
//       minH="100%"
//       justifyContent="center"
//       hidden={!visible}
//     >
//       <Stack justifyContent="center">
//         <Box>
//           <Button variant="link" pt={1} >
//             <Icon
//               as={FaTrashAlt}
//               h={4}
//               w={4}
//               color="error"
//               transition="0.2s"
//               _hover={{ transform: 'translateY(-2px)' }}
//               _active={{ transform: 'translateY(0)' }}
//             />
//           </Button>
//         </Box>
//         <Stack p={0}>          
//           <Box textAlign="center" fontWeight="bold" fontSize="sm">
//             <Text fontWeight="black">{descripcion}</Text>
//             <Text >{estilo}</Text>
//             <Text >{anio}</Text>
//           </Box>
//         </Stack>
//       </Stack>
//     </Stack>
//   );
// };

// const VehiculoVacio = () => {
//   return (
//     <Stack
//       bg="light"
//       w="100%"
//       display={{ base: 'none', md: 'flex' }}
//       // w={{ base: '100%', sm: '48%', lg: '24%' }}
//       p={2}
//       border="1px solid #F2F1F1"
//       transition="0.3s"
//       _hover={{ bg: 'light', boxShadow: 'lg', transform: 'translateY(-2px)' }}
//       cursor="pointer"
//       minH="100%"
//       justifyContent="center"
//     >
//       <Stack alignItems="center">
//         <Box color="#F2F1F1">
//           <FaCarAlt fontSize="5rem" />
//         </Box>
//         <Box textAlign="center" fontWeight="bold">
//           <Text>Vehículo</Text>
//         </Box>
//       </Stack>
//     </Stack>
//   );
// };

export const PlacaModale = ({ text }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [listaEmpresas, setListaEmpresas] = useState([]);
  const [listaEstilo, setListaEstilos] = useState([]);
  const [loading,setLoading] = useState({empresa:false,estilo:false,anio:false});
  const [datos,setDatos]=useState({empresa:0});
  const [searchtext, setSearchtext] = useState('');
  const [suggest, setSuggest] = useState([]);
  const [resfound, setResfound] = useState(true);
  const [annios,setAnnios] =  useState([]);
  const [listaVh,setListaVh] =  useState([]);
  const [estilo,setEstilo] =useState([]);
  const [maxComp,setMaxComp]=useState(4);
  const history = useHistory()
  const [estiloSel,setEstiloSel]=useState({});
  
  const handleChange = (e) => {
    let searchval = e.target.value;
    if (listaEstilo.length > 0) {
      if (searchval.length > 1) {
        const Obtener = async () => {
          const suggestion = listaEstilo.sort().filter((e) => e.descripcion.toLowerCase().includes(searchval.toLowerCase()) || e.codigo.toLowerCase().includes(searchval.toLowerCase()));
          setSuggest(suggestion);
          setResfound(suggestion.length !== 0 ? true : false);
        }
        Obtener();
      }
    }
    setSearchtext(searchval);
  };
  const onKeyDownCod = (e)=>{
    if(e.keyCode===13 || e.keyCode===9){
      if(suggest.length>0){
        suggestedText(suggest[0])
      }      
    }    
  }
  const suggestedText = (value) => {
    setEstiloSel(value);
    setSearchtext(value.descripcion);
    setSuggest([]);  
    setEstilo([]);
    const ObtenerAnnio = async () => {
      setLoading({ ...loading, anio: true });
      const response = await ObtenerAnnosPorEstiloRequest(localStorage.getItem(ConstantesSistema.nombre_Tkn), datos.empresa,value.codigo);
      if (response.exitoso) {
        let vIni = parseInt(response.objeto.result.annoInicial);
        let vFin = parseInt(response.objeto.result.annoFinal) + 1;  
        setEstilo(value);     
        if(vIni>0 && vFin >0){
          let vL = vFin - vIni;
          setAnnios(Array.from({ length: vL }, ((_, i) => vIni + i)));
        }else{
          mostrarError("El vehículo no tiene parametrización de años de vigencia. Por favor comuníquese con Distribuidor Usados.");
        }
        setLoading({ ...loading, anio: false });
      }
      else {
        mostrarError(response.mensajeRespuesta);
      }
    }
    ObtenerAnnio();
  };

  const getSuggestions = () => {
    if (suggest.length === 0 && searchtext !== '' && !resfound) {
      return (
        <Stack
          overflowY="auto"
          maxH="26.3rem"
          p={2}
          w="max-content"
          pos="absolute"
          bg="light"
          zIndex="3"
          boxShadow={{ base: 'md', lg: 'md' }}
          rounded="md"
        >
          <Box display="grid" justifyItems="start">
            <Text>No se encontraron resutados</Text>
          </Box>
        </Stack>
      );
    }
  };
 
  const onEmpresaChange = async (valor) => {
    if (valor != null && valor != '') {
      if (valor != datos.empresa) {
        setLoading({ ...loading, estilo: true });
        setDatos({ ...datos, empresa: parseInt(valor) });
        try {
          const Obtener = async () => {
            const vDatos = await ObtenerEstiloRequest(localStorage.getItem(ConstantesSistema.nombre_Tkn), valor).then(
              function(response){
                if (response.exitoso) {
                  var lista =response.objeto.filter(x=>x.busqueda===2)
                  setListaEstilos(lista);
                }
                else {
                  mostrarError(response.mensajeRespuesta);
                }
                setLoading({ ...loading, estilo: false });
              }).catch(function(err){
                mostrarError(err);
                setLoading({ estilo: false });
              });            
          }
          Obtener();
        } catch (ex) {
          mostrarError(ex);
          setLoading({ estilo: false });
        }
      }
    } else {
      setDatos({ ...datos, empresa: valor });
      setListaEstilos([]);
      setSearchtext('');
    }
  }
  const onAgregar = async()=>{
    const Crear = async () => {
      let vAnnio = localStorage.getItem(ConstantesSistema.annioSel);
      var vLista = listaVh;
      var vMax =  (window.innerWidth > ConstantesSistema.tamanioMovil)?ConstantesSistema.paramMaxCantEscritorio:ConstantesSistema.paramMaxCantMovil;      
      if(vLista.length>0){
        if(vLista.length>=vMax){
          mostrarError(`El máximo de vehículos a comparar es de ${vMax} unidades`);
          return;
        }
      }
      if(datos.empresa===0){
        mostrarError('Por favor seleccione una compañía valida.');
        return;
      }
      if ( estilo.codigo === null || estilo.codigo === undefined || estilo.codigo ==='') {
        mostrarError('Por favor ingrese estilo para la comparación.');
        return;
      }
      if (vAnnio === null || searchtext === '') {
        mostrarError('Por favor ingrese el año del estilo para la comparación.');
        return;
      }
      if (vAnnio != null && searchtext != '' && estilo.codigo != null) {
       
        var vExiste = vLista.filter(x => x.estilo.toUpperCase().includes(estilo.codigo.toUpperCase()) && parseInt(x.anio) === parseInt(vAnnio));
        var vMeses = localStorage.getItem(ConstantesSistema.paramHVentas);       
        if (vExiste.length > 0) {
          mostrarError('El modelo ya es parte de la lista de comparación.');
          return;
        }
       
        var response = await ObtenerDatosRequest(localStorage.getItem(ConstantesSistema.nombre_Tkn), datos.empresa, estilo.codigo, parseInt(vAnnio),parseInt(vMeses));
        if (response) {
          var equipamiento = await ObtenerEquipamientoRequest(localStorage.getItem(ConstantesSistema.nombre_Tkn), datos.empresa, estilo.codigo, parseInt(vAnnio)); 
          
          var vh = {
            "empresa": datos.empresa,
            "estilo": estilo.codigo,
            "descripcion": estilo.descripcion,
            "anio": parseInt(vAnnio),
            "visible": true,
            "index": datos.empresa + '|' + estilo.codigo + '|' + parseInt(vAnnio),
            "datos": response.objeto,
            "equipamiento" : equipamiento.objeto,
            "imagen":estiloSel.imagen_Marca
          }
          vLista.push(vh);
          await setListaVh(vLista);
          setSearchtext('');
          setAnnios([]);
          localStorage.removeItem(ConstantesSistema.annioSel);
        }
        else {
          mostrarError(response.mensajeRespuesta);
        }
      }
    }
    var vCrear = await Crear();
  }
  const onEliminar = async(vh)=>{
    var vLista = listaVh;
    if(vLista.length>0){
      var vExiste = vLista.filter(x => x.index != vh.index);
      await setListaVh(vExiste);
      if(vExiste.length===0){
        localStorage.removeItem(ConstantesSistema.iniciaComparar);
      }
      setSearchtext('');
    }
  }
  const onAceptar = async()=>{
    if(listaVh.length<2){
      mostrarError("Debe agregar al menos dos vehículos para realizar la comparación.");
      return;
    }    
    localStorage.setItem(ConstantesSistema.listaComparar, JSON.stringify(listaVh));
    localStorage.setItem(ConstantesSistema.iniciaComparar, ConstantesSistema.iniciaComparar);          
    const vehiculo = JSON.parse(localStorage.getItem(ConstantesSistema.vehiculoDet));
    if(vehiculo != null){
      const menu = JSON.parse(localStorage.getItem(ConstantesSistema.opcionesMenu));
      if(menu!=null){
         if(menu.filter(x=>x.id===ConstantesSistema.menuComparacion).length>0){  
            history.push(ConstantesSistema.SiteRutas.Comparar);
         }
      }else{
        mostrarError("No tiene permisos para la opción de comparación.");
      }      
    }
    onClose();
  }
  const onCerrar = async()=>{
    setDatos({...datos,empresa:0})
    onClose();
  }
  const onAbrir=async()=>{
    const vehiculo = JSON.parse(localStorage.getItem(ConstantesSistema.vehiculoDet));
    const lista = JSON.parse(localStorage.getItem(ConstantesSistema.listaComparar));
    setSearchtext('');
    if(vehiculo != null && lista === null){
      var response = await ObtenerDatosRequest(localStorage.getItem(ConstantesSistema.nombre_Tkn), vehiculo.emp, vehiculo.estilo, parseInt(vehiculo.anio),0);
      if(response){
        var vLista= [];
        var vh = {
          "empresa": vehiculo.emp,
          "estilo": vehiculo.estilo,
          "descripcion": vehiculo.estilo_Descripcion,
          "anio": parseInt(vehiculo.anio),
          "visible": true,
          "index": vehiculo.emp+'|'+vehiculo.estilo+'|'+parseInt(vehiculo.anio),
          "datos": response.objeto,
          "imagen":vehiculo.imagen_Marca,
          "equipamiento" : vehiculo.equipamiento,
        }
        vLista.push(vh);
        await setListaVh(vLista);
        localStorage.setItem(ConstantesSistema.listaComparar, JSON.stringify(vLista));
      }      
    }
    if(localStorage.getItem(ConstantesSistema.listaComparar)!=null){
      ObtenerDatos();
    }
    onOpen();
  }
  const ObtenerDatos=async()=>{    
    const listaVehiculo = JSON.parse(localStorage.getItem(ConstantesSistema.listaComparar));
    if(listaVehiculo!=null){       
      await setListaVh(listaVehiculo);
    }else{
      await setListaVh([]);
      if(parseInt(localStorage.getItem(ConstantesSistema.menuSel))===ConstantesSistema.menuComparacion){
        onAbrir();
      }      
    }
  }   
  useEffect(() => {         
    const ObtenerParam = async () => {
      var meses = localStorage.getItem(ConstantesSistema.paramHVentas)
      if(meses===null){
        const vParam = await ObtenerParamIdRequest(localStorage.getItem(ConstantesSistema.nombre_Tkn),ConstantesSistema.paramHVentas);
        if (vParam.exitoso) {
          localStorage.setItem(ConstantesSistema.paramHVentas, parseInt(vParam.objeto.valor));
        }
      }
      if (window.innerWidth > ConstantesSistema.tamanioMovil) {
        setMaxComp(ConstantesSistema.paramMaxCantEscritorio);
      } else {
        setMaxComp(ConstantesSistema.paramMaxCantMovil);
      }
    }
    const ObtenerEmpresas = async () => {
      setLoading({...loading,empresa:true});
      setListaEstilos([]);
      // var lista =JSON.parse( localStorage.getItem(ConstantesSistema.empresaLista));
      // if (lista === null) {
        
        const vDatos = await ObtenerEmpresasRequest(localStorage.getItem(ConstantesSistema.nombre_Tkn), ConstantesConsultaEmpresa.Comparacion);
        if (vDatos.exitoso) {        
          setListaEmpresas(vDatos.objeto);   
          ObtenerParam();
          ObtenerDatos();
        }
        else {
          mostrarError(vDatos.mensajeRespuesta);
        }
      // }else{
      //   setListaEmpresas(lista);   
      //   ObtenerParam();
      //   ObtenerDatos();
      // }      
      setLoading({...loading,empresa:false});
    }
    ObtenerEmpresas();    
  }, []);
  return (
    <>
      <Button
        onClick={onAbrir}
        variant="solid"
        color="light"
        bg="primary"
        rounded="md"
        w={40}
        style={{marginTop:'0px', marginLeft:'10px'}}
        _hover={{ bg: 'primaryHover' }}
      >
        {text}
      </Button>

      <Modal isOpen={isOpen} onClose={onCerrar} size="xl">
        <ModalOverlay />
        <ModalContent maxW={{ base: '100%', xl: '60%' }} top={{base:"1rem",lg:"3rem"}}>
          <ModalHeader textAlign="left" fontSize="lg">
            Agregar Vehículos a la Comparación
          </ModalHeader>
          <Divider />
          <ModalCloseButton />
          <ModalBody my={2}>
            <Stack spacing={8}>
              <Stack className="badge badge-danger p-2">
                <Text color="white" fontWeight="bold" display={{ base: 'block', md: 'none' }}>El máximo de vehículos a comparar es de {ConstantesSistema.paramMaxCantMovil} unidades </Text>
                <Text color="white" fontWeight="bold" display={{ base: 'none', lg: 'block' }}>El máximo de vehículos a comparar es de {ConstantesSistema.paramMaxCantEscritorio} unidades </Text>
              </Stack>
              <Stack spacing={4} justifyContent="space-between" direction={{ base: 'column', lg: 'row' }}>
                <FormControl id="user">
                  <FormLabel fontWeight="bold">
                    <Text hidden={loading.empresa}>Compañía</Text>
                    <Stack hidden={!loading.empresa} >
                      <Loading color="#D8222B" texto="Cargando" tam={25} />
                    </Stack>
                  </FormLabel>
                  <Select
                    placeholder="Seleccione una Compañía"
                    variant="outline"
                    bg="light"
                    focusBorderColor="borders"
                    borderColor="borders"
                    _focus={{ bg: 'light' }}
                    boxShadow="sm"
                    w={{ base: '100%', md: '100%' }}
                    onChange={(event) => onEmpresaChange(event.currentTarget.value)}
                    value={datos.empresa}
                  >
                    {listaEmpresas.map((emp, index) =>
                      <option key={index} value={emp.id}>
                        {emp.nombre}
                      </option>)}
                  </Select>
                </FormControl>
                <FormControl>
                  <FormLabel fontWeight="bold">
                    <Text hidden={loading.estilo}>Estilo</Text>
                    <Stack hidden={!loading.estilo} >
                      <Loading color="#D8222B" texto="Cargando" tam={25} />
                    </Stack>
                  </FormLabel>
                  <Box
                    w={{ base: '100%', md: '100%' }}
                    px={{ base: '2', xl: '0' }}
                    borderRadius={'full'}
                    boxShadow={{ base: 'sm', xl: 'none' }}
                    py={{ base: '0', xl: '0' }}
                    bg={{ base: 'none', xl: 'none' }}
                  >
                    <Input
                      borderColor="#D9D7D7"
                      focusBorderColor="none"
                      placeholder={'Seleccione un estilo'}
                      value={searchtext}
                      onChange={handleChange}
                      onKeyDown={onKeyDownCod}
                      fontSize={{ base: 'sm', lg: 'md' }}
                    />
                    {getSuggestions()}
                    <Stack
                      overflowY="auto"
                      maxH="10rem"
                      p={!searchtext ? '1' : '0'}
                      w="max-content"
                      pos="absolute"
                      bg="light"
                      zIndex="3"
                      boxShadow={{ base: 'md', lg: 'md' }}
                      rounded="md"
                      visibility={searchtext ? 'visible' : 'hidden'}
                    >
                      <Box display="grid" justifyItems="start">
                        {suggest.map((item, index) => {
                          return (
                            <Box key={index}>
                              <Button variant="ghost" rounded="full" onClick={() => suggestedText(item)}>
                                {item.codigo} - {item.descripcion}
                              </Button>
                              {index !== suggest.length - 1}
                            </Box>
                          );
                        })}
                      </Box>
                    </Stack>
                  </Box>
                </FormControl>
                <FormControl id="emailpassword">
                  <FormLabel fontWeight="bold">
                    <Text hidden={loading.anio}>Año</Text>
                    <Stack hidden={!loading.anio} >
                      <Loading color="#D8222B" texto="Cargando" tam={25} />
                    </Stack>
                  </FormLabel>
                  <InputGroup>
                    <Annios Item={annios} />
                    <InputRightElement children={<FaCalendarAlt color="body" />} hidden={true} />
                  </InputGroup>
                </FormControl>
                <FormControl>
                  <Button color="light" variant="solid" background="primary" _hover={{ bg: 'primaryHover' }} 
                     w={{ base: '100%', lg:'8rem' }} px={8} mt={8} onClick={onAgregar} hidden={loading.empresa} >
                    Agregar
                  </Button>
                </FormControl>
              </Stack>              
              <Stack>
                <Box display="grid" gridTemplateColumns={{ base: 'repeat(2, 1fr)', md: 'repeat(4, 1fr)' }} gridGap={1}>
                  {listaVh.map((vh, index) =>
                    <Stack
                      bg="light"
                      w="100%"
                      p={2}
                      border="1px solid #F2F1F1"
                      transition="0.2s"
                      _hover={{ bg: 'light', boxShadow: 'lg', transform: 'translateY(-2px)' }}
                      cursor="pointer"
                      minH="100%"
                      justifyContent="center"
                    >
                      <Stack justifyContent="center">
                        <Box>
                          <Button variant="link" pt={1} onClick={() => { onEliminar(vh) }} >
                            <Icon
                              as={FaTrashAlt}
                              h={4}
                              w={4}
                              color="error"
                              transition="0.2s"
                              _hover={{ transform: 'translateY(-2px)' }}
                              _active={{ transform: 'translateY(0)' }}
                            />
                          </Button>
                        </Box>
                        <Stack p={0}>
                          <Box color="#F2F1F1" display="flex" justifyContent="center">
                            <Stack hidden={vh.imagen!=null}>
                              <FaCarAlt fontSize="4rem" />
                            </Stack>
                            <Stack hidden={vh.imagen===null || vh.imagen===''}>
                              <Image src={vh.imagen} alt="Logo" w="4rem" h="3rem" mb={2} />
                            </Stack>
                          </Box>
                          <Box textAlign="center" fontWeight="bold" fontSize="sm">
                            <Text fontWeight="black">{vh.descripcion}</Text>
                            <Text >{vh.estilo}</Text>
                            <Text >{vh.anio}</Text>
                          </Box>
                        </Stack>
                      </Stack>
                    </Stack>
                  )}
                </Box>
              </Stack>
            </Stack>
          </ModalBody>
          <ModalFooter justifyContent="flex-end">
            <Button color="light" variant="solid" background="primary" _hover={{ bg: 'primaryHover' }} px={8}
              onClick={onAceptar}>
              Comparar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default PlacaModale;
