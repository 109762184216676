import React, { Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import LoginPage from './pages/Auth/LoginPage';
import PasswordPage from './pages/Auth/PasswordPage';
import BuscarCodigoPage from './pages/Buscar/BuscarCodigoPage';
import BuscarPlacaPage from './pages/Buscar/BuscarPlacaPage';
import DetallePage from './pages/Detalle/DetallePage';
import SimuladorPage from './pages/Simulador/SimuladorPage';
import CompararPage from './pages/Comparar/CompararPage';
import HomePage from './pages/Home/HomePage';
import ConstantesSistema from './Constants/ConstantesSistema';

const Routes = () => {
  const NotFound = () =>
  <div>
    <div className="container-fluid">
      <div className="row">
        <div className="col text-center mt-5">
          <h1 class="display-4">Error 404, Página no encontrada.</h1>
          <p>La página indicada no existe.</p>
        </div>
      </div>
    </div>
  </div>
  return (
    <Fragment>
      <Router>
        <Switch>
        <Route exact path={ConstantesSistema.ruta}  component={LoginPage} />
          <Route exact path={ConstantesSistema.SiteRutas.Codigo}  component={BuscarCodigoPage} />
          <Route exact path={ConstantesSistema.SiteRutas.Placa}  component={BuscarPlacaPage} />
          <Route exact path={ConstantesSistema.SiteRutas.Detalle}  component={DetallePage} />
          <Route exact path={ConstantesSistema.SiteRutas.Simulador}  component={SimuladorPage} />
          <Route exact path={ConstantesSistema.SiteRutas.Comparar}  component={CompararPage} />
          <Route exact path={ConstantesSistema.SiteRutas.Home}  component={HomePage} />
          <Route exact path={ConstantesSistema.SiteRutas.olvide}  component={PasswordPage} />
          <Route component={NotFound} />
        </Switch>
      </Router>
    </Fragment>
  );
};

export default Routes;
