import React, { useState, Fragment, useEffect } from 'react';
import ConstantesSistema from '../../Constants/ConstantesSistema';
import { mostrarError, mostrarExito, mostrarWarning } from '../Util/Notification/Notification'
import {
    Stack,
    Box,
    Text,
    Button,
    Input,
  } from '@chakra-ui/react';

export const Codigos = ({ Item, onChangeEstilo }) => {
    const [searchtext, setSearchtext] = useState('');
    const [suggest, setSuggest] = useState([]);
    const [resfound, setResfound] = useState(true);
    const [codVehiculo, setCodVehiculo] = useState('');
    const [placeHol,setPlaceHold] = useState('Buscar por marca, estilo, código');

    useEffect(() =>{
        onChangeEstilo(codVehiculo);
    }, [codVehiculo])

    const handleChange = (e) => {
      let searchval = e.target.value;
      let suggestion = [];
      let vTipo = ConstantesSistema.tipoBusCodigo;
      
      if (window.location.href.toString().toUpperCase().indexOf("PLACA") > -1) {
        vTipo = ConstantesSistema.tipoBusPlaca;
        setPlaceHold('Buscar por Placa')
      }
      if (searchval.length >= 3 && vTipo === ConstantesSistema.tipoBusCodigo) {
        let datos = Item;
        const Obtener = async () => {
          if (datos != null) {
            if (datos.length > 0) {
              const suggestion = datos.sort().filter((e) => e.descripcion.toLowerCase().includes(searchval.toLowerCase()));
              setResfound(suggestion.length !== 0 ? true : false);
              setSuggest(suggestion);
            }
          }
        }
        Obtener();
      } else if (vTipo === ConstantesSistema.tipoBusPlaca) {
        localStorage.setItem(ConstantesSistema.vehiculoSel, searchval);
      }
      setSearchtext(searchval);
    };
    const onKeyDownCod = async (e)=>{
      if(e.keyCode===13 || e.keyCode===9){
        if(suggest.length>0){
          var x = await suggestedText(suggest[0]);       
        } else{
          if (window.location.href.toString().toUpperCase().indexOf("PLACA") > -1) {
            localStorage.setItem(ConstantesSistema.iniciaBuscar, ConstantesSistema.iniciaBuscar);
          }        
        }     
      }    
    }
    const suggestedText = async (value) => {
      setSearchtext(value.descripcion);
      setCodVehiculo(value.codigo);
      setSuggest([]);
      if (window.location.href.toString().toUpperCase().indexOf("PLACA") > -1) {
        localStorage.setItem(ConstantesSistema.vehiculoSel, value);
      } else {
        localStorage.setItem(ConstantesSistema.vehiculoSel, JSON.stringify(value));
      }
      return true;
    };
  
    const getSuggestions = () => {
      if (suggest.length === 0 && searchtext !== '' && !resfound) {
        return (
          <Stack
            overflowY="auto"
            maxH="10rem"
            p={2}
            w="max-content"
            pos="absolute"
            bg="light"
            zIndex="3"
            boxShadow={{ base: 'md', lg: 'md' }}
            rounded="md"
          >
            <Box display="grid" justifyItems="start">
              <Text>No se encontraron resutados</Text>
            </Box>
          </Stack>
        );
      }
    };
    
    useEffect(() => {
      if (window.location.href.toString().toUpperCase().indexOf("PLACA") > -1) {
        setPlaceHold('Buscar por Placa')
        localStorage.setItem(ConstantesSistema.tipoBusqueda, ConstantesSistema.tipoBusPlaca);
      }else{
        localStorage.setItem(ConstantesSistema.tipoBusqueda, ConstantesSistema.tipoBusCodigo);
      }
      const obtenerDatosInternos = async () => {
        if (localStorage.getItem(ConstantesSistema.regresar) != null) {
          let vh = null;
          try{
            vh = JSON.parse(localStorage.getItem(ConstantesSistema.vehiculoSel));
          }
          catch(ex){
            vh = null;
          }

          if (vh != null) {
            if (parseInt( localStorage.getItem(ConstantesSistema.tipoBusqueda))=== ConstantesSistema.tipoBusPlaca) {
              setSearchtext(vh);
            } else {
              setSearchtext(vh.descripcion);
            }
          }else{
            if (ConstantesSistema.tipoBusPlaca === parseInt(localStorage.getItem(ConstantesSistema.tipoBusqueda))) {
              let vh2 = JSON.parse(localStorage.getItem(ConstantesSistema.listaVh));
              if (vh2 != null) {
                setSearchtext(vh2[0].placa);
              }
            }
          }
        }
      }
      obtenerDatosInternos();
    }, []);

    function limpiar() {
      if(Item.length===0 && searchtext !='' && localStorage.getItem(ConstantesSistema.regresar) === null){
        if (ConstantesSistema.tipoBusCodigo === parseInt(localStorage.getItem(ConstantesSistema.tipoBusqueda))) {
          setSearchtext('');
          localStorage.removeItem(ConstantesSistema.regresar)
        }     
      }
    }
    limpiar()
    return (
      <Box
        bg="white"
        w={{ base: '20rem', xl: '16rem' }}
        px={{ base: '4', xl: '0' }}
        borderLeftRadius={{ base: 'full', xl: 'none' }}
        boxShadow={{ base: 'sm', xl: 'none' }}
        py={{ base: '2', xl: '0' }}
      >
        <Input
          borderColor="#EEEEEF"
          focusBorderColor="none"
          variant="flushed"
          placeholder={placeHol}
          value={searchtext}
          onChange={handleChange}
          onKeyDown={onKeyDownCod}
        />
        {getSuggestions()}
        <Stack
          overflowY="auto"
          maxH="10rem"
          p={!searchtext ? '1' : '0'}
          w="max-content"
          pos="absolute"
          bg="light"
          zIndex="3"
          boxShadow={{ base: 'md', lg: 'md' }}
          rounded="md"
          visibility={searchtext ? 'visible' : 'hidden'}
        >
          <Box display="grid" justifyItems="start">
            {suggest.map((item, index) => {
              return (
                <Box key={index}>
                  <Button variant="ghost" rounded="full" onClick={() => suggestedText(item)}>
                    {item.descripcion}
                  </Button>
                  {index !== suggest.length - 1}
                </Box>
              );
            })}
          </Box>
        </Stack>
      </Box>
    );
  };