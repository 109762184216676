import React, { Fragment,Component } from 'react';
import Routes from './routes';
import ScrollToTop from './components/Hooks/ScrollToTop';
import Footer from './components/Footer/Footer';
const App = () => {
  return (
    <Fragment >
      <ScrollToTop />
      <Routes />
      <Footer />
    </Fragment>
  );
};

export default App;
