import React from 'react';
import ReactLoading from 'react-loading';

const Loading = ({ texto,color,tam }) => (
    <div>
        <table>
            <tr>
                <td>
                <ReactLoading type="spin" color={color} height={tam} width={tam}  />
                </td>
                <td style={{color: `${color}`}}>
                    {texto}
                </td>
            </tr>
        </table>
    </div>
);

export default Loading;