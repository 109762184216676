import React, { Fragment } from 'react';
import Seo from '../../components/Layout/Seo';
import Navbar from '../../components/Layout/Navbar';
import BuscarCodigo from '../../components/Buscar/BuscarCodigo';
const BuscarCodigoPage = () => {
  return (
    <Fragment>
      <Seo title="Buscar Codigo" />
      <Navbar />
      <BuscarCodigo />
    </Fragment>
  );
};

export default BuscarCodigoPage;
