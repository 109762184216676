import {
    Stack,
    Box,
    Text,
  } from '@chakra-ui/react';
import { FaCarAlt } from 'react-icons/fa';

export const Auto = ({ placa, codigo, anio }) => {
    return (
      <Stack direction="row" px={2} py={2} alignContent="center" justifyContent="flex-start">
        <Box color="error">
          <FaCarAlt fontSize="1.5rem" />
        </Box>
        <Text fontWeight="bold" color="secondary">
          {(placa === null) ? '' : placa}
        </Text>
        <Text fontWeight="bold" color="bodyTitle">
          {codigo} {anio} 
        </Text>
      </Stack>
    );
  };