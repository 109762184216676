import ReactExport from "react-data-export";
import {
    Button,
    Icon,
  } from '@chakra-ui/react';
import { FaDownload} from 'react-icons/fa';

export const Excel = ({ btn, nombre, columnas, data }) => {
    const ExcelFile = ReactExport.ExcelFile;
    const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
    const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
    return (
      <ExcelFile
        filename ={nombre}
        element={<Button
        variant="filled"
        bg="primary"
        w="max-content"
        p={4}
        _hover={{ transform: 'translateY(-2px)' }}
        _active={{ transform: 'translateY(0)' }}
      >
        <Icon as={FaDownload} fontSize="1.2rem" transition="0.3s" color="light" />
      </Button>}>
        <ExcelSheet data={data} name={nombre} >
          {
            columnas.map((columna, index) => (
              <ExcelColumn key={index} label={columna.text} value={columna.dataField} />
            ))
          }
        </ExcelSheet>
      </ExcelFile >
    )
  }