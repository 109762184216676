import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { mostrarError, mostrarExito, mostrarWarning } from '../Util/Notification/Notification'
import {
  Container,
  Stack,
  Box,
  Text,
  Heading,
  Button,
  Image,
  List,
  ListItem,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Select,
  Alert,
  AlertIcon,
  FormControl,
  FormLabel,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
} from '@chakra-ui/react';
import { BiChevronLeft } from 'react-icons/bi';
import { InfoIcon } from '@chakra-ui/icons';
import EditarModal from '../Comparar/EditarModal';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import ConstantesSistema from '../../Constants/ConstantesSistema';
import ConstantesMenu from '../../Constants/ConstantesMenu';
import { ObtenerHistoricoVentasRequest,ObtenerPromedioVentasRequest,ObtenerListaMeses,ObtenerValorFiscalyContratoRequest } from '../../request/VehiculosRequest';
import {ObtenerParamIdRequest} from '../../request/ParamRequest';

import NumberFormat from "react-number-format";
import Loading from '../../components/Util/Loading/Loading';
import { Equipamiento } from './Equipamiento';
import { Ventas } from './Ventas'
import { PrecioRecibo } from './PrecioRecibo'
import { SiteRutas } from '../../request/PathConfig';

export const DetallesPlaca = () => {
  const [promedio, setPromedio] = useState([]);
  const [mes, setMes] = useState([]);
  const [meses, setMeses] = useState([]);
  const [leyendaPrecioRecibo, setLeyendaPrecioRecibo] = useState('');
  const [loading, setLoading] = useState(false);
  const [vh, setVh] = useState(JSON.parse(localStorage.getItem(ConstantesSistema.vehiculoDet)));
  const [tipo, setTipo] = useState(parseInt(localStorage.getItem(ConstantesSistema.tipoBusqueda)));
  const [msjRes, setMsjRes] = useState('');
  const [subMenu,setSubMenu]= useState([]);
  const [valores,setValores]=useState({fiscal:0,contrato:0});
  const [listaPermiso, setListaPermiso] = useState({ opRestringido: false,
                                                    opGeneral: false, 
                                                    opInventario: false, 
                                                    opRecibo: false, 
                                                    opSugerido: false, 
                                                    opFisyCon: false, 
                                                    opPromedio: false, 
                                                    opEquipamiento: false,
                                                    opPVenta: false,
                                                    opPRecibo: false,
                                                    opSalesf:false });
  
  useEffect(() => {
    const ObtenerParamVentas = async()=>{

      let vParam = await ObtenerParamIdRequest(vh.tk,ConstantesSistema.paramLeyendaBusquedaVehiculoPR);
      if (vParam.exitoso) {
        setLeyendaPrecioRecibo(vParam.objeto.valor);
      }

      vParam = await ObtenerParamIdRequest(vh.tk,ConstantesSistema.paramHVentas);
      
      if (vParam.exitoso) {
        setMes(parseInt(vParam.objeto.valor));
        ObtenerPromedio(parseInt(vParam.objeto.valor));
        await ObtenerSubMenu();        
      }
      if (vh.tiene_Restriccion) {
        const vMsj = await ObtenerParamIdRequest(vh.tk, ConstantesSistema.paramMsjRes);
        if (vMsj.exitoso) {
          setMsjRes(vMsj.objeto.valor);          
        }
      }

    }
    ObtenerParamVentas();
    const ObtenerPromedio = async (pMes) => {
      const vDatos = await ObtenerPromedioVentasRequest(vh.tk,vh.emp,vh.estilo,vh.anio,(pMes===null)?mes:pMes);
      if (vDatos.exitoso) {
        setPromedio(vDatos.objeto);
        //setprome(promedio.venta_Min === 0);
      }
      else {
        mostrarError('Promedio Ventas: ' + vDatos.mensajeRespuesta);
      }
      const vMeses = await ObtenerListaMeses(vh.tk);
      if (vMeses.exitoso) {
        setMeses(vMeses.objeto);
      }
      else {
        mostrarError('Lista Meses: ' + vDatos.mensajeRespuesta);
      }
    }
    CargaValores();

  }, []);

  const CargaValores = async()=>{
    const response = await ObtenerValorFiscalyContratoRequest(localStorage.getItem(ConstantesSistema.nombre_Tkn),vh.placa);
    if (response.exitoso) {
      setValores({fiscal:response.objeto.valorFiscal.toFixed(2),contrato:response.objeto.valorContrato.toFixed(2)});
    }
    else {
      mostrarError(response.mensajeRespuesta);
    }
  }
  const ObtenerSubMenu = async () => { 
    var vSubMenu = JSON.parse(localStorage.getItem(ConstantesSistema.opcionesSubMenu));  
    var vTipo = parseInt(localStorage.getItem(ConstantesSistema.tipoBusqueda));
    if (vSubMenu != null) {
      vSubMenu = vSubMenu.filter(x => x.menu_Id === vTipo);     
      if(vTipo===ConstantesSistema.tipoBusCodigo){
        setListaPermiso({ 
          opRestringido:vSubMenu.filter(x => parseInt(x.opcion_Id) === 1).length===0,
          opGeneral:(vSubMenu.filter(x => parseInt(x.opcion_Id) === 3).length===0 || vh.transmision === ""),
          opInventario:(vSubMenu.filter(x => parseInt(x.opcion_Id) === 3).length===0 || vh.cantidad_Inventario === 0),
          opRecibo:vSubMenu.filter(x => parseInt(x.opcion_Id) === 4).length===0,
          opSugerido:vSubMenu.filter(x => parseInt(x.opcion_Id) === 5).length===0,
          opFisyCon:vSubMenu.filter(x => parseInt(x.opcion_Id) === 6).length===0,
          opPromedio:(vSubMenu.filter(x => parseInt(x.opcion_Id) === 7).length===0 || vh.transmision === ""),
          opEquipamiento:vSubMenu.filter(x => parseInt(x.opcion_Id) === 8).length===0,
          opPVenta:vSubMenu.filter(x => parseInt(x.opcion_Id) === 9).length===0,
          });
      }else{
        setListaPermiso({ 
          opPRecibo:vSubMenu.filter(x => parseInt(x.opcion_Id) === 1).length===0,
          opRestringido:vSubMenu.filter(x => parseInt(x.opcion_Id) === 2).length===0,
          opGeneral:(vSubMenu.filter(x => parseInt(x.opcion_Id) === 3).length===0 || vh.transmision === ""),          
          opInventario:(vSubMenu.filter(x => parseInt(x.opcion_Id) === 3).length===0 || vh.cantidad_Inventario === 0),
          opRecibo:vSubMenu.filter(x => parseInt(x.opcion_Id) === 4).length===0,       
          opSugerido:vSubMenu.filter(x => parseInt(x.opcion_Id) === 5).length===0,
          opEquipamiento:vSubMenu.filter(x => parseInt(x.opcion_Id) === 6).length===0,
          opPVenta:vSubMenu.filter(x => parseInt(x.opcion_Id) === 7).length===0,
          opPromedio:(vSubMenu.filter(x => parseInt(x.opcion_Id) === 7).length===0 || vh.transmision === ""),
          opSalesf:vSubMenu.filter(x => parseInt(x.opcion_Id) === 8).length===0,
          opFisyCon:vSubMenu.filter(x => parseInt(x.opcion_Id) === 9).length===0
          });
      }
    }
  }
  const onMesChange =async (valor) =>{  
    setLoading(true);
    setMes(valor);
    const vh =  JSON.parse(localStorage.getItem(ConstantesSistema.vehiculoDet));  
    const vDatos = await ObtenerPromedioVentasRequest(vh.tk,vh.emp,vh.estilo,vh.anio,parseInt(valor));
    if (vDatos.exitoso) {
      setPromedio(vDatos.objeto);
    }
    else {
      mostrarError(vDatos.mensajeRespuesta);
    }
    setLoading(false);
  }  
  const validarPermiso=async(pPermiso)=>{
    if(ConstantesMenu.validar){      
      var x =subMenu.filter(x=>parseInt(x.opcion_Id)===parseInt(pPermiso));
      return true;
    }else{
      return false;
    }
  }

  return (
    <Box w="100%" minH="100vh">
      <Stack bg="secondary" boxShadow="sm" direction="row" justifyContent="center" pr={4} hidden={!vh.tiene_Restriccion}>
        <Alert status="warning" bg="none" color="light">
          <AlertIcon color="light" alignSelf="flex-start" />
          {msjRes}
        </Alert>
        <Stack p={6} hidden={true}>
          <Popover>
            <PopoverTrigger>
              <Button variant="link">
                <InfoIcon
                  w={6}
                  h={6}
                  color="light"
                  transition="0.3s"
                  _hover={{ transform: 'translateY(-2px)' }}
                  _active={{ transform: 'translateY(0)' }}
                />
              </Button>
            </PopoverTrigger>
            <PopoverContent w="max-content">
              <PopoverArrow />
              <PopoverCloseButton />
              <PopoverHeader>Detalle</PopoverHeader>
              <PopoverBody>Restricción 1</PopoverBody>
              <PopoverBody>Restricción 2</PopoverBody>
              <PopoverBody>Restricción 3</PopoverBody>
            </PopoverContent>
          </Popover>
        </Stack>
      </Stack>
      <Container maxW={{ base: '95%', md: '75%' }} py={8}>
        <Image src={vh.imagen_Marca} alt="Logo" w="11rem" mb={8} hidden={vh.imagen_Marca===null} />
        <List fontSize="lg" spacing={4} mb={6}>
          <ListItem hidden={listaPermiso.opGeneral}>Transmisión: {vh.transmision}</ListItem>
          <ListItem hidden={listaPermiso.opGeneral}>Combustible: {vh.combustible}</ListItem>
          <ListItem hidden={listaPermiso.opGeneral}>Cilindraje: {vh.cilindraje}</ListItem>
          <ListItem hidden={listaPermiso.opGeneral}>Tracción: {vh.traccion}</ListItem>
          <ListItem hidden={(tipo===ConstantesSistema.tipoBusPlaca || listaPermiso.opInventario)} onClick={ObtenerSubMenu}>Cantidad en Inventario: {vh.cantidad_Inventario}</ListItem>
        </List>
        <Stack justifyContent="flex-start" direction="column" py={2} hidden={listaPermiso.opRecibo}>
          <Text style={{textAlign:'right'}} fontSize="sm" color="error" fontWeight="bold">Última Actualización: {vh.fechaCalculo}</Text>
          <Text style={{textAlign:'right'}} fontSize="sm" color="error" fontWeight="bold">{leyendaPrecioRecibo}</Text>
        </Stack>        
        <Stack spacing={8}>
          <Stack spacing={4}>
          <Stack
              bg="light"
              justifyContent="space-between"
              fontWeight="bold"
              p={4}
              rounded="md"
              boxShadow="sm"
              hidden={(tipo!=ConstantesSistema.tipoBusPlaca)}
              direction={{ base: 'column', sm: 'row' }}
            >
              <Text textTransform="uppercase">Precio de recibo</Text>
              <Text fontSize="lg" color="error">
                <NumberFormat
                  value={vh.precio_Recibo}
                  displayType={'text'} thousandSeparator={true} prefix={'$'}
                />
              </Text>
            </Stack>
            <Stack
              bg="light"
              justifyContent="space-between"
              fontWeight="bold"
              p={4}
              rounded="md"
              boxShadow="sm"
              hidden={(tipo!=ConstantesSistema.tipoBusPlaca)}
              direction={{ base: 'column', sm: 'row' }}
            >
              <Text textTransform="uppercase">Año Anterior</Text>
              <Text fontSize="lg" color="error">
                <NumberFormat
                  value={vh.precio_Recibo_Anterior}
                  displayType={'text'} thousandSeparator={true} prefix={'$'}
                />
              </Text>
            </Stack>
            <Stack
              bg="light"
              justifyContent="space-between"
              fontWeight="bold"
              p={4}
              rounded="md"
              boxShadow="sm"
              hidden={(tipo!=ConstantesSistema.tipoBusPlaca)}
              direction={{ base: 'column', sm: 'row' }}
            >
              <Text textTransform="uppercase">Precio sugerido de venta</Text>
              <Text fontSize="lg" color="error">
                <NumberFormat
                  value={vh.precio_Sugerido_Venta}
                  displayType={'text'} thousandSeparator={true} prefix={'$'}
                />
              </Text>
            </Stack>
            
            <Stack
              bg="light"
              justifyContent="space-between"
              fontWeight="bold"
              p={4}
              rounded="md"
              boxShadow="sm"
              hidden={(tipo===ConstantesSistema.tipoBusPlaca || listaPermiso.opRecibo)}
              direction={{ base: 'column', sm: 'row' }}
            >
              <Text textTransform="uppercase">Precio de recibo</Text>
              <Text fontSize="lg" color="error">
                <NumberFormat
                  value={vh.precio_Recibo}
                  displayType={'text'} thousandSeparator={true} prefix={'$'}
                />
              </Text>
            </Stack>
            <Stack
              bg="light"
              justifyContent="space-between"
              fontWeight="bold"
              p={4}
              rounded="md"
              hidden={(tipo===ConstantesSistema.tipoBusPlaca || listaPermiso.opSugerido)}
              boxShadow="sm"
              direction={{ base: 'column', sm: 'row' }}
            >
              <Text textTransform="uppercase">precio sugerido de venta</Text>
              <Text fontSize="lg" color="error">
                <NumberFormat
                  value={vh.precio_Sugerido_Venta}
                  displayType={'text'} thousandSeparator={true} prefix={'$'}
                />
              </Text>
            </Stack>
            <Stack
              bg="light"
              justifyContent="space-between"
              fontWeight="bold"
              p={4}
              rounded="md"
              boxShadow="sm"
              hidden={(tipo===ConstantesSistema.tipoBusCodigo || listaPermiso.opFisyCon)}
              direction={{ base: 'column', sm: 'row' }}
            >
              <Text textTransform="uppercase">valor fiscal</Text>
              <Text fontSize="lg" color="error">
                <NumberFormat
                  value={valores.fiscal}  
                  displayType={'text'} thousandSeparator={true} prefix={'$'}
                />
              </Text>
            </Stack>
            <Stack
              bg="light"
              justifyContent="space-between"
              fontWeight="bold"
              p={4}
              rounded="md"
              boxShadow="sm"
              hidden={(tipo===ConstantesSistema.tipoBusCodigo || listaPermiso.opFisyCon)}
              direction={{ base: 'column', sm: 'row' }}
            >
              <Text textTransform="uppercase">valor de contrato</Text>
              <Text fontSize="lg" color="error">
                <NumberFormat
                  value={valores.contrato}
                  displayType={'text'} thousandSeparator={true} prefix={'$'}
                />
              </Text>
            </Stack>
          </Stack>
        </Stack>
        <List fontSize="lg" spacing={4} mt={6} fontWeight="normal"
          hidden={listaPermiso.opPromedio}>
          {/* hidden={(tipo === ConstantesSistema.tipoBusPlaca || listaPermiso.opPromedio)}> */}
          <ListItem display="flex">Precio de venta mínimo:
            <Stack fontWeight="bold" ml={2}>
              <NumberFormat
                value={promedio.venta_Min}
                displayType={'text'} thousandSeparator={true} prefix={'$'}
              />
            </Stack>
          </ListItem>
          <ListItem display="flex">Precio de venta máximo:
            <Stack fontWeight="bold" ml={2}>
              <NumberFormat
                value={promedio.venta_Max}
                displayType={'text'} thousandSeparator={true} prefix={'$'}
              />
            </Stack>
          </ListItem>
          <ListItem display="flex">Promedio de ventas:
            <Stack fontWeight="bold" ml={2}>
              <NumberFormat
                value={promedio.venta_Promedio}
                displayType={'text'} thousandSeparator={true} prefix={'$'}
              />
            </Stack>
          </ListItem>
        </List>
        <FormControl pb={20} spacing={30} 
        hidden={listaPermiso.opPromedio}>
        {/* hidden={(tipo===ConstantesSistema.tipoBusPlaca || listaPermiso.opPromedio)}> */}
        <FormLabel fontWeight="bold" color="#4D4D4D" pt={5}>
            Histórico De Precio De Venta:   
            <Text fontSize="sm" justifyContent="right" color="error" fontWeight="bold">{promedio.comentario}</Text>
          </FormLabel>
          <Select            
            variant="filled"
            bg="light"
            fontWeight="bold"
            focusBorderColor="none"
            boxShadow="sm"
            hidden={loading}
            _focus={{ bg: 'light' }}
            _hover={{ bg: 'light', boxShadow: 'md' }}
            value = {mes}
            onChange={(event) => onMesChange(event.currentTarget.value)}
          >
            {meses.map((mes, index) =>
              <option key={index} value={mes.pB_MesesId}>
                {mes.pB_MesesDescripcion}
              </option>)}
          </Select>
          <Stack hidden={!loading} >
            <Loading color="#D8222B" texto="Cargando" tam={30} />
          </Stack>
        </FormControl>        
      </Container>
    </Box>
  );
};










const Detalle = () => {
  const vh = JSON.parse(localStorage.getItem(ConstantesSistema.vehiculoDet));
  const vEmp = parseInt(localStorage.getItem(ConstantesSistema.empresaSel));
  const vTk = localStorage.getItem(ConstantesSistema.nombre_Tkn);
  const [vehiculo, setVehiculo] = useState([]);
  const [ventas, setVentas] = useState([]);
  const [datos, setDatos] = useState({ emp: 0, cod: '', annio: 0, tk: '', ventas: [] });
  const history = useHistory();
  const [tipo, setTipo] = useState(parseInt(localStorage.getItem(ConstantesSistema.tipoBusqueda)));
  const [indMovil, setIndMovil] = useState((window.innerWidth < 800) ? 1 : 0);
  const [tabIndex, setTabIndex] = useState(0)
  const [listaPermiso, setListaPermiso] = useState({ opPVenta: false, opPRecibo: false,opEquipamiento: false});
  const [verComparar,setVerComparar] =   useState(false)
  const tabs = {
    detalle: 0,
    precio: 1,
    ventas: 2
  };
  useEffect(() => {
    const ObtenerVehiculo = async () => {
      screenTam(); 
      if (vh != null) {
        setVehiculo(vh);
        ObtenerSubMenu();
      }
    }
    const screenTam = async()=>{        
      setTabIndex(0);
      
      if(window.innerWidth>800){
        if (parseInt(tipo) === ConstantesSistema.tipoBusCodigo) {
          setTabIndex(2);
        }else{
          setTabIndex(1);
        }          
      }
    }
    const ObtenerVentas = async () => {
      const response = await ObtenerHistoricoVentasRequest(vTk, vEmp, vh.estilo, vh.anio);
      if (response.exitoso) {
        setVentas(response.objeto);
      }
    }    
    ObtenerVehiculo();
    setDatos({emp:vEmp,annio:vh.anio,cod:vh.estilo,tk:vTk,ventas:ventas});

    setListaPermiso({...listaPermiso,
       opPVenta: vh.transmision === "",
    })

  }, []);
  const onRegresar = () =>{   
    localStorage.setItem(ConstantesSistema.regresar, ConstantesSistema.regresar);
    if (parseInt(localStorage.getItem(ConstantesSistema.tipoBusqueda)) === ConstantesSistema.tipoBusCodigo) {
      history.push(ConstantesSistema.SiteRutas.Codigo);
    }else{
      history.push(ConstantesSistema.SiteRutas.Placa);
    }   
  }
  const ObtenerSubMenu = async () => { 
    var vSubMenu = JSON.parse(localStorage.getItem(ConstantesSistema.opcionesSubMenu));  
    var vTipo = parseInt(localStorage.getItem(ConstantesSistema.tipoBusqueda));
    var vMenu = JSON.parse(localStorage.getItem(ConstantesSistema.opcionesMenu)); 
    var verMenu = vMenu.filter(x=>x.id===ConstantesSistema.menuComparacion);
    if(verMenu.length>0){
      setVerComparar(true)
    }else{
      setVerComparar(false)
    }
    vSubMenu = vSubMenu.filter(x => x.menu_Id === vTipo);
    if (vSubMenu != null && vSubMenu.length>0) {    
      if (vTipo === ConstantesSistema.tipoBusCodigo) {
        setListaPermiso({
          opPRecibo: vSubMenu.filter(x => x.nombre.toUpperCase().trim() === ConstantesSistema.desMenu.precioRecibo).length === 0,
          opPVenta: vSubMenu.filter(x => x.nombre.toUpperCase().trim() === ConstantesSistema.desMenu.promediosVenta).length === 0,
          opEquipamiento: vSubMenu.filter(x => x.nombre.toUpperCase().trim() === ConstantesSistema.desMenu.equipamiento).length === 0,
        });
      } else {
        setListaPermiso({
          opPRecibo: vSubMenu.filter(x => x.nombre.toUpperCase().trim() === ConstantesSistema.desMenu.precioRecibo).length === 0,
          opPVenta: vSubMenu.filter(x => x.nombre.toUpperCase().trim() === ConstantesSistema.desMenu.promediosVenta).length === 0,
          opEquipamiento: vSubMenu.filter(x => x.nombre.toUpperCase().trim() === ConstantesSistema.desMenu.equipamiento).length === 0,
        });
      }
    }
    else{
      setListaPermiso({
        opPRecibo: true,
        opPVenta: true,
        opEquipamiento: true
      });
    }
  }


  const onClickCalcular = () => {

    localStorage.removeItem(ConstantesSistema.listaVh);
    localStorage.removeItem(ConstantesSistema.regresar);
    localStorage.removeItem(ConstantesSistema.vehiculoDet);
    //localStorage.removeItem(ConstantesSistema.vehiculoSel);
    //localStorage.removeItem(ConstantesSistema.tipoBusqueda);
    localStorage.removeItem(ConstantesSistema.listaComparar);
    localStorage.removeItem(ConstantesSistema.paramMeses);
    localStorage.removeItem(ConstantesSistema.iniciaComparar);
    //localStorage.removeItem(ConstantesSistema.annioSel);
    localStorage.setItem(ConstantesSistema.menuSel, ConstantesSistema.menuSimulador); 
    localStorage.setItem(ConstantesSistema.enviadoComparacion, true); 
    history.push(ConstantesSistema.SiteRutas.Simulador);

  }

  return (
    <Stack spacing={0}>
      <Container maxW={{ base: '100%', md: '100%' }} pb={10} py={10} bg={{ base: 'none', md: 'light' }}>
        <Stack direction={{ base: 'column', md: 'row' }} justifyContent="space-between" px={{ base: '0', md: '5%' }}>
          <Heading fontSize={{ base: 'sm', lg: 'xl' }} display={{ base: 'block', md: 'none' }} mb={4}>
            {(tipo=== ConstantesSistema.tipoBusCodigo)?'Buscar Código':'Buscar Placa'}            
          </Heading>
          <Stack direction="row" alignItems="center">
            <Button variant="link" mr={2} p={0} alignSelf="flex-start" color="body" _hover={{ color: 'primaryHover' }}
              onClick={onRegresar}>
              <BiChevronLeft fontSize="2rem" />
            </Button>
            <Stack
              direction={{ base: 'column-reverse', md: 'column' }}
              fontWeight="bold"
              fontSize={{ base: 'md', md: 'xl' }}
              spacing={0}
            >
              <Text>{vehiculo.estilo_Descripcion} {vehiculo.anio}</Text>
              <Text color={{ base: 'secondary', md: 'bodyAlt' }}>{(vehiculo.placa===null)?'':vehiculo.placa}</Text>
            </Stack>
          </Stack>
          <Stack display={{ base: 'none', md: 'block' }} hidden={!verComparar} >
            <Button    style={{marginTop:'0px'}}   onClick={onClickCalcular} variant="solid" color="light" bg="primary" rounded="md"w={40} _hover={{ bg: 'primaryHover' }}>
              Calcular
            </Button>
            <EditarModal text="Comparar"  style={{marginTop:'0px'}} />
          </Stack>
        </Stack>
      </Container>
      <Stack direction={{ base: 'column', md: 'row' }} spacing={0}>
        <Box flex="1" bg="#E4E4E3" minH="100%" display={{ base: 'none', lg: 'block' }}>
          <Box h={10} bg={{ base: 'none', md: 'light' }}></Box>
          <DetallesPlaca vh={vehiculo} />
        </Box>
        <Box flex="2" bg="#F2F1F1" minH="100%">
          <Tabs colorScheme="red" size="md" borderColor="tertiary" defaultIndex={(indMovil===1)?0:(tipo === ConstantesSistema.tipoBusCodigo)?2:1} px={{ base: '2', md: '0' }}>
            <TabList
              bg={{ base: 'none', md: 'light' }}
              display={{ base: 'grid', md: 'flex' }}
              gridTemplateColumns="repeat(auto-fit, minmax(5rem, 1fr))"
            >
              <Tab fontWeight="bold" display={{ base: 'block', lg: 'none' }}>
                Detalle
              </Tab>
              <Tab fontWeight="bold" hidden={(tipo===ConstantesSistema.tipoBusCodigo || listaPermiso.opPRecibo)}>Precio de Recibo</Tab>
              <Tab fontWeight="bold" hidden={listaPermiso.opPVenta}>Ventas</Tab>
              <Tab fontWeight="bold" hidden={listaPermiso.opEquipamiento}>Equipamiento</Tab>
            </TabList>
            <TabPanels>
              <TabPanel p={0}>
                <Box display={{ base: 'block', lg: 'none' }}>
                  <DetallesPlaca vh={vehiculo}/>
                </Box>
              </TabPanel>
              <TabPanel hidden={(tipo===ConstantesSistema.tipoBusCodigo || listaPermiso.opPRecibo)}>
                <PrecioRecibo />
              </TabPanel>           
              <TabPanel>
                <Stack hidden={listaPermiso.opPVenta}>
                  <Ventas />
                </Stack>
              </TabPanel>
              <TabPanel>
                <Stack hidden={listaPermiso.opEquipamiento}>
                  <Equipamiento/>
                </Stack>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Box>
      </Stack>
    </Stack>
  );
};

export default Detalle;
