import React, { useState,useEffect, useReducer } from 'react';
import { useHistory } from "react-router-dom";
import { LinkStyled } from '../../styles';
import { mostrarError, mostrarExito, mostrarWarning }from '../Util/Notification/Notification';
import {
  Button,
  InputRightElement,
  Flex,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  Stack,
  Center,
  Image,
  usePrefersReducedMotion,
} from '@chakra-ui/react';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import logo from '../../assets/images/logo.png';
import loginbg from '../../assets/images/PB.jpg';
import loginbgoverlay from '../../assets/images/PB.jpg';
import { moveInUp } from '../../styles';
import { LoginRequest } from '../../request/LoginRequest';
import Loading from '../../components/Util/Loading/Loading';
import ConstantesSistema from '../../Constants/ConstantesSistema';
import { ObtenerMenuRequest } from '../../request/MenuRequest';
import { ObtenerParamIdRequest } from '../../request/ParamRequest';
import { Link, withRouter } from 'react-router-dom';
import { connect, useStore } from 'react-redux';
import { GiConsoleController } from 'react-icons/gi';

const Login = (props) => {
  const [datos,setDatos] = useState({username:'',password:'',loadingText:''});
  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);
  const [loading,setLoading] = useState(false);
  const history = useHistory();
  const dispatch = useStore();
  const onAceptar = async ()=> {
    try {
      if (validateInputData()) {
        setLoading(true);
        var response = await LoginRequest(datos.username, datos.password);
        response = { ...response, respuesta: response.respuesta };
        if (response.respuesta && response.respuesta.exitoso) {
          localStorage.setItem(ConstantesSistema.username, datos.username); 
          localStorage.setItem(ConstantesSistema.imagen, response.respuesta.objeto.usuario_Imagen);    
          localStorage.setItem(ConstantesSistema.nombre_Tkn, response.respuesta.objeto.token);   
          localStorage.removeItem(ConstantesSistema.vehiculoSel);
          localStorage.removeItem(ConstantesSistema.annioSel);
          localStorage.removeItem(ConstantesSistema.empresaSel);    
          localStorage.removeItem(ConstantesSistema.listaVh);       
          localStorage.removeItem(ConstantesSistema.opcionesSubMenu);
          localStorage.removeItem(ConstantesSistema.empresaLista);
          localStorage.removeItem(ConstantesSistema.paramAnios);
          
          const menu = await ObtenerMenuRequest(response.respuesta.objeto.token); 
          //console.log(JSON.stringify(menu.objeto));

          localStorage.setItem(ConstantesSistema.opcionesMenu, JSON.stringify(menu.objeto)); 
          //console.log(localStorage.getItem(ConstantesSistema.opcionesMenu));

          const respMensajes = await ObtenerParamIdRequest(localStorage.getItem(ConstantesSistema.nombre_Tkn), ConstantesSistema.paramTiempoMensajes);
          
          if (respMensajes.exitoso) {
            localStorage.setItem(ConstantesSistema.paramTiempoMensajes, respMensajes.objeto.valor * 1000);           
          }

          //console.log(ConstantesSistema.SiteRutas.Home);
          history.push(ConstantesSistema.SiteRutas.Home);
        }
        else {

          if (response.respuesta && response.respuesta.mensajeRespuesta) {
            mostrarError(response.respuesta.mensajeRespuesta);
          }
          else {
            mostrarError('Ocurrió un error validando los credenciales de acceso.' + response.mensajeRespuesta);
          }
        }
      }
    } catch (ex) {
      mostrarError(ex.message);
    }

    setLoading(false);
  };
  const validateInputData = () => {
    if (!datos.username || !datos.username.trim()) {
      mostrarError("Por favor ingrese un usuario.");
      return false;
    }

    if (!datos.password || !datos.password.trim()) {
      mostrarError("Por favor ingrese la contraseña.");
      return false;
    }

    return true;
  }
  
  const prefersReducedMotion = usePrefersReducedMotion();
  const animation = prefersReducedMotion ? undefined : `${moveInUp} 0.6s`;

  return (
    <Stack
      minH={'100vh'}
      direction={{ base: 'column', md: 'row' }}
      background={{ base: '#060606', md: 'primary' }}
      backgroundImage={'none'}
      backgroundSize="cover"
      backgroundPosition="center center"
      backgroundRepeat="no-repeat"
    >
      <Flex p={8} flex={1} align={'center'} justify={'center'} color="light">
        <Stack spacing={6} w={'full'} maxW={'md'} animation={animation}>
          <Center mb={{ base: '0rem', md: '1rem' }}>
            <Image src={logo} alt="Logo" w="11rem" display={{ base: 'none', md: 'block' }}/>
            <Image src={loginbg} alt="Logo" w="90%" display={{ base: 'block', md: 'none' }}/>
          </Center>
          <FormControl id="email">
            <FormLabel >Usuario</FormLabel>           
            <Stack color="body" bg="light" >
              Usuario
            </Stack>
            <Input
              type="email"
              color="body"
              name = "username"
              variant="filled"
              value={datos.usuario}
              _focus={{ bg: 'light' }}
              placeholder="Escriba su nombre de usuario"
              onChange={(event) => setDatos({ ...datos, username: event.currentTarget.value })}
            />
          </FormControl>
          <FormControl id="password">
            <FormLabel>Contraseña</FormLabel>
            <InputGroup>
              <Input
                type={show ? 'text' : 'password'}
                color="body"
                name ="password"
                variant="filled"
                _focus={{ bg: 'light' }}
                placeholder="Escriba su contraseña"
                onChange={(event) => setDatos({ ...datos, password: event.currentTarget.value })}
              />
              <InputRightElement width="4.5rem">
                <Button h="1.75rem" size="sm" onClick={handleClick} variant="link" color="body">
                  {show ? (
                    <ViewIcon w={6} h={6} color="primaryHover" />
                  ) : (
                    <ViewOffIcon w={6} h={6} color="primaryHover" />
                  )}
                </Button>
              </InputRightElement>
            </InputGroup>
          </FormControl>
          <Stack spacing={6}>
            <Stack direction={'row'} align={'start'} justify={'flex-end'}>
              <Stack >
                <LinkStyled to={ConstantesSistema.SiteRutas.olvide} _hover={{ textDecor: 'none' }} >
                  Olvidé mi contraseña
                </LinkStyled>
              </Stack>
              <Stack display={{ base: 'none', md: 'none' }}>
                <button className="btn btn-secondary btn-sm" w="15rem" background="light" color="body" >
                  <LinkStyled to={ConstantesSistema.SiteRutas.olvide} _hover={{ textDecor: 'none' }} >
                    Olvidé mi contraseña
                  </LinkStyled>
                </button>
              </Stack>
            </Stack>
            <Center hidden={loading}>
              <Button variant={'solid'} w="15rem" background="secondary" _hover={{ bg: 'secondaryHover' }} onClick={onAceptar} >
                Iniciar Sesión
              </Button>
            </Center>
            <Center hidden={!loading}>
              <Loading color="#C9A221" texto="Validando usuario..." tam={30} />
            </Center> 
          </Stack>
        </Stack>
      </Flex>
      <Flex
        display={{ base: 'none', md: 'block' }}
        flex={1}
        backgroundImage={loginbg}
        backgroundSize="cover"
        backgroundPosition="center center"
        backgroundRepeat="no-repeat"
      ></Flex>
    </Stack>
  );
};

export default Login;