import React, { useState, useEffect } from 'react';
import { mostrarError, mostrarExito, mostrarWarning } from '../Util/Notification/Notification'
import {
  Center,
  Stack,
  Box,
  Text,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Alert,
  AlertIcon,
} from '@chakra-ui/react';
import { BiChevronDown } from 'react-icons/bi';
import { FaSearch } from 'react-icons/fa';
import ConstantesSistema from '../../Constants/ConstantesSistema';

import { ObtenerEmpresasRequest } from '../../request/EmpresasRequest';
import { ObtenerEstiloRequest, ObtenerVehiculosxEstiloRequest, ObtenerVehiculosxPlacaRequest } from '../../request/VehiculosRequest'
import Loading from '../../components/Util/Loading/Loading';
import { Annios } from '../Buscar/Annios';
import ConstantesConsultaEmpresa from '../../Constants/ConstantesConsultaEmpresa';
import { DetallesSimulador } from './DetallesSimulador';
import { Codigo } from './Codigo';
import { PrecioRecibo } from './PrecioRecibo';



export const Busqueda = () => {

    const [listaEmpresas, setListaEmpresas] = useState([]);
    const [tipoBusqueda, setTipoBusqueda] = useState(0);
    const [NuevaBusqueda, setNuevaBusqueda] = useState(false);
    const [desTipoBusqueda, setDesTipoBusqueda] = useState('Tipo Búsqueda');
    const [empresaSel, setEmpresaSel] = useState([]);
    const [loading, setLoading] = useState(false);
    const [codigos, setCodigos] = useState([]);
    const [estilo, setEstilo] = useState(null);
    const [enviadoComparacion, setEnviadoComparacion] = useState(false);
    const [listaVehiculo, setListaVehiculo] = useState([]);
    const [ver, setVer] = useState({ tipo: false, codigo: false, anio: false, btn: false,deta:false});
    const [texto,setTexto] =useState('');
    const [NoResultado,setNoResultado] =  useState(localStorage.getItem(ConstantesSistema.paramNoVh));
    const [mensajeNoVh,setMensajeNoVh]=useState(false);
    const [placa, setPlaca] = useState(null);
    const listaBusqueda = [
      { "id": ConstantesSistema.tipoBusCodigo, "des": "Código" },
      { "id": ConstantesSistema.tipoBusPlaca, "des": "Placa" }
    ]
    const [loadingAnnios, setLoadingAnnios] = useState(false);

    useEffect(() => {

      if(tipoBusqueda === ConstantesSistema.tipoBusCodigo){
        if(estilo!=null && enviadoComparacion){
          buscarVehiculo();
          setEnviadoComparacion(false); //luego de esto, la pantalla debe funcionar como si no fuera enviado
        }
      }
      else if (tipoBusqueda === ConstantesSistema.tipoBusPlaca){
        if(placa!=null && enviadoComparacion){
          buscarVehiculo();
          setEnviadoComparacion(false); //luego de esto, la pantalla debe funcionar como si no fuera enviado
        }
      }

    }, [estilo, placa]);

  
    useEffect(() => {

      if(localStorage.getItem(ConstantesSistema.enviadoComparacion)){
        setEnviadoComparacion(true);
        localStorage.removeItem(ConstantesSistema.enviadoComparacion);
      }
      else{
        localStorage.removeItem(ConstantesSistema.enviadoComparacion);
        localStorage.removeItem(ConstantesSistema.vehiculoSel);
        localStorage.removeItem(ConstantesSistema.annioSel);
        localStorage.removeItem(ConstantesSistema.empresaSel);
        setEnviadoComparacion(false);
      }

        obtener_Empresas();

    }, []);

    const obtener_Empresas = () => {

      const ObtenerEmpresas = async () => {
        const vDatos = await ObtenerEmpresasRequest(localStorage.getItem(ConstantesSistema.nombre_Tkn), ConstantesConsultaEmpresa.Consultas);
        if (vDatos.exitoso) {
          setListaEmpresas(vDatos.objeto);
        }
        else {
          mostrarError(vDatos.mensajeRespuesta);
        }
      }
      ObtenerEmpresas();
    }

    useEffect(() => {

      if(listaEmpresas.length>0){

        if(enviadoComparacion){

          var empresaSel = listaEmpresas.find((x) => x.id === parseInt(localStorage.getItem(ConstantesSistema.empresaSel)));
         
          onItemMenuClick(empresaSel);

        }
      }

    }, [listaEmpresas]);

    useEffect(async () => {

   
      if(empresaSel){
        if(!isNaN(empresaSel.id)){

          const Obtener = async () => {
            setLoading(true);
            const vDatos = await ObtenerEstiloRequest(localStorage.getItem(ConstantesSistema.nombre_Tkn), parseInt(empresaSel.id));
            if (vDatos.exitoso) {
              let vCod = vDatos.objeto.filter(x => x.busqueda === 2);
              setCodigos(vCod);
              setLoading(false);
            }
            else {
              mostrarError(vDatos.mensajeRespuesta);
            }
          }
          await Obtener();

          if(!enviadoComparacion){
            setTipoBusqueda(0);
          }
        }
      }
    
    }, [empresaSel]);

    useEffect(()=> {

      if(enviadoComparacion){
        var item_busc = listaBusqueda.find((x) => x.id === parseInt(localStorage.getItem(ConstantesSistema.tipoBusqueda)));

        if(item_busc){       
     
          onClickTipoBusqueda(item_busc);
        }
      }
      else{
        setTipoBusqueda(0);
      }

    }, [codigos])

    useEffect(() => {

      if(tipoBusqueda>0 && empresaSel.id>0){
         if(enviadoComparacion){
           if(tipoBusqueda === ConstantesSistema.tipoBusCodigo){
            if(JSON.parse(localStorage.getItem(ConstantesSistema.vehiculoSel))!=null){
              setCodigos(JSON.parse(localStorage.getItem(ConstantesSistema.vehiculoSel)).codigo);
              setEstilo(JSON.parse(localStorage.getItem(ConstantesSistema.vehiculoSel)).codigo);
             } 
           }
           else if(tipoBusqueda === ConstantesSistema.tipoBusPlaca){
            setPlaca(localStorage.getItem(ConstantesSistema.vehiculoSel));
           }
        }
        else{
          if(tipoBusqueda === 0){

            setDesTipoBusqueda('Tipo Búsqueda');
            setTexto('');
            setPlaca(null);
            setEstilo(null);
            setVer({...ver, deta: false});
          }
        }
        
      }
      else{
        setDesTipoBusqueda('Tipo Búsqueda');
        setTexto('');
        setPlaca(null);
        setEstilo(null);
        setVer({...ver, deta: false});
      }

    }, [tipoBusqueda]);

    const onItemMenuClick = (pItem) => {

      localStorage.setItem(ConstantesSistema.empresaSel, pItem.id);
      setEmpresaSel(pItem);
      //setVer({...ver, tipo: true, deta: false });
      setVer({...ver, tipo: true});

    }

    const onClickTipoBusqueda = (pItem) => {

      if(!enviadoComparacion){
        setDesTipoBusqueda('Tipo Búsqueda');
        setTexto('');
        setPlaca(null);
        setEstilo(null);
        setVer({...ver, deta: false});
      }

      setTipoBusqueda(pItem.id)
      setDesTipoBusqueda(pItem.des);
      setVer({...ver,deta:false});
      setTexto('Buscar por ' + pItem.des);
      let vAnio = false;
      if (pItem.id === ConstantesSistema.tipoBusCodigo) {
        vAnio = true;
      }
      setVer({...ver, codigo: true, anio: vAnio, btn: true });
      if (pItem.id === ConstantesSistema.tipoBusCodigo && codigos.length === 0) {
        setLoading(true);
       
      }
      localStorage.setItem(ConstantesSistema.tipoBusqueda, pItem.id);

    }

    const buscarVehiculo = async () => {
  
      let vEmpresa = empresaSel.id //parseInt(localStorage.getItem(ConstantesSistema.empresaSel));
      let vTipo = tipoBusqueda; //parseInt(localStorage.getItem(ConstantesSistema.tipoBusqueda));
      let vCod = localStorage.getItem(ConstantesSistema.vehiculoSel);

      setNuevaBusqueda(true);

      setListaVehiculo([]);

      if(vEmpresa===null || vEmpresa ===''){
        mostrarError('Por favor seleccione una compañía');
        return;
      }
      if(vTipo===null || vTipo ===''){
        mostrarError('Por favor seleccione el tipo de búsqueda');
        return;
      }

      if (parseInt(vTipo) === ConstantesSistema.tipoBusCodigo){
        setVer({tipo: true, codigo: true, anio: true, btn: true, deta: false});
      }else{
        setVer({tipo: true, codigo: true, anio: false, btn: true, deta: false});
      }

      setMensajeNoVh(false);

      try { 
        vEmpresa = parseInt(vEmpresa);
        vTipo =parseInt(vTipo);
        if (vTipo === ConstantesSistema.tipoBusCodigo) {

          let vAnnio = localStorage.getItem(ConstantesSistema.annioSel);
          if(vCod===null || vCod ===''){
            mostrarError('Por favor ingrese el código o marca a consultar');
            return;
          }
          if (vAnnio === null || vAnnio==='') {
            mostrarError('Por favor ingrese el año a consultar');
            return;
          }
          var response = await ObtenerVehiculosxEstiloRequest(localStorage.getItem(ConstantesSistema.nombre_Tkn), vEmpresa, 2, estilo, parseInt(vAnnio),'',"Calc");
          if (response) {
            if(response.objeto.length>0){
              var vDatos = response.objeto[0];
              vDatos.empresaId = vEmpresa;
              setListaVehiculo(vDatos);
              setVer({tipo: true, codigo: true, anio: true, btn: true, deta: true});
              localStorage.setItem(ConstantesSistema.vehiculoDet, JSON.stringify(vDatos));  
            }else{
              setMensajeNoVh(true);
            }          
          }
        } else {
      
          if (vTipo === ConstantesSistema.tipoBusPlaca) {
            if(vCod===null || vCod ===''){
              mostrarError('Por favor ingrese la placa a consultar');
              return;
            }
            var response = await ObtenerVehiculosxPlacaRequest(localStorage.getItem(ConstantesSistema.nombre_Tkn), vEmpresa, vCod.toString().toUpperCase(),"Calc");
            if (response.exitoso) {
              if(response.objeto.length>0){
                var vDatos = response.objeto[0];
                vDatos.empresaId = vEmpresa;
                setListaVehiculo(vDatos);
                setVer({tipo: true, codigo: true, anio: false, btn: true, deta: true});
                localStorage.setItem(ConstantesSistema.vehiculoDet, JSON.stringify(vDatos));
              }  
              else{
                setMensajeNoVh(true);
              }         
            }else{
              mostrarError(response.mensajeRespuesta);
              return;
            }
          } else{
            mostrarError("Seleccione un tipo de Búsqueda.");
            return;
          }

        }
      } catch (ex) {
        mostrarError(ex);
      }
    }
  
    
    const onChangeEstilo = (pEstilo) => {
      setEstilo(pEstilo);
    }
  
    const onSetLoadingAnnio = (valor) => {
      setLoadingAnnios(valor);
    }

    const texto_automatico = () => {

      
      var resultado = '';

      if (tipoBusqueda === ConstantesSistema.tipoBusCodigo){
        resultado =  estilo;
      }
      else if (tipoBusqueda === ConstantesSistema.tipoBusPlaca){
        resultado = placa;
      }  
      else{
        resultado = '';
      }

      return resultado;
      
    }
  
    return (
      <Stack
        bg={{ base: 'light', lg: 'light' }}
        boxShadow={{ base: 'light', lg: 'light' }}
        px={{ base: '0', xl: '0' }}
        spacing={1}
        w={{ base: '100%', lg: '100%' }}
      >
        <Stack direction={{ base: 'column', lg: 'row' }} py={3} spacing="4" bg={{ base: 'light', lg: 'light' }} >
          <Stack direction={{ base: 'column', lg: 'row' }} bg={{ base: 'none', lg: 'light' }} w={{ base: '100%', lg: '100%' }}>
            <Stack display={{ base: 'none', xl: 'block' }} w={{base: '95%', lg: '20%'}} direction="row" pr={4} borderRight="1px solid #E4E4E3" fontWeight="bold">
              <Center>
                <Text fontSize="xl">Calculadora</Text>
              </Center>
            </Stack>
            <Stack direction="row" pr={4} borderRight="1px solid #E4E4E3" fontWeight="bold">
              <Text p={2}>Compañía</Text>
              <Box>
                <Menu>
                  <MenuButton
                    variant="ghost"
                    as={Button}
                    color={(empresaSel.length===0)?'tertiary':"#000000"}
                    borderBottom="1px solid #E4E4E3"
                    rounded="none"
                    fontSize="md"
                    fontWeight="regular"
                    _active={{
                      bg: 'none',
                    }}
                    _hover={{
                      bg: 'none',
                    }}
                    rightIcon={<BiChevronDown />}
                  >
                    <Text>{(empresaSel.length===0)?'Seleccione una compañía':empresaSel.nombre}</Text>
                  </MenuButton>
                  <MenuList overflowY="auto" maxH="10rem" p={1} minWidth="max-content">
                    {listaEmpresas.map((item, index) => {
                      return (
                        <MenuItem rounded="full" fontWeight="bold" w="max-content" key={index} onClick={() => { onItemMenuClick(item) }}>
                          {item.nombre}
                        </MenuItem>
                      );
                    })}
                  </MenuList>
                </Menu>
              </Box>
            </Stack>
            <Stack w={{ base: '100%', lg: '70%' }} direction={'row'} pr={4} borderRight="1px solid #E4E4E3" fontWeight="bold" hidden={!ver.tipo}>
              <Box w={{base: '6rem', lg: '20%'}}>
                <Menu>
                  <MenuButton
                    variant="ghost"
                    as={Button}
                    color="#000000"
                    borderBottom="1px solid #E4E4E3"
                    rounded="none"
                    fontSize="md"
                    fontWeight="bold"
                    _active={{
                      bg: 'none',
                    }}
                    _hover={{
                      bg: 'none',
                    }}
                    rightIcon={<BiChevronDown />}
                  >
                    <Text>{desTipoBusqueda}</Text>
                  </MenuButton>
                  <MenuList overflowY="auto" maxH="10rem" p={4} minWidth="max-content">
                    {listaBusqueda.map((item, index) => {
                      return (
                        <MenuItem rounded="full" fontWeight="bold" w="max-content" key={index} onClick={() => {onClickTipoBusqueda(item) }}>
                          {item.des}
                        </MenuItem>
                      );
                    })}
                  </MenuList>
                </Menu>
              </Box>
              <Box w={{base: '45%', md: '40%'}} hidden={!ver.codigo}>
                <Codigo Item={codigos} 
                        texto={texto} 
                        pTextoAutomatico={texto_automatico()} 
                        pTipoBusqueda = {tipoBusqueda}
                        enviadoComparacion={enviadoComparacion}  
                        onChangeEstilo={onChangeEstilo} />
              </Box>   
              <Stack hidden={!loadingAnnios} >
                <Loading color="#D8222B" texto="" tam={30} />
              </Stack>     
              <Stack w={{ base: '20%', md: '15%' }} hidden={!ver.anio || loadingAnnios} >
                <Annios pEstilo={estilo} onSetLoading={onSetLoadingAnnio}  />
              </Stack>
              <Stack
                direction="row"
                spacing={2}
                bg={{ base: 'light', xl: 'none' }}
                boxShadow={{ base: 'none', xl: 'none' }}
                align="center"
                px={{ base: '1', lg: '10' }}
                py={{ base: '1', lg: '0' }}
                borderRightRadius={{ base: 'full', xl: 'none' }}
                hidden={!ver.btn}
              >
                <Button
                  background="primary"
                  variant="solid"
                  color="light"
                  rounded="full"
                  p={{ base: '1', md: '4' }}
                  _hover={{ bg: 'primaryHover' }}
                  onClick={buscarVehiculo}
                >
                  <FaSearch fontSize="1rem" display={{ base: 'none', md: 'block' }} />
                  <Text display={{ base: 'none', md: 'block' }} ml={2} fontSize="sm">
                    Buscar
                  </Text>
                </Button>
              </Stack>
            </Stack>         
          </Stack>
        </Stack>
        <Stack direction={{ base: 'column', lg: 'row' }} spacing={0} w={{ base: '100%', lg: '100%' }}>
          <Stack direction={{ base: 'column', lg: 'row' }} justifyContent="space-between" bg={{ base: 'none', lg: 'light' }} w={{ base: '100%', lg: '100%' }}>
            <Box flex="1" bg="#E4E4E3" minH="100%" hidden={!ver.deta}>
              <DetallesSimulador vh={listaVehiculo} />
            </Box>
            <Box flex="2" bg="#F2F1F1" minH="100%" hidden={!ver.deta}>
              <PrecioRecibo pNuevaBusqueda={NuevaBusqueda}/>
            </Box>
            <Box flex="1" bg="#F2F1F1" minH="100%" hidden={!mensajeNoVh}>
              <Stack bg="secondary" boxShadow="sm" direction="row" justifyContent="center" pr={4}>
                <Alert status="warning" bg="none" color="light">
                  <AlertIcon color="light" alignSelf="flex-start" />
                  {NoResultado}
                </Alert>
              </Stack>
            </Box>
          </Stack>
        </Stack>
      </Stack>
    );
  };