import React, { useState,useEffect } from 'react';
import { NavLinkStyled } from '../../styles';
import { useHistory } from "react-router-dom";
import {
    Box,
    Container,
    Row,
    Column,
    Column2,
    FooterLink,
    Heading,
} from "./FooterStyles";
import {Center} from '@chakra-ui/react';
const Footer = () => {
    const anio = new Date().getFullYear();
    const goUrl = async (item) => {
        window.open(item)
    }
    return (
        <>
            <Box>
                <Container>
                    <Center>
                        <Heading >Derechos Reservados {anio} &copy; Grupo Purdy</Heading>
                    </Center>
                </Container>
            </Box>
        </>
    );
}
export default Footer;