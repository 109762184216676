import React, { useState } from 'react';
import { LinkStyled } from '../../styles';
import { useHistory } from "react-router-dom"
import { mostrarError, mostrarExito, mostrarWarning } from '../Util/Notification/Notification';

import {
  Button,
  Flex,
  Text,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Center,
  Heading,
  Image,
  usePrefersReducedMotion,
} from '@chakra-ui/react';
import loginbg from '../../assets/images/PB.jpg';
import { moveInUp } from '../../styles';

import { ObtenerUsuarioRequest } from '../../request/UsuarioRequest';
import Loading from '../../components/Util/Loading/Loading';
import { OlvideContraseniaRequest } from '../../request/LoginRequest';
import ConstantesSistema from '../../Constants/ConstantesSistema';

const Password = () => {
  const [datos, setDatos] = useState({ username: '', email: '', loading: false,textoLoading:'' });
  const prefersReducedMotion = usePrefersReducedMotion();
  const animation = prefersReducedMotion ? undefined : `${moveInUp} 0.6s`;
  const history = useHistory()
  const OnKeyPressT = event => {
    const { value } = event.currentTarget;
    if (event.key === 'Enter') {
      setDatos({ ...datos, loading: true,textoLoading:'Validando usuario' });
      onObtenersuario();
    }
  }
  const onObtenersuario = async () => {
    try {
      const response = await ObtenerUsuarioRequest(datos.username);
      if (response.exitoso) {
        if (response.objeto.length > 0) {
          let vUser = response.objeto[0];
          setDatos({ ...datos, email: vUser.usuario_Email,loading: false });
        } else {
          mostrarError('Usuario no encontrado.');
          setDatos({ ...datos, loading: false });
        }
      }
    } catch (ex) {
      mostrarError(ex);
      setDatos({ ...datos, loading: false });
    }    
  }
  const GenerarContraseniaTemporal = async () => {
    setDatos({ ...datos, loading: true,textoLoading:'Enviando clave temporal' });
    const respuesta = await OlvideContraseniaRequest(datos.username)
    if (respuesta.exitoso) {
      mostrarExito('Ha sido enviada una contraseña temporal al correo: ' + datos.email);
      setDatos({ contraseniaTemporal: respuesta.objeto, loading: false });
      history.push(ConstantesSistema.SiteRutas.Login);
    }
    else {
      mostrarError(respuesta.mensajeRespuesta);
      setDatos({ ...datos, loading: false });
    }
  }
  return (
    <Stack
      minH={'100vh'}
      direction={{ base: 'column', md: 'row' }}
      background={{ base: '#060606', md: 'primary' }}
      backgroundImage={'none'}
      backgroundSize="cover"
      backgroundPosition="center center"
      backgroundRepeat="no-repeat"
    >
      <Flex p={8} flex={1} align={'center'} justify={'center'} color="light">
        <Stack spacing={6} w={'full'} maxW={'md'} animation={animation}>
          <Center mb={{ base: '0rem', md: '1rem' }}>
            <Image src={loginbg} alt="Logo" w="65%" display={{ base: 'block', md: 'none' }} />
          </Center>
          <Center mb="1rem">
            <Heading>Olvidé mi Contraseña</Heading>
          </Center>
          <FormControl id="user">
            <FormLabel display="flex">
              Usuario
              <Text className="ml-2">(Escriba su usuario y presione ENTER)</Text>
            </FormLabel>
            <Input
              type="text"
              color="body"
              variant="filled"
              _focus={{ bg: 'light' }}
              placeholder="Escriba su nombre de usuario y presione ENTER"
              onChange={(event) => setDatos({ ...datos, username: event.currentTarget.value })}
              onKeyPress={(event) => OnKeyPressT(event)}
            />
          </FormControl>
          <FormControl id="email" hidden={datos.email===''}>
            <FormLabel>Correo Electrónico</FormLabel>
            <Input type="email" color="body" variant="filled" _focus={{ bg: 'light' }} disabled
              value={datos.email}
              onChange={(event) => setDatos({ ...datos, email: event.currentTarget.value })} />
          </FormControl>
          <Stack direction={'row'} align={'start'} justify={'flex-end'}>
            <LinkStyled to={ConstantesSistema.SiteRutas.Login} _hover={{ textDecor: 'none' }}>
              Cancelar
            </LinkStyled>
          </Stack>
          <Center mt="1rem" hidden={datos.loading}>
            <Button hidden={datos.email === ''} onClick={GenerarContraseniaTemporal}
              variant={'solid'} w="15rem" background="secondary" _hover={{ bg: 'secondaryHover' }}>
              Enviar
            </Button>
          </Center>
          <Center hidden={!datos.loading}>
            <Loading color="#C9A221" texto={datos.textoLoading} tam={30} />
          </Center>
        </Stack>
      </Flex>
      <Flex
        display={{ base: 'none', md: 'block' }}
        flex={1}
        backgroundImage={loginbg}
        backgroundPosition="center center"
        backgroundSize="cover"
        backgroundRepeat="no-repeat"
      ></Flex>
    </Stack>
  );
};

export default Password;
