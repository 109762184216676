import React, { Fragment } from 'react';
import Seo from '../../components/Layout/Seo';
import Navbar from '../../components/Layout/Navbar';
import Detalle from '../../components/Detalle/Detalle';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

const DetallePlacaPage = () => {
  return (
    <Fragment>
      <Seo title="Detalle de Vehículo" />
      <Navbar />
      <Detalle />
    </Fragment>
  );
};

const mapStateToProps = state => {
  return {
      usuario: state.usuario
  };
};

export default connect(mapStateToProps)(withRouter(DetallePlacaPage));

//export default DetallePlacaPage;
