/*
    Opciones de menu en seguridad, utiliza el 
*/
export const ConstantesMenu = {
    nombre:"opcionesSubMenu",
    validar:false,
    PBBuscarCodigo_VerEstilosRestringidos:{menu_id: 1,opcion_id: 1},
    PBBuscarCodigo_VerCaracteristicasGenerales: { menu_id: 1, opcion_id: 2 },
    PBBuscarCodigo_VerCantidadesenInventario: { menu_id: 1, opcion_id: 3 },
    PBBuscarCodigo_VerMontoPrecioRecibo: { menu_id: 1, opcion_id: 4 },
    PBBuscarCodigo_VerMontoPrecioSugerido: { menu_id: 1, opcion_id: 5 },
    PBBuscarCodigo_VerValorFiscalydeContrato: { menu_id: 1, opcion_id: 6 },
    PBBuscarCodigo_VerPromediosdeVenta: { menu_id: 1, opcion_id: 7 },
    PBBuscarCodigo_VerEquipamiento: { menu_id: 1, opcion_id: 8 },
    PBBuscarCodigo_VerPestannaHistoricosdeVenta: { menu_id: 1, opcion_id: 9 },
    PBBuscarPlaca_VerPestannaPrecioRecibo: { menu_id: 2, opcion_id: 1 },
    PBBuscarPlaca_VerEstilosRestringidos: { menu_id: 2, opcion_id: 2 },
    PBBuscarPlaca_VerCaracteristicasGenerales: { menu_id: 2, opcion_id: 3 },
    PBBuscarPlaca_VerMontoPrecioRecibo: { menu_id: 2, opcion_id: 4 },
    PBBuscarPlaca_VerMontoPrecioSugerido: { menu_id: 2, opcion_id: 5 },
    PBBuscarPlaca_VerEquipamiento: { menu_id: 2, opcion_id: 6 },
    PBBuscarPlaca_VerPestannaHistoricosdeVenta: { menu_id: 2, opcion_id: 7 },
    PBBuscarPlaca_EnvioaSalesForce: { menu_id: 2, opcion_id: 8 },
    PBComparacion_VerCaracteristicasGenerales:{ menu_id: 3, opcion_id: 1, cod_seguridad: 1 },
    PBComparacion_VerCantidadesenInventario:{ menu_id: 3, opcion_id: 2, cod_seguridad: 2 },
    PBComparacion_VerMontoPrecioRecibo:{ menu_id: 3, opcion_id: 3, cod_seguridad: 3 },
    PBComparacion_VerMontoPrecioSugerido:{ menu_id: 3, opcion_id: 4, cod_seguridad: 4 },
    PBComparacion_VerPromediosdeVenta:{ menu_id: 3, opcion_id: 5, cod_seguridad: 5 },
    PBComparacion_VerEquipamiento:{ menu_id: 3, opcion_id: 6, cod_seguridad: 0 },
}   

export default ConstantesMenu;