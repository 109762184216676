import React from 'react';
import ReactDOM from 'react-dom';
import 'focus-visible/dist/focus-visible';
// import '@fontsource/roboto/400.css';
import { ChakraProvider } from '@chakra-ui/react';
import App from './App';
import { theme } from './styles';
import { Provider } from 'react-redux';
import { createBrowserHistory } from 'history';
import configureStore from './store/configureStore';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/montserrat.css';
require('typeface-sofia');
const initialState = window.initialReduxState;
const history = createBrowserHistory();
const { store, persistor } = configureStore(history, initialState);

ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <ChakraProvider resetCSS theme={theme}>
        <App />
      </ChakraProvider>
    </React.StrictMode>
  </Provider>,
  document.getElementById('root')
);
