import React, { useState, useEffect } from 'react';
import {
    Center,
    Stack,
    Image,
} from '@chakra-ui/react';
import construcion from '../../assets/images/underConstruccion.jpg';
export const Construccion = () => {
    return (
        <Stack bg="light" p={6} rounded="md" boxShadow="md">
            <Center>
                <Image src={construcion} alt="Logo" w="50%" />
            </Center>
        </Stack>);
};

export default Construccion;