import React, { Fragment } from 'react';
import Seo from '../../components/Layout/Seo';
import Login from '../../components/Auth/Login';

const LoginPage = () => {

  return (
    <Fragment>
      <Seo title="Iniciar Sesión" />
      <Login/>
    </Fragment>
  );
};

export default LoginPage;
