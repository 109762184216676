import { GetResponseBody, baseUrlApi,baseUrlApiPC, GetHeaderRequest } from './PathConfig';

export const ObtenerEstiloRequest = async (pToken,pEmpresaId) => {
    const result = await fetch(`${baseUrlApi}api/UtilBusquedas/ObtenerDatosBusquedaEstilo?empresaId=${pEmpresaId}`, {
        method: 'GET',
        headers: GetHeaderRequest(pToken)
    });
    return await result.json();
};

export const ObtenerVehiculosxEstiloRequest = async (pToken, pEmpresaId, pBusqueda, pCodigo, pAnio,pExtras, Origen) => {
    const data = { empresaId: pEmpresaId, busqueda: pBusqueda, codigo: pCodigo, anio: pAnio,extras:pExtras };
    let DesOrigen = Origen === "Calc" ? "Calculadora_ConsultaEstilo": "ConsultaxEstilo";
    const result = await fetch(`${baseUrlApi}api/Busquedas/ObtenerVehiculosxEstilo?Pantalla=${DesOrigen}`, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: GetHeaderRequest(pToken)
    });
    return await GetResponseBody(result);
};
export const ObtenerVehiculosxPlacaRequest = async (pToken, pEmpresaId, pPlaca, Origen) => {
    const data = {empresaId: pEmpresaId, placa: pPlaca };
    let DesOrigen = Origen == "Calc" ? "Calculadora_ConsultaxPlaca": "ConsultaxPlaca";
    const result = await fetch(`${baseUrlApi}api/Busquedas/ObtenerVehiculoxPlaca?Pantalla=${DesOrigen}`, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: GetHeaderRequest(pToken)
    });
    return await GetResponseBody(result);
};
export const ObtenerHistoricoVentasRequest = async (pToken, pEmpresaId, pEstilo,pAnnio) => {
    const data = { empresaId: pEmpresaId, estilo: pEstilo, anno: pAnnio };
    const result = await fetch(`${baseUrlApi}api/Busquedas/ObtenerHistoricoVentas`, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: GetHeaderRequest(pToken)
    });
    return await GetResponseBody(result);
};
export const ObtenerPromedioVentasRequest = async (pToken, pEmpresaId, pEstilo,pAnnio,pMes) => {
    const data = { empresaId: pEmpresaId, estilo: pEstilo, anno: pAnnio };
    const result = await fetch(`${baseUrlApi}api/Busquedas/ObtenerPromediosVentas?meses=${pMes}`, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: GetHeaderRequest(pToken)
    });
    return await GetResponseBody(result);
};
export const ObtenerListaMeses = async (pToken) => {
    const result = await fetch(`${baseUrlApi}api/UtilBusquedas/ObtenerListaMeses_HistConsulta`, {
        method: 'GET',
        headers: GetHeaderRequest(pToken)
    });
    return await GetResponseBody(result);
};
export const ObtenerEstadosRequest = async (pToken) => {
    const result = await fetch(`${baseUrlApi}api/EstadoVehiculos/ObtenerEstadoVehiculos`, {
        method: 'GET',
        headers: GetHeaderRequest(pToken)
    });
    return await GetResponseBody(result);
};
export const ObtenerPrecioAvaluoRequest = async (pToken, data) => {
    const result = await fetch(`${baseUrlApi}api/PrecioAvaluo/ObtenerPrecioAvaluo`, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: GetHeaderRequest(pToken)
    });
    return await GetResponseBody(result);
};
export const ObtenerAnnosPorEstiloRequest = async (pToken,  pEmpresaId, pEstilo) => {
    const data = { empresaId: parseInt(pEmpresaId), estilo: pEstilo, anno: 0 };
    const result = await fetch(`${baseUrlApi}api/UtilBusquedas/ObtenerAnnosPorEstilo`, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: GetHeaderRequest(pToken)
    });
    //console.log(result);
    return await GetResponseBody(result);
};

export const ObtenerValorFiscalyContratoRequest = async (pToken,pPlaca) => {
    const result = await fetch(`${baseUrlApi}api/Busquedas/ObtenerValorFiscalyContrato?placa=${pPlaca}`, {
        method: 'POST',
        headers: GetHeaderRequest(pToken)
    });
    return await GetResponseBody(result);
};

export const ObtenerEquipamientoRequest = async (pToken, pEmpresaId, pEstilo, pAnnio) => {
    const data = { empresaId: parseInt(pEmpresaId), estilo: pEstilo, anno: parseInt(pAnnio) };
    const result = await fetch(`${baseUrlApi}api/Busquedas/ObtenerEquipamiento`, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: GetHeaderRequest(pToken)
    });
    return await GetResponseBody(result);
};