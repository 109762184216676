import {
    Stack,
    Text,
    Alert,
    AlertIcon,
    Button,
    Icon,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    Input,
    Select,     
  } from '@chakra-ui/react';
  import { BiChevronDown } from 'react-icons/bi';
  import React, { useState, useEffect } from 'react';
  import ConstantesSistema from '../../Constants/ConstantesSistema';
  import NumberFormat from "react-number-format";
  import {AvaluoRequest} from '../../request/AvaluoRequest';
  import { MdLabel } from 'react-icons/md';
  import Construcion from '../../components/Construccion/Construccion';
  import PlacaModal from './PlacaModal';
  import Loading from '../../components/Util/Loading/Loading';
  import { ObtenerEstadosRequest, ObtenerPrecioAvaluoRequest } from '../../request/VehiculosRequest';
  import { mostrarError } from '../Util/Notification/Notification';


export const PrecioRecibo = () => {
    const [vh, setVh] = useState(JSON.parse(localStorage.getItem(ConstantesSistema.vehiculoDet)));
    const [estado, setEstado] = useState([]);
    const [avaluo,setAvaluo]=useState([]);
    const [existeAvaluo,setExisteAvaluo]=useState(false);  
    const [loading, setLoading] = useState(false);
    const [datos, setDatos] = useState({ kilometraje: '', mecanica: '', carroceria: '', taller: 0, estado: 0, estadoDes: '', tallerDes: '' });
    const CargarAvaluo = async()=>{   
      const response = await AvaluoRequest(localStorage.getItem(ConstantesSistema.nombre_Tkn),vh.emp,vh.placa);
      if (response.exitoso) {
        setExisteAvaluo(true);
        setAvaluo(response.objeto);
        console.log('1',response.objeto);
      }
      else {
        if(response.mensajeRespuesta!=null){
          var x ={
            mensaje:response.mensajeRespuesta,
            enviaSF:false
          }        
          setExisteAvaluo(true);
          await setAvaluo(x);
        }
        //mostrarError(response.mensajeRespuesta);
      }
    }  
    const ObtenerDatos = async () => {
      const vDatos = await ObtenerEstadosRequest(localStorage.getItem(ConstantesSistema.nombre_Tkn));
      if (vDatos.exitoso) {
        setEstado(vDatos.objeto.sort());
        console.log(vDatos.objeto);
      }
      else {
        mostrarError(vDatos.mensajeRespuesta);
      }
    }

    useEffect(() => {
      if (parseInt(localStorage.getItem(ConstantesSistema.tipoBusqueda)) === ConstantesSistema.tipoBusPlaca) {
        CargarAvaluo();
        ObtenerDatos();
      }
    }, []);

    const onSelectCmb = async (pVal, pName, pDes, pNameDes) => {
      console.log('por aqui');
      const x = await setDatos({ ...datos, [pName]: pVal, [pNameDes]: pDes });
      let vEstado = (pName==='estado')?pVal:avaluo.estadoVehiculo;
      let vTaller = (pName==='taller')?pVal:avaluo.historialTaller;
      let vDesEstado = (pName==='estado')?pDes:avaluo.estadoVehiculoDescrip
      ObtenerPrecio(vEstado,vTaller, vDesEstado);
    }

    const ObtenerPrecio = async (pEstado,pTaller, pvDesEstado) => {
      if (avaluo.kilometraje != '' && avaluo.reparacionesMecanicas != '' && avaluo.reparacionesCarroceria != '' && avaluo.estadoVehiculo != 0) {
        var vH = JSON.parse(localStorage.getItem(ConstantesSistema.vehiculoDet));
        console.log(pEstado,pTaller);
        console.log(vH);
        var vObj = {
          "estadoVehiculo": parseInt(pEstado),
          "kilometraje": parseInt(avaluo.kilometraje),
          "historialTaller": pTaller,
          "reparacionesMecanicas": parseInt(avaluo.reparacionesMecanicas),
          "reparacionesCarroceria": parseInt(avaluo.reparacionesCarroceria),
          "precioRecibo": parseInt(vH.precio_Recibo),
          "empresaId": parseInt(vH.emp),
          "estilo": vH.estilo,
          "anno": parseInt(vH.anio)
        }
        console.log(vObj);
        setLoading(true);
        const vDatos = await ObtenerPrecioAvaluoRequest(localStorage.getItem(ConstantesSistema.nombre_Tkn), vObj);
        if (vDatos.exitoso) {
          console.log(vDatos.objeto);
          setAvaluo({...avaluo, precioReciboAvaluo: vDatos.objeto, historialTaller: pTaller, estadoVehiculo: pEstado, estadoVehiculoDescrip:pvDesEstado })
        }
        else {
          mostrarError(vDatos.mensajeRespuesta);
        }
        setLoading(false);
      }
    }
    return (
      <Stack p={{ base: '0', md: '4' }} minH="100vh">
        <Stack spacing={6}>
          <Stack hidden={true}>
            <Construcion />
          </Stack>
          <Stack>
            <Stack bg="light" p={6} rounded="md" boxShadow="md" hidden={!existeAvaluo} >
              <Stack bg="secondary" boxShadow="sm" direction="row" justifyContent="center" pr={4} hidden={avaluo.mensaje === null || avaluo.mensaje === '' || !existeAvaluo}>
                <Alert status="warning" bg="none" color="light">
                  <AlertIcon color="light" alignSelf="flex-start" />
                  {avaluo.mensaje}
                </Alert>
              </Stack>
              <Stack
                direction="row"
                justifyContent="space-between"
                textTransform="uppercase"
                color="tertiary"
                fontWeight="bold"
                px={8}
                mb={6}
                hidden={!avaluo.mostrarDatos || !existeAvaluo}
                display={{ base: 'none', md: 'flex' }}
              >
                <Text>Variable de recibo</Text>
                <Text>Estado según avalúo</Text>
              </Stack>
              <Stack spacing={4} hidden={!avaluo.mostrarDatos}>
                {/* Item */}
                <Stack bg="background" p={3} rounded="md" boxShadow="sm">
                  
                  <Stack direction={{ base: 'column', md: 'row' }} justifyContent="space-between">
                    <Stack fontWeight="bold" justifyContent="flex-start" alignItems="center" direction="row">
                      <Icon as={MdLabel} w={7} h={7} color="error" mr={4} transform="scaleX(-1)" />
                      <Text>Estado de Vehículo</Text>
                    </Stack>
                    {avaluo.origen == 0 ? 
                      <Text alignSelf="center">Excelente</Text>
                      :
                      <Menu>
                        <MenuButton
                          variant="filled" bg="light" as={Button} boxShadow="sm" color="#000000" borderBottom="1px solid #E4E4E3"
                          rounded="none" fontSize="md" w={{ base: '100%', md: '25%' }} fontWeight="regular"
                          rightIcon={<BiChevronDown />}
                        >
                          <Text align="right">{avaluo.estadoVehiculoDescrip}</Text>
                        </MenuButton>
                        <MenuList overflowY="auto" maxH="10rem" p={1} minWidth="max-content">
                          {estado.map((item, index) => {
                            return (
                              <MenuItem rounded="full" fontWeight="bold" w="max-content" key={index} onClick={() => { onSelectCmb(item.codigo, 'estado', item.nombre, 'estadoDes') }}>
                                {item.nombre}
                              </MenuItem>
                            );
                          })}
                        </MenuList>
                      </Menu>
                      
                    }
                  </Stack>
                  
                </Stack>
                {/* Item */}
                <Stack bg="background" p={3} rounded="md" boxShadow="sm">
                  <Stack direction={{ base: 'column', md: 'row' }} justifyContent="space-between">
                    <Stack fontWeight="bold" justifyContent="flex-start" alignItems="center" direction="row">
                      <Icon as={MdLabel} w={7} h={7} color="error" mr={4} transform="scaleX(-1)" />
                      <Text>Kilometraje</Text>
                    </Stack>
                    <Text alignSelf="center">
                      <NumberFormat
                        value={avaluo.kilometraje}
                        displayType={'text'} thousandSeparator={true}
                      />
                    </Text>
                  </Stack>
                </Stack>
                {/* Item */}
                <Stack bg="background" p={3} rounded="md" boxShadow="sm">
                  <Stack direction={{ base: 'column', md: 'row' }} justifyContent="space-between">
                    <Stack fontWeight="bold" justifyContent="flex-start" alignItems="center" direction="row">
                      <Icon as={MdLabel} w={7} h={7} color="error" mr={4} transform="scaleX(-1)" />
                      <Text>Historial del taller</Text>
                    </Stack>
                    {avaluo.origen == 0 ? 
                      <Text alignSelf="center">{(avaluo.historialTaller === 1) ? 'SI' : 'NO'}</Text>
                      :
                      <Menu>
                        <MenuButton
                          variant="filled" bg="light" as={Button} boxShadow="sm" color="#000000" borderBottom="1px solid #E4E4E3"
                          rounded="none" fontSize="md" w={{ base: '100%', md: '25%' }} fontWeight="regular"
                          rightIcon={<BiChevronDown />}
                        >
                          <Text alignSelf="right">{(avaluo.historialTaller === 1) ? 'SI' : 'NO'}</Text>
                        </MenuButton>
                        <MenuList overflowY="auto" maxH="10rem" p={1} minWidth="max-content">
                          <MenuItem rounded="full" fontWeight="bold" w="max-content" key={0} onClick={() => { onSelectCmb(1, 'taller', 'SI', 'tallerDes') }}>
                            SI
                          </MenuItem>
                          <MenuItem rounded="full" fontWeight="bold" w="max-content" key={1} onClick={() => { onSelectCmb(0, 'taller', 'NO', 'tallerDes') }}>
                            NO
                          </MenuItem>
                        </MenuList>
                      </Menu>
                    }
                  </Stack>
                </Stack>
                {/* Item */}
                <Stack bg="background" p={3} rounded="md" boxShadow="sm">
                  <Stack direction={{ base: 'column', md: 'row' }} justifyContent="space-between">
                    <Stack fontWeight="bold" justifyContent="flex-start" alignItems="center" direction="row">
                      <Icon as={MdLabel} w={7} h={7} color="error" mr={4} transform="scaleX(-1)" />
                      <Text>Reparaciones Mecánicas</Text>
                    </Stack>
                    <Text alignSelf="center">
                      <NumberFormat
                        value={avaluo.reparacionesMecanicas}
                        displayType={'text'} thousandSeparator={true} prefix={'$'}
                      />
                    </Text>
                  </Stack>
                </Stack>
                {/* Item */}
                <Stack bg="background" p={3} rounded="md" boxShadow="sm">
                  <Stack direction={{ base: 'column', md: 'row' }} justifyContent="space-between">
                    <Stack fontWeight="bold" justifyContent="flex-start" alignItems="center" direction="row">
                      <Icon as={MdLabel} w={7} h={7} color="error" mr={4} transform="scaleX(-1)" />
                      <Text>Reparaciones de Carrocería</Text>
                    </Stack>
                    <Text alignSelf="center">
                      <NumberFormat
                        value={avaluo.reparacionesCarroceria}
                        displayType={'text'} thousandSeparator={true} prefix={'$'}
                      />
                    </Text>
                  </Stack>
                </Stack>
                {/* Item */}
                <Stack bg={{ base: 'light', md: 'background' }} p={3} rounded="md" boxShadow="sm">
                  <Stack direction={{ base: 'row', md: 'row' }} justifyContent="space-between">
                    <Stack fontWeight="bold" justifyContent="flex-start" alignItems="center" direction="row">
                      <Text textTransform="uppercase" fontSize={{ base: 'xs', md: 'md' }}>
                        Precio de Recibo del avalúo
                      </Text>
                    </Stack>
                    <Text color="error" fontWeight="bold" hidden={loading}>
                      <NumberFormat
                        value={avaluo.precioReciboAvaluo}
                        displayType={'text'} thousandSeparator={true} prefix={'$'}
                      />
                    </Text>
                    <Stack hidden={!loading} >
                      <Loading color="#D8222B" texto="Calculando" tam={30} />
                    </Stack>                    
                  </Stack>
                </Stack>
                {/* Item */}
              </Stack>
            </Stack>
            <Stack justifyContent={{ base: 'center', md: 'flex-end' }} direction="row" hidden={!avaluo.enviaSF || !existeAvaluo || vh.tiene_Restriccion}>
              <PlacaModal avaluo={avaluo} vehiculo={vh} />
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    );
  };