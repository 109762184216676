import React, { useState, Fragment, useEffect } from 'react';
import ConstantesSistema from '../../Constants/ConstantesSistema';
import {
    Stack,
    Text,
  } from '@chakra-ui/react';
import NumberFormat from "react-number-format";

export const Cantidades = ({ preciosugventa, preciorecibo, cantinventario,permiso }) => {
    const [tipo,setTipo]= useState(0);
    useEffect(() => {
      if (window.location.href.toString().toUpperCase().indexOf("PLACA") > -1) {
        setTipo(ConstantesSistema.tipoBusPlaca);
      }else{
        setTipo(ConstantesSistema.tipoBusCodigo);
      }        
    }, []);
    return (
      <Stack
        paddingLeft={{ base: 0, md: '10' }}
        borderLeft={{ base: 0, md: '1px solid #E6E4E4' }}
        alignItems={{ base: 'center', md: 'flex-end' }}
        justifyContent="center"
        spacing={4}
        borderBottom={{ base: '1px solid #E6E4E4', md: '0' }}
        py={{ base: '8', md: '0' }}
      >
        <Stack direction={{ base: 'column', md: 'row' }} alignItems="center" hidden={permiso.opSugerido}>
          <Text fontWeight="bold" color="bodyTitle" textTransform="uppercase">
            Precio Sugerido de venta:
          </Text>
          <Text fontWeight="bold" color="error">
            <NumberFormat
              value={preciosugventa}
              displayType={'text'} thousandSeparator={true} prefix={'$'}
            />
          </Text>
        </Stack>
        <Stack direction={{ base: 'column', md: 'row' }} alignItems="center" hidden={permiso.opRecibo}>
          <Text fontWeight="bold" color="bodyTitle" textTransform="uppercase">
            Precio de Recibo:
          </Text>
          <Text fontWeight="bold" color="error">
            <NumberFormat
              value={preciorecibo}
              displayType={'text'} thousandSeparator={true} prefix={'$'}
            />
          </Text>
        </Stack>
        <Stack direction={{ base: 'column', md: 'row' }} alignItems="center" hidden={permiso.opInventario || tipo ===ConstantesSistema.tipoBusPlaca}>
          <Text fontWeight="bold" color="bodyTitle" textTransform="uppercase">
            Cantidad en Inventario:
          </Text>
          <Text fontWeight="bold" color="error">
            {cantinventario}
          </Text>
        </Stack>
      </Stack>
    );
  };
  