import { GetResponseBody, GetHeaderRequest, baseUrlApi } from './PathConfig';

export const ObtenerParamIdRequest = async (pToken,pParam) => {
    const result = await fetch(`${baseUrlApi}api/Parametros/ObtenerParametroPorId?id=${pParam}`, {
        method: 'GET',
        headers: GetHeaderRequest(pToken)
    });
    return await GetResponseBody(result);
};
export const ObtenerParamRequest = async (pToken) => {
    const result = await fetch(`${baseUrlApi}api/Parametros/ObtenerParametros`, {
        method: 'GET',
        headers: GetHeaderRequest(pToken)
    });
    return await GetResponseBody(result);
};