import {
    Stack,
    Box,
    Text,
    Icon,
    Checkbox,
    CheckboxGroup
  } from '@chakra-ui/react';
  import {FaAngleUp,FaAngleDown } from 'react-icons/fa';
  import ConstantesSistema from '../../Constants/ConstantesSistema';
  import {ObtenerEquipamientoRequest } from '../../request/VehiculosRequest';
  import React, { useState, useEffect } from 'react';
  import { MdLabel } from 'react-icons/md';
  import { mostrarError, mostrarExito, mostrarWarning }from '../Util/Notification/Notification';

export const Equipamiento = () => {
    var vh = JSON.parse(localStorage.getItem(ConstantesSistema.vehiculoDet));
    const [lista,setLista]=useState([]);
    const CargarEquipamento = async()=>{
      const response = await ObtenerEquipamientoRequest(vh.tk,vh.emp,vh.estilo,vh.anio)
      if(response.exitoso){
        const result = response.objeto.map(
          item => ({
            codigo: item.codigo,
            descripcion: item.descripcion,
            detalle: item.detalle,
            ver: true
          })
        );
        setLista(result);
        vh.equipamiento=result;
        localStorage.setItem(ConstantesSistema.vehiculoDet,JSON.stringify(vh) );
      }else{
        mostrarError(response.mensajeRespuesta);
      }
    }
    const verPanel= async(e)=>{
  
      const result = lista.map(
        item => item.codigo === e.codigo ?{
          codigo: item.codigo,
          descripcion: item.descripcion,
          detalle: item.detalle,
          ver: !item.ver
        }:item
      );
      setLista(result);
    }
    useEffect(() => {
        CargarEquipamento();
    }, []);
    return (
      <Stack>
        {lista.map((item, index) => {
          return (
            <Stack spacing={6}  _hover={{ background: '#f5f5f5' }}>
              <Stack bg="light" p={6} rounded="md" boxShadow="md"  _hover={{ background: '#FBFBFB' }}>
                <Stack w="100%" borderBottom={'1px solid #8998B3'} p={1} cursor="pointer" direction="row" onClick={()=>verPanel(item)}> 
                  <Icon as={(item.ver)?FaAngleUp:FaAngleDown} fontSize="1.2rem" transition="0.3s" color="#8998B3"/>
                  <Text fontSize="sm" fontWeight="bold" color="bodyTitle" textTransform="uppercase" mb={2}>{item.descripcion}</Text>
                </Stack>
                <Stack hidden={!item.ver} padding={1}>
                  <Box display="grid" gridTemplateColumns="repeat(auto-fit, minmax(15rem, 1fr))" gridGap={4}>
                    {item.detalle.map((deta, indexdeta) => {
                      return (
                        <EquipamientoLabel text={deta.descripcion.toUpperCase()} />
                      );
                    })}
                  </Box>
                </Stack>
              </Stack>
            </Stack>
          )
        })}
      </Stack>
      /*
      <Accordion defaultIndex={[0]} w="100%" allowMultiple>
        {lista.map((item, index) => {
          return (
            <AccordionItem p={3}>
              <Stack spacing={6}>
                <Stack bg="light" p={6} rounded="md" boxShadow="md">
                  <Stack>
                    <AccordionButton py={4} fontWeight="bold" borderBottomWidth={2} borderColor="tertiary">
                      <AccordionIcon />
                      {item.descripcion}
                    </AccordionButton>
                  </Stack>
                  <AccordionPanel>
                    <Box display="grid" gridTemplateColumns="repeat(auto-fit, minmax(15rem, 1fr))" gridGap={4}>
                      {item.detalle.map((deta, indexdeta) => {
                        return (
                          <EquipamientoLabel text={deta.descripcion.toUpperCase()} />
                        );
                      })}
                    </Box>
                  </AccordionPanel>
                </Stack>
              </Stack>
            </AccordionItem>
          );
        })}
      </Accordion>
      */
    );
  };
  
  export const EquipamientoLabel = ({ text }) => {
    return (
      <Stack bg="background" p={2} rounded="md" boxShadow="sm">
        <Stack direction={{ base: 'column', md: 'row' }} justifyContent="space-between">
          <Stack fontWeight="550" fontSize="sm" justifyContent="flex-start" alignItems="center" direction="row">
            <Icon as={MdLabel} w={6} h={6} color="error" mr={2} transform="scaleX(-1)" />
            <Text fontSize="sm">{text}</Text>
          </Stack>
        </Stack>
      </Stack>
    );
  };
  
  export const EquipamientoCheckbox = ({ text }) => {
    return (
      <Stack bg="background" p={4} rounded="md" boxShadow="sm">
        <Stack direction={{ base: 'column', md: 'row' }} justifyContent="space-between">
          <Stack fontWeight="medium" justifyContent="flex-start" alignItems="center" direction="row">
            <CheckboxGroup colorScheme="red">
              <Checkbox>{text}</Checkbox>
            </CheckboxGroup>
          </Stack>
        </Stack>
      </Stack>
    );
  };
  
  export const EquipamientoCheckboxDisabled = ({ text }) => {
    return (
      <Stack bg="background" p={4} rounded="md" boxShadow="sm">
        <Stack direction={{ base: 'column', md: 'row' }} justifyContent="space-between">
          <Stack fontWeight="medium" justifyContent="start" alignItems="center" direction="row">
            <Text color="tertiary">{text}</Text>
          </Stack>
        </Stack>
      </Stack>
    );
  };