import ConstantesSistema from '../../Constants/ConstantesSistema';
import React, { useState, useEffect } from 'react';
import {
    Stack,
    Box,
    Select,
    FormControl,
    FormLabel,
  } from '@chakra-ui/react';

import { ObtenerHistoricoVentasRequest } from '../../request/VehiculosRequest';
import { TableStyled } from '../../styles';
import NumberFormat from "react-number-format";
import { Excel } from './Excel'
import BootstrapTable from 'react-bootstrap-table-next';


export const Ventas = () => {
    let ventas =[];
    const vh =  JSON.parse(localStorage.getItem(ConstantesSistema.vehiculoDet));
    const vEmp = parseInt( localStorage.getItem(ConstantesSistema.empresaSel));
    const vTk =  localStorage.getItem(ConstantesSistema.nombre_Tkn);
   // const [meses,setMeses] = useState([]);
    const [annios,setAnnios] = useState([]);
    const [ventasDeta,setVentasDeta]=  useState([]);
    const [ventasFiltro,setVentasFiltro]=  useState([]);
    const[annio,setAnnio] =useState(ConstantesSistema.todos);
    const[mes,setMes] =useState(ConstantesSistema.todos);
    const[login,setLogin] =useState(false);  
    //FaSortDown,FaSortUp
    const sortingHeaderStyle = {
      backgroundColor: 'red'
    };
    const columns = [
      {
        dataField: 'unidad',
        text: 'Unidad',
        sort: true,
      },
      {
        dataField: 'placa',
        text: 'Placa',
        sort: true,
      },
      {
        dataField: 'sucursal',
        text: 'Sucursal',
        sort: true,
      },
      {
        dataField: 'precio_Venta',
        text: 'Precio de venta',
        sort: true,
        formatter: (cellContent, row) => (
          <div >
            <NumberFormat
              value={row.precio_Venta}
              displayType={'text'} thousandSeparator={true} prefix={'$'}
            />
          </div>
        )
      },
      {
        dataField: 'mes',
        text: 'Mes',
        sort: true,
      },
      {
        dataField: 'anno',
        text: 'Año',
        sort: true,
      },
      {
        dataField: 'dias_Inventario',
        text: 'Días inventario',
        sort: true,
      },
      {
        dataField: 'kilometraje',
        text: 'Kilometraje',
        sort: true,
        formatter: (cellContent, row) => (
          <div >
            <NumberFormat
              value={row.kilometraje}
              displayType={'text'} thousandSeparator={true} 
            />
          </div>
        )
      },
    ];
    
    useEffect(() => {
      const Obtener = async()=>{
        setLogin(true);
        const response = await ObtenerHistoricoVentasRequest(vTk, vEmp, vh.estilo, vh.anio);
        if (response.exitoso) {
          ventas = response.objeto;
          let vMeses =ConstantesSistema.meses;
          let vAnnios=[...new Set(ventas.map(({anno})=>anno))];
          setVentasDeta(response.objeto);
          setVentasFiltro(response.objeto);
          setAnnios(vAnnios.sort(function(a, b){return b-a})); 
          setLogin(false);
        }
        return true;
      }
      Obtener();
    }, []);
    const onAnnioChange = (valor) => {
      setAnnio(valor);
      let filtro = ventasDeta.filter(x => x.anno.toString().toUpperCase().includes(valor.toString().toUpperCase()));
      setVentasFiltro(filtro);
      let vMeses = [...new Set(filtro.map(({ mes }) => mes))];
      setMes(ConstantesSistema.todos);
    }
    const onMesChange = (valor) =>{    
      setMes(valor);
      let filtro = ventasDeta.filter(x=>x.mes.toString().toUpperCase().includes(valor.toString().toUpperCase()));
      if(annio != ConstantesSistema.todos){
        filtro = filtro.filter(x=>x.anno.toString().toUpperCase().includes(annio.toString().toUpperCase()));
      }
      setVentasFiltro(filtro);
    }  
    function priceFormatter(cell, row){
      return '<i class="glyphicon glyphicon-usd"></i> ' + cell;
    }
    return (
      <Stack spacing={6} minH="100vh">
        <Stack
          direction={{ base: 'column-reverse', md: 'row' }}
          justifyContent="space-between"
          alignItems={{ base: 'flex-start', md: 'flex-end' }}
          spacing={6}
        >
          <Stack>
            <Excel nombre={`Ventas_${vh.estilo}`} columnas={columns} data={ventasFiltro} />
          </Stack>
          <Stack spacing={4}>
            <Stack direction={{ base: 'column', md: 'row' }} justifyContent="flex-end" alignContent="center" spacing={6}>
              <Box>
                <FormControl>
                  <FormLabel>Año</FormLabel>
                  <Select
                    placeholder="Todos"
                    variant="filled"
                    bg="light"
                    w="10rem"
                    fontWeight="bold"
                    focusBorderColor="none"
                    boxShadow="sm"
                    _focus={{ bg: 'light' }}
                    _hover={{ bg: 'light', boxShadow: 'md' }}
                    onChange={(event) => onAnnioChange(event.currentTarget.value)}
                  >
                    {annios.map((annio, index) =>
                      <option key={index} value={annio}>
                        {annio}
                      </option>)}
                  </Select>
                </FormControl>
              </Box>
              <Box>
                <FormControl>
                  <FormLabel>Mes</FormLabel>
                  <Select
                    placeholder="Todos"
                    variant="filled"
                    bg="light"
                    w="10rem"
                    fontWeight="bold"
                    focusBorderColor="none"
                    boxShadow="sm"
                    _focus={{ bg: 'light' }}
                    _hover={{ bg: 'light', boxShadow: 'md' }}
                    onChange={(event) => onMesChange(event.currentTarget.value)}
                    value={mes}
                  >
                    {ConstantesSistema.meses.map((mes, index) =>
                      <option key={index} value={mes.des}>
                        {mes.des}
                      </option>)}
                  </Select>
                </FormControl>
              </Box>
            </Stack>
          </Stack>
        </Stack>
        <Stack bg="light" p={1} rounded="md" boxShadow="md">
          <TableStyled>
            <BootstrapTable bootstrap4 keyField="placa,kilometraje" data={ventasFiltro} columns={columns} bordered={false} hover >
            </BootstrapTable>
          </TableStyled>
        </Stack>
      </Stack>
    );
  };