import React, { useState, useEffect } from 'react';
import { Top } from '../../styles';
import { Icon } from '@chakra-ui/react';
import { IoArrowUpCircleSharp } from 'react-icons/io5';

const ScrollToTop = () => {
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', () => {
      const isTop = window.scrollY < 150;

      isTop !== true ? setScrolled(true) : setScrolled(false);
    });
  }, []);

  function handleGoToTop() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }

  return (
    <>
      <Top>
        {scrolled && (
          <button id="goToTop" onClick={() => handleGoToTop()} title="Go to top">
            <Icon as={IoArrowUpCircleSharp} w={12} h={12} color="primary" opacity="0.4" />
          </button>
        )}
      </Top>
    </>
  );
};

export default ScrollToTop;
