export default class ConstantesSistema {

    // opciones de menu
    static opcionesMenu = 'opcionesMenu';
    static opcionesSubMenu = 'opcionesSubMenu';

    static vehiculoSel ='vehiculoSel';
    static iniciaBuscar = 'iniciaBuscar';

    static annioSel = "annioSel";
    static empresaSel = "empresaSel";
    static tipoBusqueda = 'tipoBusqueda';
    static tipoBusCodigo = 1;
    static tipoBusPlaca = 2;
    static vehiculoDet ='vehiculoDet';
    static todos ='Todos';
    static imagen = 'userimage';
    static regresar = 'regresar';
    static listaVh = 'listaVh';
    static menuSel ='menuSeleccionado'
    static empresaLista = 'empresaLista';
    static tamanioMovil = 990;

    // Id de usuario
    static usuario_Id = 'usuario_Id';

    // Nombre del Token
    static nombre_Tkn = 'ld_purdy_book_web_tk'

    // Nombre del Token Purdy Center
    static nombre_TknPC = 'ld_purdy_book_web_pc_tk';

    static username ='username';

    static imagenError='https://e7.pngegg.com/pngimages/505/761/png-clipart-login-computer-icons-avatar-icon-monochrome-black-thumbnail.png'

    // Empresa Default a utilizar
    static empresaDefault = 2;

    static listaComparar = 'listaComparar';
    static iniciaComparar = 'iniciaComparar';
    static paramMaxCantEscritorio = 4;
    static paramMaxCantMovil = 2;

    static comVenta=5;
    static comVentaMax=6;
    static comVentaMin=7;
    static comVentaPro=8;

    static menuCodigo = 1;
    static menuPlaca = 2;
    static menuComparacion = 3;
    static menuSimulador = 4;

    static enviadoComparacion = 'enviadoComparacion';

    static meses = [
        {'id':1,'des':'Enero'},
        {'id':2,'des':'Febrero'},
        {'id':3,'des':'Marzo'},
        {'id':4,'des':'Abril'},
        {'id':5,'des':'Mayo'},
        {'id':6,'des':'Junio'},
        {'id':7,'des':'Julio'},
        {'id':8,'des':'Agosto'},
        {'id':9,'des':'Septiembre'},
        {'id':10,'des':'Octubre'},
        {'id':11,'des':'Noviembre'},
        {'id':12,'des':'Diciembre'}
    ]
    
    static paramMeses = 'MesesMaximoHistoricoVentas';
    static paramAnios = 'AnnoInicialBusquedaVehiculos';
    static paramHVentas = 'ItemDefectoHistoricoPrecio_Venta';
    static paramMsjRes = 'LeyendaVehiculoRestringido';
    static paramMaxCompEscritorio = 'CantidadEstilosCompararEscritorio';
    static paramMaxCompMovil = 'CantidadEstilosCompararCelular ';    
    static paramNoVh = 'LeyendaNoResultadosBusqueda';
    static paramCantExtras ='CantMaxExtrasFiltroBusqueda';
    static paramLeyendaBusquedaVehiculoPR ='LeyendaBusquedaVehiculoPR';
    static paramTiempoMensajes ='DuracionMsgErrorPBSegundos';

    static verImagenes = false;
    static desMenu = {
        envioSalesForce: 'ENVÍO A SALESFORCE',
        filtrarExtras: 'FILTRAR POR EXTRAS',
        cantidadInventario: 'VER CANTIDADES EN INVENTARIO',
        caracGenerales: 'VER CARACTERÍSTICAS GENERALES',
        equipamiento: 'VER EQUIPAMIENTO',
        restringidos: 'VER ESTILOS RESTRINGIDOS',
        precioRecibo: 'VER MONTO PRECIO RECIBO',
        precioSugerido: 'VER MONTO PRECIO SUGERIDO',
        pestanaVenta: 'VER PESTAÑA HISTÓRICOS DE VENTA',
        pestanaRecibo: 'VER PESTAÑA PRECIO RECIBO',
        promediosVenta: 'VER PROMEDIOS DE VENTA',
        valorFiscalContrato: 'VER VALOR FISCAL Y DE CONTRATO',
    }
    //Desa
    static ruta = '/';
    //Purdy
    //static ruta = '/purdybook/';
    static SiteRutas = {
        Login: this.ruta,
        Codigo: `${this.ruta}PBBuscarCodigo`,
        Placa: `${this.ruta}PBBuscarPlaca`,
        Detalle: `${this.ruta}detalle`,
        Simulador: `${this.ruta}PBSimulador`,
        Comparar: `${this.ruta}PBComparacion`,
        Home: `${this.ruta}Home`,
        olvide: `${this.ruta}olvide`
    };
}
