/*
    Para publicación a Purdy, o en alguna subruta: 
    Link de referencia: https://scottvinkle.me/blogs/work/how-to-deploy-a-react-app-to-a-subdirectory
        1) Cambiar la ruta en ConstantesSistema:  static ruta = '/subruta/';
        2) Poner en el atributo homepage del packages.json la ruta del URL donde se publicará. ("homepage": "http://test.wsgpm.net:19005/purdybook/",)

*/


//Url Base
// export const baseUrl = '/';
//Desa
// export const baseUrlApi ='http://vm-purdyweb.oc.ld.co.cr:237/';
// export const baseUrlApiPC ='http://vm-purdyweb.oc.ld.co.cr:233/';
// Desa Local
// export const baseUrlApi ='http://localhost:5000/';
// export const baseUrlApiPC ='http://vm-purdyweb.oc.ld.co.cr:233/';

// Desa Local
// export const baseUrlApi ='http://localhost:5000/';
// export const baseUrlApiPC ='http://vm-purdyweb.oc.ld.co.cr:233/';

//Testing
// export const baseUrlApi ='http://vm-purdyweb.oc.ld.co.cr:234/';
// export const baseUrlApiPC ='http://vm-purdyweb.oc.ld.co.cr:238/'; 

//Purdy (pruebas)
// export const baseUrlApi ='http://test.wsgpm.net:19005/purdybook/api/';
// export const baseUrlApiPC ='http://test.wsgpm.net:18051/';

//Purdy Producción
export const baseUrlApi   = 'http://wsgpm.net:8005/purdybook/api/';
export const baseUrlApiPC = 'http://wspurdy.purdymo.com:8011/';

//Url Routes for Components
export const SiteRutas = {
    Home: '/Home',
    Login: '/Login',
    Parametros: '/Parametros',
    CambiarContrasena: '/CambiarContrasena',
    Comparar :'/Home/PBComparacion'
};

//Request Header
export const GetHeaderRequest = pToken => {
    return {
        'Content-Type': 'application/json', 
        'Authorization': `Bearer ${pToken}`
    }
};
export const GetHeaderRequestFormData = pToken => {
    return {
        
        'Authorization': `Bearer ${pToken}`
    }
};
export const GetHeaderRequestBlobData = pToken => {
    return {
        'Content-Type': 'application/vnd.ms-excel', 
        'Authorization': `Bearer ${pToken}`
    }
};
//Response Body
export const GetResponseBody = pResponse => {
  
    const contentType = pResponse.headers.get("content-type");
    if (contentType && contentType.indexOf("application/json") !== -1) {
        return pResponse.json();
    } else {
        switch (pResponse.status) {
            case 401:
                return { exitoso: false, mensajeRespuesta: 'No tiene permisos para acceder a este método.', codigoError: 3 };
            case 500:
                return { exitoso: false, mensajeRespuesta: 'Ocurrió un error en el servidor.', codigoError: 999 };
            default:
                return { exitoso: false, mensajeRespuesta: 'Ocurrió un error realizando la acción.', codigoError: 999 };
        }
    }
};
