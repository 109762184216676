import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { mostrarError, mostrarExito, mostrarWarning } from '../Util/Notification/Notification'
import {
  Container,
  Stack,
  Box,
  Text,
  Heading,
  Button,
  Checkbox,
  CheckboxGroup,
  useDisclosure,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Select,
  Icon,
  Image,
} from '@chakra-ui/react';
import { BiChevronLeft } from 'react-icons/bi';
import { FaCarAlt,FaTrashAlt } from 'react-icons/fa';
import toyotalogo from '../../assets/images/toyotalogo.png';
import fordlogo from '../../assets/images/fordlogo.png';
import { CompareTableStyled, FilterIconStyled } from '../../styles';
import { VehicleTableStyled } from '../../styles';
import EditarModal from './EditarModal';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import ConstantesSistema from '../../Constants/ConstantesSistema';
import ConstantesMenu from '../../Constants/ConstantesMenu';
import { ObtenerBaseRequest } from '../../request/CompararRequest';
import Loading from '../../components/Util/Loading/Loading';

import {ObtenerParamIdRequest} from '../../request/ParamRequest';
import { ObtenerPromedioVentasRequest,ObtenerListaMeses } from '../../request/VehiculosRequest';
import _, { map } from 'underscore';


export const Vehiculo = ({ descripcion, estilo, logo, anio, empresa, index }) => {
  const [loading, setLoading] = useState(false);
  const eliminarVh = async () => {
    const listaVehiculo = JSON.parse(localStorage.getItem(ConstantesSistema.listaComparar));
    var lista = listaVehiculo.filter(x => x.index != index);
    localStorage.setItem(ConstantesSistema.listaComparar, JSON.stringify(lista));
    localStorage.setItem(ConstantesSistema.iniciaComparar, ConstantesSistema.iniciaComparar);
  }
  return (
    <Stack
      bg="light"
      borderWidth={1}
      borderColor="background"
      transition="0.2s"
      _hover={{ boxShadow: 'xl', transform: 'translateY(-4px)' }}
      minH="100%"
      justifyContent="start"
      spacing={0}
      p={0}
    >
      <Stack spacing={0} w="100%">
        <Box>
          <Button variant="link" pl={2} pt={3}>
            <Icon
              as={FaTrashAlt}
              h={5}
              w={5}
              color="error"
              transition="0.2s"
              _hover={{ transform: 'translateY(-2px)' }}
              _active={{ transform: 'translateY(0)' }}
              onClick={eliminarVh}
            />
          </Button>
        </Box>
        <Stack p={1}>
          <Box color="#F2F1F1" display="flex" justifyContent="center" >
            <Stack hidden={logo != null}>
              <FaCarAlt fontSize="4rem" />
            </Stack>
            <Stack hidden={logo === null}>
              <Image src={logo} alt="Logo" w="4rem" h="3rem" mb={2} />
            </Stack>
          </Box>
          <Box textAlign="center" fontWeight="bold" fontSize="md">
            <Text mb={1} fontSize="xl">
              {descripcion}
            </Text>
            <Text fontWeight="black" fontSize="lg">
              {anio}
            </Text>
          </Box>
        </Stack>
      </Stack>
    </Stack>
  );
};

export const TableCheckbox = ({check,dato}) => {
  return (
    <Box display="grid" alignItems="center" justifyItems="center">
      <CheckboxGroup colorScheme="red">
        <Checkbox  isChecked={check}/>
      </CheckboxGroup>
    </Box>
  );
};

export const VehiculoVacio = () => {
  return <Box borderWidth={1} borderColor="background" minH="100%"></Box>;
};

export const Vehiculos = () => {
  const [vh, setVh] = useState([]);
  const [fields, setfields] = useState([]);
  const [fieldsM, setfieldsM] = useState([]);
  const fields2 = [
    {
      1: <VehiculoVacio />,
      2: <Vehiculo logo={toyotalogo} marca="Terios Bego 4X4 Full 2016" codigo="BE02" />,
      3: <Vehiculo logo={toyotalogo} marca="Toyota Corolla 1.8 VVT-i TA 2018" codigo="BE03" />,
      4: <Vehiculo logo={toyotalogo} marca="RUSH Ta Sport Plus 2019" codigo="BE04" />,
      5: <Vehiculo logo={fordlogo} marca="Ford Edge Cell 2.0L TM 2020" codigo="BE55" />,
    },
  ];

  const columns = [
    {
      dataField: '',
      text: '1',
    },
    {
      dataField: '2',
      text: '2',
    },
    {
      dataField: '3',
      text: '3',
    },
    {
      dataField: '4',
      text: '4',
    },
    {
      dataField: '5',
      text: '5',
    },
  ];
  const InicializaDatos = () => {
    var dato = [];
    var datoMovil=[];
    const CargarDatos = async () => {
      var datos = [];
      var datosMovil=[];
      datos.push(<VehiculoVacio />);
      datos.push(<VehiculoVacio />);
      datosMovil.push(<VehiculoVacio />);
      datosMovil.push(<VehiculoVacio />);
      datosMovil.push(<VehiculoVacio />);
      datosMovil.push(<VehiculoVacio />);
      var vh = JSON.parse(localStorage.getItem(ConstantesSistema.listaComparar));
      if(vh!=null){
        var vMax = 0;
        /*
        if (window.innerWidth < 800) {
          datos.push(<VehiculoVacio />);
          datos.push(<VehiculoVacio />);
        } */
        for (var x = 0; x <= vh.length-1; x++) {
          if (vh[x] != null) {
            datos.push(<Vehiculo descripcion={vh[x].descripcion} anio={vh[x].anio} estilo={vh[x].estilo} empresa={vh[x].empresa} index={vh[x].index} logo={vh[x].imagen} />);
            datosMovil.push(<Vehiculo descripcion={vh[x].descripcion} anio={vh[x].anio} estilo={vh[x].estilo} empresa={vh[x].empresa} index={vh[x].index} logo={vh[x].imagen} />);
          }
        }
        dato.push(datos);
        datoMovil.push(datosMovil);
        setfields(dato);
        setfieldsM(datoMovil);
      }      
    }
    CargarDatos();
  }
  useEffect(() => {
     InicializaDatos();
    const interval = setInterval(() => {
      var inicia = localStorage.getItem(ConstantesSistema.iniciaComparar);
      if (inicia != null) {
        InicializaDatos();
      }
    }, 2000);
    return () => clearInterval(interval);
  }, []);
  return (
    <VehicleTableStyled>
      <Stack display={{ base: 'none', md: 'block' }}>
        <BootstrapTable keyField="id" data={fields} columns={columns} bordered={false}  />
      </Stack>
      <Stack display={{ base: 'block', md: 'none' }}>
        <BootstrapTable keyField="id" data={fieldsM} columns={columns} bordered={false} />
      </Stack>      
    </VehicleTableStyled>
  );
};


const Comparar = () => {
  const [lista, setLista] = useState([]);
  const [listaBase, setListaBase] = useState([]);
  const [listaEnca, setListaEnca] = useState([]);
  const [listaDatos, setListaDatos] = useState([]);
  const [datos, setDatos] = useState([]);
  const [equipamiento, setEquipamiento] = useState([]);
  const [equipamientoIni, setEquipamientoIni] = useState([]);
  const [fields, setfields] = useState([]);
  const [loading, setLoading] = useState(false);
  const [verDiferencias, setVerDiferencias] = useState(false);
  const [mes, setMes] = useState([]);
  const [meses, setMeses] = useState([]);
  const history = useHistory();
  const [verRegresar,setRegresar]=useState(true);
  const columns = [
    {
      dataField: 'tit',
      text: '0'      
    },
    {
      dataField: 'pos1',
      text: '1',
    },
    {
      dataField: 'pos2',
      text: '2',
    },
    {
      dataField: 'pos3',
      text: '3',
    },
    {
      dataField: 'pos4',
      text: '4',
    },
  ];
  const InicializaDatos = async () => {
    //console.clear();
    setLoading(true);
    var regresar =(localStorage.getItem(ConstantesSistema.vehiculoDet));
    if(regresar===null){
      setRegresar(false);
    }
    const CargarBase = async () => {
      const response = await ObtenerBaseRequest(localStorage.getItem(ConstantesSistema.nombre_Tkn));
      if (response.exitoso) {
        setListaBase(response.objeto);
        let enca = response.objeto.filter(x => x.padre === 0);
        setListaEnca(enca);
        await CargarDatos(enca, response.objeto)
      }
      else {
        mostrarError(response.mensajeRespuesta);
      }
    }
    const CargarDatos = async (enca, base) => {
      const vh = JSON.parse(localStorage.getItem(ConstantesSistema.listaComparar));
      setLista(vh);
      if (vh != null) {
        await crearDatos(enca, base);
        setLoading(false);
      }
    }
    CargarBase();
    setLoading(false);
  }
  const ObtenerParam = async()=>{
    const vParam = await ObtenerParamIdRequest(localStorage.getItem(ConstantesSistema.nombre_Tkn),ConstantesSistema.paramHVentas);
    if (vParam.exitoso) {
      setMes(parseInt(vParam.objeto.valor));
      localStorage.setItem(ConstantesSistema.paramMeses, parseInt(vParam.objeto.valor));
    }   
  }
  const CargarMeses=async()=>{
    const vMeses = await ObtenerListaMeses(localStorage.getItem(ConstantesSistema.nombre_Tkn));
    if (vMeses.exitoso) {
      setMeses(vMeses.objeto);
    }
    else {
      mostrarError('Lista Meses: ' + vMeses.mensajeRespuesta);
    }
  }
  useEffect(() => {
    InicializaDatos();
    ObtenerParam();
    CargarMeses();

    const interval = setInterval(() => {
      var inicia = localStorage.getItem(ConstantesSistema.iniciaComparar);
      if (inicia != null) {
        localStorage.removeItem(ConstantesSistema.iniciaComparar)
        InicializaDatos();
        // ObtenerParam();
        // CargarMeses();
      }
    }, 2000);
    return () => clearInterval(interval);

  }, []);
  const validarPermisos = async(pPermisos,pId)=>{
    switch (parseInt(pId)) {
      case ConstantesMenu.PBComparacion_VerCaracteristicasGenerales.cod_seguridad:
      case ConstantesMenu.PBComparacion_VerCantidadesenInventario.cod_seguridad:
      case ConstantesMenu.PBComparacion_VerMontoPrecioRecibo.cod_seguridad:
      case ConstantesMenu.PBComparacion_VerMontoPrecioSugerido.cod_seguridad:
      case ConstantesMenu.PBComparacion_VerPromediosdeVenta.cod_seguridad:
      case ConstantesMenu.PBComparacion_VerEquipamiento.cod_seguridad:
        {
          if(ConstantesMenu.validar){
            var x =pPermisos.filter(x=>parseInt(x.opcion_Id)===parseInt(pId));
            return(x.length>0)
          }else{
            return true
          }         
          break;
        }
      default:
        return true;
        break;
    }
  }
  const crearDatos = async (plistaEnca, pListabase) => {
    var vDatos = [];
    const listaVehiculo = JSON.parse(localStorage.getItem(ConstantesSistema.listaComparar));
    if(ConstantesMenu.validar){
      var subMenu = JSON.parse(localStorage.getItem(ConstantesSistema.opcionesSubMenu));
      subMenu=subMenu.filter(x=>x.menu_Id===ConstantesSistema.menuComparacion);
      subMenu=subMenu.filter(x=>x.opcion_Id!=3);
       if(subMenu.filter(x=>parseInt(x.opcion_Id)===ConstantesMenu.PBComparacion_VerCaracteristicasGenerales.cod_seguridad).length===0){
         plistaEnca=plistaEnca.filter(x=>x.id!=ConstantesMenu.PBComparacion_VerCaracteristicasGenerales.cod_seguridad)
       }
    }
    for (var i = 0; i <= plistaEnca.length; i++) {
      if (plistaEnca[i] != null) {
        var vEnca = {
          enca: plistaEnca[i],
          deta: []
        };
        var titulos = pListabase.filter(x => x.padre === plistaEnca[i].id);
        for (var r = 0; r <= titulos.length; r++) {
          var item = titulos[r];
          var caract = [];
          if (item != null) {
            var acceso = await validarPermisos(subMenu,item.id);
            if(acceso){              
              caract.push(item.id);
              for (var v = 0; v <= listaVehiculo.length; v++) {
                var vh = listaVehiculo[v];             
                if (vh != null) {
                  var vr = vh.datos.filter(x => x.id === item.id);
                  if(item.tipo===2){
                    var vCheck =((parseInt(vr[0].valor)===1)?true:false);
                    vr[0].valor=<TableCheckbox check={vCheck} dato={(parseInt(vr[0].valor))} name={vCheck} />        
                  }  
                  caract.push(vr[0]);                
                }
              }     
              var v1 =(caract[1] != null) ? caract[1].valor : '';
              var v2 =(caract[2] != null) ? caract[2].valor : '';
              var v3 =(caract[3] != null) ? caract[3].valor : '';
              var v4 =(caract[4] != null) ? caract[4].valor : '';
              var x = {
                tit: item.etiqueta,
                pos1: v1,
                pos2: v2,
                pos3: v3,
                pos4: v4,
                tipo: item.tipo,
                dat1: (item.tipo===2 && v1 !='')?v1.props.dato:v1,
                dat2: (item.tipo===2 && v2 !='')?v2.props.dato:v2,
                dat3: (item.tipo===2 && v3 !='')?v3.props.dato:v3,
                dat4: (item.tipo===2 && v4 !='')?v4.props.dato:v4,
                item:item
              }
              vEnca.deta.push(x);
            }            
          }
        }
        vDatos.push(vEnca)
      }
    }
    setDatos(vDatos);
    setListaDatos(vDatos);
    await CargarEquipamiento(vDatos);
  }
  const CargarEquipamiento = async (pDatos) => {
    const vh = JSON.parse(localStorage.getItem(ConstantesSistema.listaComparar));
    var caract = [];
    var equipo = [];
    var titulo = [];
    var detalle =[];
    var equipamiento = [];
    for (let index = 0; index <= vh.length - 1; index++) {
      var v = vh[index];
      if (v.equipamiento != null) {
        var vC = v.equipamiento.map(
          item => ({
            cod_Seguridad: item.codigo,
            etiqueta: item.descripcion,
            codigo: item.codigo,
            detalle: item.detalle
          })
        );
        caract.push(...vC);
      }
    }
    const unique = findUnique(caract, d => d.etiqueta);
    for (let index = 0; index <= unique.length - 1; index++) {
      var deta =[];
      var listaDeta=[];
      var x = {
        etiqueta: unique[index].etiqueta,
        cod_Seguridad: unique[index].cod_Seguridad,
        id: parseInt(unique[index].cod_Seguridad),
        orden: parseInt(unique[index].cod_Seguridad),
        padre: 0,
        tipo: 0
      }
      var vDeta = caract.filter(x => x.codigo === unique[index].cod_Seguridad);          
      for (let i = 0; i <= vDeta.length - 1; i++) {     
        var vl = vDeta[i].detalle;
        deta.push(...vDeta[i].detalle);
      }
      const listaCaract = findUnique(deta, d => d.descripcion);
      for(let i=0;i<=listaCaract.length-1;i++){        
        var nom =listaCaract[i].descripcion.toLowerCase();
        var nom2= nom.charAt(0).toUpperCase() + nom.slice(1);
        var reg = {
          tit:nom2,
          dat1: "",
          dat2: "",
          dat3: "",
          dat4: "",
          pos1: "",
          pos2: "",
          pos3: "",
          pos4: "",
          tipo: 2,
          dif:0
        }
        
        for (let v = 0; v <= vh.length - 1; v++) {
          if(vh[v].equipamiento != null){
            var datos = vh[v].equipamiento.filter(x=>x.codigo===unique[index].codigo);   
            var chk=<TableCheckbox check={false} dato={0} name={vh[v].estilo} />
            var dato = 0;
            if(datos.length>0){
              var equi = datos[0].detalle.filter(x=>x.codigo===listaCaract[i].codigo);  
              dato = equi.length;          
              if(equi.length>0){
                chk=<TableCheckbox check={true} dato={1} name={vh[v].estilo} />
              }                 
            }
            switch (parseInt(v)){
              case 0:
                reg.dat1 = dato;
                reg.pos1 =  chk;
                break;
              case 1:
                reg.dat2 = dato;
                reg.pos2 = chk;
                break;
              case 2:
                reg.dat3 = dato;
                reg.pos3 = chk;
                break;
              case 3:
                reg.dat4 = dato;
                reg.pos4 = chk;
                break;
            }  
          }               
        }         
        
        if(reg.dat1 != ((reg.dat2 === "") ? reg.dat1 : reg.dat2)
        || reg.dat2 != ((reg.dat3 === "") ? reg.dat2 : reg.dat3)
        || reg.dat3 != ((reg.dat4 === "") ? reg.dat3 : reg.dat4)){
          reg.dif = 1;
        }
        listaDeta.push(reg)
      }
      var vEnca = {
        enca: x,
        deta: listaDeta
      };
      equipo.push(vEnca)
    }
    await setEquipamiento(equipo);
    await setEquipamientoIni(equipo);
  }
  function findUnique(arr, predicate) {
    var found = {};
    arr.forEach(d => {
      found[predicate(d)] = d;
    });
    return Object.keys(found).map(key => found[key]); 
  }
  const MostrarDiferencias = async () => {
    if (!verDiferencias) {
      let vDatos = datos;
      var vArray = [];
      for (var e = 0; e <= datos.length - 1; e++) {
        var vEnca = vDatos[e];
        var enca = {
          enca: [],
          deta: []
        };
        for (var d = 0; d <= vEnca.deta.length - 1; d++) {
          var vD = vEnca.deta[d];
          if (
            vD.dat1 != ((vD.dat2 === "") ? vD.dat1 : vD.dat2)
            || vD.dat2 != ((vD.dat3 === "") ? vD.dat2 : vD.dat3)
            || vD.dat3 != ((vD.dat4 === "") ? vD.dat3 : vD.dat4)
          ) {
            enca.deta.push(vD);
          }
        }
        if (enca.deta.length > 0) {
          enca.enca = vEnca.enca;
          vArray.push(enca)
        }
      }
      setDatos(vArray);
      var equipo = [];
      for (var e = 0; e <= equipamientoIni.length - 1; e++) {
        var vEnca = equipamientoIni[e];
        var enca = {
          enca: [],
          deta: []
        };
        for (var d = 0; d <= vEnca.deta.length - 1; d++) {
          var vD = vEnca.deta[d];
          if(parseInt(vD.dif)===1){
            enca.deta.push(vD);
          }          
        }
        if (enca.deta.length > 0) {
          enca.enca = vEnca.enca;
          equipo.push(enca)
        }
      }
      await setEquipamiento(equipo);
    } else {
      setDatos(listaDatos);
      await setEquipamiento(equipamientoIni);
    }
    setVerDiferencias(!verDiferencias);
  }
  const onMesChange = async (valor) => {
    var datos=[];
    var vh = JSON.parse(localStorage.getItem(ConstantesSistema.listaComparar));
    var vS='';
    var vR='';
    localStorage.setItem(ConstantesSistema.paramMeses, parseInt(valor));
    setLoading(true);
    setMes(parseInt(valor));
    if (vh != null) {
      if(vh.length>0){
        var tk = localStorage.getItem(ConstantesSistema.nombre_Tkn);     
        for (let index = 0; index <= vh.length - 1; index++) {
          var v = vh[index];
          const contents = await ObtenerPromedioVentasRequest(tk, v.empresa, v.estilo, parseInt(v.anio), parseInt(valor));
          v.venta_max = contents.objeto.venta_Max.toLocaleString();
          v.venta_min = contents.objeto.venta_Min.toLocaleString();
          v.venta_Pro = contents.objeto.venta_Promedio.toLocaleString();
          for (let d = 0; d <= v.datos.length - 1; d++) {
            if(parseInt(v.datos[d].id)===ConstantesSistema.comVenta && vS==='' && vR===''){
              var vNum = v.datos[d].valor.toString();
              if(vNum.indexOf('.')>0){
                vS ='.';
                vR =',';
              }
              if(vNum.indexOf(',')>0){
                vS =',';
                vR ='.';
              }
            }
            switch (parseInt(v.datos[d].id)) {
              case ConstantesSistema.comVentaMax:
                v.datos[d].valor = '$' + contents.objeto.venta_Max.toLocaleString().replace(vR,vS);
                break;
              case ConstantesSistema.comVentaMin:
                v.datos[d].valor = '$' + contents.objeto.venta_Min.toLocaleString().replace(vR,vS);
                break;
              case ConstantesSistema.comVentaPro:
                v.datos[d].valor = '$' + contents.objeto.venta_Promedio.toLocaleString().replace(vR,vS);
                break;
            }
          }
          datos.push(v);
        };
      }   
      await localStorage.setItem(ConstantesSistema.listaComparar, JSON.stringify(datos));
      await InicializaDatos();
      await setVerDiferencias(false);
    }
    setLoading(false);
  }
  const onRegresar = () =>{   
    const vehiculo = JSON.parse(localStorage.getItem(ConstantesSistema.vehiculoDet));
    if (vehiculo===null) {
      history.push(ConstantesSistema.SiteRutas.Home);
    }else{
      localStorage.removeItem(ConstantesSistema.listaComparar);   
      history.push(ConstantesSistema.SiteRutas.Detalle);
    }   
  }
  return (
    <Stack spacing={0}>
      <Container maxW={{ base: '100%', md: '100%' }} pt={10} bg={{ base: 'none', md: 'light' }} px={0}>
        <Stack spacing={8}>
          <Stack direction={{ base: 'column', md: 'row' }} justifyContent="space-between" px={{ base: '0', md: '5%' }}>
            <Stack direction="row" alignItems="center">
              <Button
                variant="link"
                mr={2}
                p={1}
                alignSelf="flex-start"
                color="body"
                _hover={{ color: 'primaryHover' }}
                onClick={onRegresar}
                hidden={!verRegresar}
              >
                <BiChevronLeft fontSize="2rem" />
              </Button>
              <Stack
                direction={{ base: 'column-reverse', md: 'column' }}
                fontWeight="bold"
                fontSize={{ base: 'md', md: 'xl' }}
                spacing={0}
              >
                <Heading fontSize="xl">Comparación de Vehículos</Heading>
              </Stack>
            </Stack>
            <Stack display={{ base: 'none', md: 'block' }} hidden={true}>
              <Button variant="solid" color="light" bg="primary" rounded="md" w={40} _hover={{ bg: 'primaryHover' }}>
                <Text as="span" mr={4}>
                  Filtrar por
                </Text>
                <FilterIconStyled />
              </Button>
            </Stack>
          </Stack>
          <Stack
            direction={{ base: 'column', md: 'row' }}
            justifyContent="flex-end"
            alignItems="center"
            px={{ base: '0', md: '5%' }}
            spacing={4}
          >
            <Stack hidden={!loading}>
              <Loading color="#D8222B" texto="Cargando" tam={30} />
            </Stack>
            <EditarModal text="Editar comparación" />
            <Select
              placeholder=""
              variant="outline"
              bg="light"
              focusBorderColor="#D9D7D7"
              borderColor="#D9D7D7"
              _focus={{ bg: 'light' }}
              boxShadow="sm"
              w={{ base: '100%', md: '15%' }}
              display={{ base: 'none', md: 'block' }}
              value = {mes}
              onChange={(event) => onMesChange(event.currentTarget.value)}
            >
               {meses.map((mes, index) =>
              <option key={index} value={mes.pB_MesesId}>
                {mes.pB_MesesDescripcion}
              </option>)}
            </Select>
            <Button
              variant="solid"
              color="tertiary"
              bg="background"
              rounded="md"
              w={40}
              _hover={{ bg: 'background' }}
              borderWidth={1}
              borderColor="tertiary"
              display={{ base: 'none', md: 'block' }}
              onClick={MostrarDiferencias}
            >
              {(!verDiferencias)?'Diferencias':'Todo'}
            </Button>
          </Stack>
          <Stack direction={{ base: 'row', md: 'row-reverse' }} spacing={0} overflow={{ base: 'auto', md: 'auto' }}>
            <Vehiculos />
          </Stack>
        </Stack>
      </Container>
      <Stack direction={{ base: 'column', md: 'row' }} spacing={0} paddingBottom={10}>
        <Accordion defaultIndex={[0]} allowMultiple>
          {datos.map((item, index) => {
            return (
              <AccordionItem >
                <Stack bg="light" rounded="md" cursor="pointer" transition="0.3s" boxShadow="sm" spacing={0}>
                  <Stack>
                    <AccordionButton bg="background" py={4} _hover={{ background: 'background' }} fontWeight="bold">
                      <Text paddingLeft={{ base: '2', md: '55' }}>{item.enca.etiqueta}</Text>                      
                      <AccordionIcon />
                    </AccordionButton>
                  </Stack>
                  <AccordionPanel p={0}  >
                    <Stack>
                      <CompareTableStyled>
                        <BootstrapTable keyField="id" data={item.deta} columns={columns} bordered={false} hover />
                      </CompareTableStyled>
                    </Stack>                    
                  </AccordionPanel>
                </Stack>
              </AccordionItem>
            );
          })}

          {equipamiento.map((item, index) => {
            return (
              <AccordionItem >
                <Stack bg="light" rounded="md" cursor="pointer" transition="0.3s" boxShadow="sm" spacing={0}>
                  <Stack>
                    <AccordionButton bg="background" py={4} _hover={{ background: 'background' }} fontWeight="bold">
                      <Text paddingLeft={{ base: '2', md: '55' }}>{item.enca.etiqueta}</Text>
                      <AccordionIcon />
                    </AccordionButton>
                  </Stack>
                  <AccordionPanel p={0}  >
                    <CompareTableStyled>
                      <BootstrapTable keyField="id" data={item.deta} columns={columns} bordered={false} hover />
                    </CompareTableStyled>
                  </AccordionPanel>
                </Stack>
              </AccordionItem>
            );
          })}
        </Accordion>
      </Stack>
    </Stack>
  );
};

export default Comparar;

//export default connect(mapStateToProps)(withRouter(Comparar));

