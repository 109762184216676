import {
    Stack,
    Box,
    Text,
  } from '@chakra-ui/react';

import { GiCarWheel, GiAbstract051 } from 'react-icons/gi';
import { TiFlowSwitch } from 'react-icons/ti';
import { RiGasStationFill } from 'react-icons/ri';

export const Especificaciones = ({ transmision, cilindraje, combustible, traccion }) => {
    return (
      <Stack justifyContent="center" py={{ base: '8', md: '0' }}>
        <Box
          display="grid"
          gridTemplateColumns={{
            base: '1fr',
            md: 'repeat(2, 1fr)',
          }}
          gridGap={8}
        >
          <Stack direction="row">
            <Box color="light" bg="error" p={1} rounded="full">
              <TiFlowSwitch fontSize="1.2rem" />
            </Box>
            <Text fontWeight="bold" color="bodyTitle">
              Transmisión: {transmision}
            </Text>
          </Stack>
          <Stack direction="row">
            <Box color="light" bg="error" p={1} rounded="full">
              <GiAbstract051 fontSize="1.2rem" />
            </Box>
            <Text fontWeight="bold" color="bodyTitle">
              Cilindraje: {cilindraje}
            </Text>
          </Stack>
          <Stack direction="row">
            <Box color="light" bg="error" p={1} rounded="full">
              <RiGasStationFill fontSize="1.2rem" />
            </Box>
            <Text fontWeight="bold" color="bodyTitle">
              Combustible: {combustible}
            </Text>
          </Stack>
          <Stack direction="row">
            <Box color="light" bg="error" p={1} rounded="full">
              <GiCarWheel fontSize="1.2rem" />
            </Box>
            <Text fontWeight="bold" color="bodyTitle">
              Tracción: {traccion}
            </Text>
          </Stack>
        </Box>
      </Stack>
    );
  };