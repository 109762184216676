import { keyframes } from '@chakra-ui/react';

export const moveInUp = keyframes`
  0% {
        opacity: 0;
        transform: translateY(50px)
    }

    100% {
        opacity: 1;
        transform: translateY(0)
    }
`;

export const moveInDown = keyframes`
  0% {
        opacity: 0;
        transform: scale(0.8) translateY(-50px);
    }

    100% {
        opacity: 1;
        transform: scale(1) translateY(0);
    }
`;
