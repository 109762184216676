import React, { useState, useEffect } from 'react';
import {
  Stack,
  Text,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Input,
  Icon,
  Select,
} from '@chakra-ui/react';
import { BiChevronDown } from 'react-icons/bi';
import { MdLabel } from 'react-icons/md';
import { ObtenerEstadosRequest, ObtenerPrecioAvaluoRequest } from '../../request/VehiculosRequest'
import NumberFormat from "react-number-format";
import ConstantesSistema from '../../Constants/ConstantesSistema';
import { mostrarError, mostrarExito, mostrarWarning }from '../Util/Notification/Notification';
import Loading from '../../components/Util/Loading/Loading';


export const PrecioRecibo = (pNuevaBusqueda) => {
    const [estado, setEstado] = useState([]);
    const [datos, setDatos] = useState({ kilometraje: '', mecanica: '', carroceria: '', taller: 0, estado: 0, estadoDes: '', tallerDes: '' });
    const [loading, setLoading] = useState(false);
    const [avaluo, setAvaluo] = useState(0);
    
    useEffect(() => {
      const ObtenerDatos = async () => {
        const vDatos = await ObtenerEstadosRequest(localStorage.getItem(ConstantesSistema.nombre_Tkn));
        if (vDatos.exitoso) {
          setEstado(vDatos.objeto.sort());
        }
        else {
          mostrarError(vDatos.mensajeRespuesta);
        }
      }
      ObtenerDatos();
    }, []);

    useEffect(() => {

      if (pNuevaBusqueda)
      {
        setDatos({...datos, kilometraje: '', mecanica: '', carroceria: '', taller: 0, estado: 0, estadoDes: '', tallerDes: ''});
        setAvaluo(0);
      }

    }, [pNuevaBusqueda]);

    const onSelectCmb = async (pVal, pName, pDes, pNameDes) => {
      const x = await setDatos({ ...datos, [pName]: pVal, [pNameDes]: pDes });
      let vEstado = (pName==='estado')?pVal:datos.estado;
      let vTaller = (pName==='taller')?pVal:datos.taller;
      ObtenerPrecio(vEstado,vTaller);
    }
    const onKeyDownTexto = (event) => {
      if (event.key === 'Enter' || event.key === 'Tab') {
        ObtenerPrecio(datos.estado,datos.taller);
      }
    }
    const onChangeText = (event) => {
      let { name, value } = event.currentTarget;
      let xVal = value;
      var filtro = '1234567890'
      let char = xVal.charAt(xVal.length - 1);
      let pos = filtro.indexOf(char);
      if (pos < 0) {
        xVal = xVal.replace(char, '');
      }
      setDatos({ ...datos, [name]: xVal });
  
    }
    const ObtenerPrecio = async (pEstado,pTaller) => {
      if (datos.kilometraje != '' && datos.mecanica != '' && datos.carroceria != '' && datos.estado != 0) {
        var vH = JSON.parse(localStorage.getItem(ConstantesSistema.vehiculoDet));
        var vObj = {
          "estadoVehiculo": parseInt(pEstado),
          "kilometraje": parseInt(datos.kilometraje),
          "historialTaller": pTaller,
          "reparacionesMecanicas": parseInt(datos.mecanica),
          "reparacionesCarroceria": parseInt(datos.carroceria),
          "precioRecibo": parseInt(vH.precio_Recibo),
          "empresaId": parseInt(vH.empresaId),
          "estilo": vH.estilo,
          "anno": parseInt(vH.anio)
        }
        setLoading(true);
        const vDatos = await ObtenerPrecioAvaluoRequest(localStorage.getItem(ConstantesSistema.nombre_Tkn), vObj);
        if (vDatos.exitoso) {
          setAvaluo(vDatos.objeto)
        }
        else {
          mostrarError(vDatos.mensajeRespuesta);
        }
        setLoading(false);
      }
    }
    return (
      <Stack p={{ base: '4', md: '6' }}>
        <Stack spacing={6}>
          <Stack bg="light" p={6} rounded="md" boxShadow="md">
            <Stack
              direction="row"
              justifyContent="space-between"
              textTransform="uppercase"
              color="tertiary"
              fontWeight="bold"
              px={8}
              mb={6}
              display={{ base: 'none', md: 'flex' }}
            >
              <Text>Variable de recibo</Text>
              <Text>Estado según avalúo</Text>
            </Stack>
            <Stack spacing={4}>
              {/* Item */}
              <Stack bg="background" p={3} rounded="md" boxShadow="sm">
                <Stack direction={{ base: 'column', md: 'row' }} justifyContent="space-between">
                  <Stack fontWeight="bold" justifyContent="flex-start" alignItems="center" direction="row">
                    <Icon as={MdLabel} w={7} h={7} color="error" mr={4} transform="scaleX(-1)" />
                    <Text>Estado de Vehículo</Text>
                  </Stack>
                  <Menu>
                    <MenuButton
                      variant="filled"
                      bg="light"
                      as={Button}
                      boxShadow="sm"
                      color="#000000"
                      borderBottom="1px solid #E4E4E3"
                      rounded="none"
                      fontSize="md"
                      w={{ base: '100%', md: '25%' }}
                      fontWeight="regular"
                      rightIcon={<BiChevronDown />}
                    >
                      <Text align="right">{datos.estadoDes}</Text>
                    </MenuButton>
                    <MenuList overflowY="auto" maxH="10rem" p={1} minWidth="max-content">
                      {estado.map((item, index) => {
                        return (
                          <MenuItem rounded="full" fontWeight="bold" w="max-content" key={index} onClick={() => { onSelectCmb(item.codigo, 'estado', item.nombre, 'estadoDes') }}>
                            {item.nombre}
                          </MenuItem>
                        );
                      })}
                    </MenuList>
                  </Menu>
                </Stack>
              </Stack>
              {/* Item */}
              <Stack bg="background" p={3} rounded="md" boxShadow="sm">
                <Stack direction={{ base: 'column', md: 'row' }} justifyContent="space-between">
                  <Stack fontWeight="bold" justifyContent="flex-start" alignItems="center" direction="row">
                    <Icon as={MdLabel} w={7} h={7} color="error" mr={4} transform="scaleX(-1)" />
                    <Text>Kilometraje</Text>
                  </Stack>
                  <Input
                    placeholder=""
                    variant="outline"
                    bg="light"
                    focusBorderColor="#D9D7D7"
                    borderColor="#D9D7D7"
                    _focus={{ bg: 'light' }}
                    boxShadow="sm"
                    w={{ base: '100%', md: '25%' }}
                    textAlign="right"
                    onChange={onChangeText}
                    onKeyDown={onKeyDownTexto}
                    name="kilometraje"
                    maxLength="10"
                    value={datos.kilometraje}
                  />
                </Stack>
              </Stack>
              {/* Item */}
              <Stack bg="background" p={3} rounded="md" boxShadow="sm">
                <Stack direction={{ base: 'column', md: 'row' }} justifyContent="space-between">
                  <Stack fontWeight="bold" justifyContent="flex-start" alignItems="center" direction="row">
                    <Icon as={MdLabel} w={7} h={7} color="error" mr={4} transform="scaleX(-1)" />
                    <Text>Historial del taller</Text>
                  </Stack>
                  <Select
                    hidden={true}
                    placeholder=""
                    variant="outline"
                    bg="light"
                    focusBorderColor="#D9D7D7"
                    borderColor="#D9D7D7"
                    _focus={{ bg: 'light' }}
                    boxShadow="sm"
                    w={{ base: '100%', md: '25%' }}
                    sx={{ direction: 'rtl' }}
                    icon={false}
                    onChange={(event) => { onSelectCmb(event.currentTarget.value, 'taller') }}
                  >
                    <option value={1}>Si</option>
                    <option value={0}>No</option>
                  </Select>
                  <Menu>
                    <MenuButton
                      variant="filled"
                      bg="light"
                      as={Button}
                      boxShadow="sm"
                      color="#000000"
                      borderBottom="1px solid #E4E4E3"
                      rounded="none"
                      fontSize="md"
                      w={{ base: '100%', md: '25%' }}
                      fontWeight="regular"
                      rightIcon={<BiChevronDown />}
                    >
                      <Text align="right">{datos.tallerDes}</Text>
                    </MenuButton>
                    <MenuList overflowY="auto" maxH="10rem" p={1} minWidth="max-content">
                      <MenuItem rounded="full" fontWeight="bold" w="max-content" key={0} onClick={() => { onSelectCmb(1, 'taller', 'SI', 'tallerDes') }}>
                        SI
                      </MenuItem>
                      <MenuItem rounded="full" fontWeight="bold" w="max-content" key={1} onClick={() => { onSelectCmb(0, 'taller', 'NO', 'tallerDes') }}>
                        NO
                      </MenuItem>
                    </MenuList>
                  </Menu>
                </Stack>
              </Stack>
              {/* Item */}
              <Stack bg="background" p={3} rounded="md" boxShadow="sm">
                <Stack direction={{ base: 'column', md: 'row' }} justifyContent="space-between">
                  <Stack fontWeight="bold" justifyContent="flex-start" alignItems="center" direction="row">
                    <Icon as={MdLabel} w={7} h={7} color="error" mr={4} transform="scaleX(-1)" />
                    <Text>Reparaciones Mecánicas ($)</Text>
                  </Stack>
                  <Input
                    variant="outline"
                    bg="light"
                    focusBorderColor="#D9D7D7"
                    borderColor="#D9D7D7"
                    _focus={{ bg: 'light' }}
                    boxShadow="sm"
                    w={{ base: '100%', md: '25%' }}
                    textAlign="right"
                    onChange={onChangeText}
                    onKeyDown={onKeyDownTexto}
                    name="mecanica"
                    placeholder="$"
                    value={datos.mecanica}
                  />
                </Stack>
              </Stack>
              {/* Item */}
              <Stack bg="background" p={3} rounded="md" boxShadow="sm">
                <Stack direction={{ base: 'column', md: 'row' }} justifyContent="space-between">
                  <Stack fontWeight="bold" justifyContent="flex-start" alignItems="center" direction="row">
                    <Icon as={MdLabel} w={7} h={7} color="error" mr={4} transform="scaleX(-1)" />
                    <Text>Reparaciones de Carrocería ($)</Text>
                  </Stack>
                  <Input
                    variant="outline"
                    bg="light"
                    focusBorderColor="#D9D7D7"
                    borderColor="#D9D7D7"
                    _focus={{ bg: 'light' }}
                    boxShadow="sm"
                    w={{ base: '100%', md: '25%' }}
                    textAlign="right"
                    onChange={onChangeText}
                    onKeyDown={onKeyDownTexto}
                    name="carroceria"
                    placeholder="$"
                    value={datos.carroceria}
                  />
                </Stack>
              </Stack>
              {/* Item */}
              <Stack bg={{ base: 'light', md: 'background' }} p={3} rounded="md" boxShadow="sm">
                <Stack direction={{ base: 'row', md: 'row' }} justifyContent="space-between">
                  <Stack fontWeight="bold" justifyContent="flex-start" alignItems="center" direction="row">
                    <Text textTransform="uppercase" fontSize={{ base: 'xs', md: 'md' }}>
                      Precio de Recibo
                    </Text>
                  </Stack>
                  <Text color="error" fontWeight="bold" hidden={loading}>
                    <NumberFormat
                      value={avaluo}
                      displayType={'text'} thousandSeparator={true} prefix={'$'}
                    />
                  </Text>
                  <Stack hidden={!loading} >
                    <Loading color="#D8222B" texto="Calculando" tam={30} />
                  </Stack>
                </Stack>
              </Stack>
              {/* Item */}
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    );
  };