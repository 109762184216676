import styled from '@emotion/styled';
import { NavLink } from 'react-router-dom';
import { Link } from 'react-router-dom';

// CUSTOM COMPONENTS

export const NavLinkStyled = styled(NavLink)`
  &:hover {
    text-decoration: none !important;
    color: ${(props) => props.theme.colors.light};
  }
`;

export const LinkStyled = styled(Link)`
  &:hover {
    text-decoration: none !important;
    color: ${(props) => props.theme.colors.light};
  }
`;

export const Top = styled.div`
  button {
    position: fixed;
    bottom: 30px;
    right: 30px;
    z-index: 9999;
    cursor: pointer;
    transition: 0.2s;
  }

  button:hover {
    transition: 0.3s;
    bottom: 25px;
  }
`;

export const TableStyled = styled.div`
  overflow: auto;

  table {
    border: none;
    table-layout: fixed;
  }
  th {
    font-weight: bold;
    border-top: none;
    border-bottom: ${(props) => props.theme.colors.tertiary};
    white-space: nowrap;
    text-align: center;
  }
  td {
    border: none;
    text-align: center;
    white-space: nowrap;
  }
  @media only screen and (max-width: 1000px) {
  }
  table {
    table-layout: auto;
  }
`;

export const VehicleTableStyled = styled.div`
  overflow: auto;
  table {
    border: none;
    table-layout: fixed;
    padding: 0;
    margin: 0;
  }
  thead {
    display: none;
  }

  tbody tr {
    min-height: 100%;
  }
  tbody tr td {
    height: 1rem;
    border: none;
    margin: 0;
    padding: 0;
  }
  @media only screen and (max-width: 1000px) {
    table {
      table-layout: fixed;
    }

    tbody tr td:nth-child(1),
    tbody tr td:nth-child(2),
    tbody tr td:nth-child(3) {
      display: none;
    }
  }
`;

export const CompareTableStyled = styled.div`
  overflow: auto;
  table {
    border: none;
    table-layout: fixed;
    padding: 0;
    margin: 0;
  }
  thead {
    display: none;
  }

  tbody tr td {
    font-weight: bold;
    text-align: center;
    border: none;
    padding-top: 1rem;
    padding-bottom: 1rem;
    font-weight: 800;
  }

  tbody tr td:first-of-type {
    background-color: ${(props) => props.theme.colors.background};
    text-align: left;
    font-weight: 400;
    padding-left: 75px;
  }

  @media only screen and (max-width: 1000px) {
    table {
      text-align: center;
    }

    tbody tr {
      display: flex;
      flex-wrap: wrap;
      /* display: grid;
      grid-template-columns: repeat(auto-fill, minmax(25%, 1fr)); */
      overflow: auto;
    }

    tbody tr td:first-of-type {
      flex: 1 0 100%;
      grid-column: 1/-1;
      background-color: ${(props) => props.theme.colors.backgroundLight};
      text-align: center;
      font-weight: 800;
      padding-left: 0px;
    }

    tbody tr td:nth-child(4),
    tbody tr td:nth-child(5) {
      display: none;
    }

    tbody tr td {
      display: grid;
      flex: 2 0 50%;
      font-weight: 400;
    }
  }
`;

export const SelectRTL = styled.select`
  direction: rtl;
  background: white;
  border: 1px solid ${(props) => props.theme.colors.background};
  width: 100%;
  height: 2.5rem;
  border-radius: 4px;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  @media only screen and (min-width: 768px) {
    width: 24%;
  }
`;

// CUSTOM ICONS

const FilterIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="1.2rem" viewBox="0 0 16.481 18.541">
      <path
        fill="white"
        d="M12.165 13.349a3.189 3.189 0 113.188 3.132 3.161 3.161 0 01-3.188-3.132zM1.4 14.76a1.374 1.374 0 110-2.747h6.1a1.374 1.374 0 110 2.747zM0 3.132A3.161 3.161 0 013.188 0a3.16 3.16 0 013.188 3.132 3.16 3.16 0 01-3.188 3.133A3.161 3.161 0 010 3.132zm11.048 1.374a1.373 1.373 0 110-2.746h6.1a1.373 1.373 0 110 2.746z"
        transform="rotate(90 8.24 8.24)"
      ></path>
    </svg>
  );
};

export const FilterIconStyled = styled(FilterIcon)`
  color: white;
  width: 0.1rem;
`;
