import { GetResponseBody, GetHeaderRequest, baseUrlApi } from './PathConfig';

export const ObtenerMenuRequest = async (pToken) => {
    const result = await fetch(`${baseUrlApi}api/Menu/Obtener_Menu`, {
        method: 'GET',
        headers: GetHeaderRequest(pToken)
    });
    //console.log(result);
    return await GetResponseBody(result);
};

export const InsertaMenuHistoricoRequest = async (pToken, pMenu) => {
    const result = await fetch(`${baseUrlApi}api/WS_Acceso_Menu_Historico/WS_Acceso_Menu_Historico_InsertB`, {
        method: 'POST',
        headers: GetHeaderRequest(pToken),
        body: JSON.stringify(pMenu)
    });
    return await GetResponseBody(result);
};

export const ObtenerMenuOpcionesRequest = async (pToken, pMenuId) => {
    const result = await fetch(`${baseUrlApi}api/Menu/Obtener_Permisos?menu_Id=${pMenuId}`, {
        method: 'GET',
        headers: GetHeaderRequest(pToken)
    });
    return await GetResponseBody(result);
};