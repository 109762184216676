import React, { Fragment } from 'react';
import Seo from '../../components/Layout/Seo';
import Navbar from '../../components/Layout/Navbar';
import MenuHome from '../../components/Menu/MenuHome';

import ConstantesSistema from '../../Constants/ConstantesSistema';
import { Stack } from '@chakra-ui/react';

const HomePage = () => {
  localStorage.removeItem(ConstantesSistema.listaVh);   
  localStorage.removeItem(ConstantesSistema.regresar);   
  localStorage.removeItem(ConstantesSistema.vehiculoDet);   
  localStorage.removeItem(ConstantesSistema.vehiculoSel);   
  localStorage.removeItem(ConstantesSistema.tipoBusqueda);   
  localStorage.removeItem(ConstantesSistema.menuSel);   
  return (
    <Stack>
      <Fragment>
        <Seo title="Home" />
        <Navbar />
      </Fragment>
      <MenuHome/>
      
    </Stack>
  );
};

export default HomePage;
